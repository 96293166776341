import { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  label?: string;
  description?: string;
  errorMessage?: string;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const UIInput: FC<Props> = ({
  onChange,
  label,
  description,
  errorMessage,
  forwardedRef,
  ...props
}) => {
  return (
    <label className="flex flex-col gap-2">
      {(label || description) && (
        <div className={`flex flex-col gap-0.5 ${props.disabled ? 'opacity-60' : ''}`}>
          <span className="block text-lg">{label}</span>
          <span className="text-sm opacity-60">{description}</span>
        </div>
      )}
      <ItemWithFancyBorder className="rounded-xl">
        <input
          {...props}
          ref={forwardedRef}
          className="text-whitet w-full rounded-xl bg-black px-5 py-4"
          onChange={onChange}
        />
      </ItemWithFancyBorder>
      {errorMessage && <div className="text-red-700">{errorMessage}</div>}
    </label>
  );
};
