import { Icon } from '@iconify/react';
import { FC, HTMLAttributes, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { useImmutableCallback } from 'src/hooks/useActualRef';

export interface IOption {
  name: string;
  icon?: ReactNode;
  value: string;
  hide?: boolean;
}

type Props = {
  value: IOption | null | undefined;
  options: IOption[];
  onSelect: (item: IOption) => void;
  description?: string;
  placeholder?: string;
  label?: string | ReactNode;
  labelPosition?: 'left' | 'top' | 'inside';
  disabled?: boolean;
  labelEnd?: ReactNode;
  ToggleButton?: FC<{ isOpen: boolean }> | string;
  selectContainerClasses?: HTMLAttributes<HTMLDivElement>['className'];
};

export const UISelector: FC<Props> = ({
  placeholder,
  disabled,
  label,
  description,
  labelPosition = 'top',
  options,
  value,
  labelEnd,
  ToggleButton,
  onSelect,
  selectContainerClasses,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const clickCallback = useImmutableCallback(() => {
    setIsOpen(false);
    setSearch('');
  });

  useEffect(() => {
    document.addEventListener('click', clickCallback);

    return () => document.removeEventListener('click', clickCallback);
  }, []);

  const filteredOptions = [...options].filter((el) =>
    el.name.toLowerCase().includes(search.toLowerCase()),
  );

  const toggleDropdown = (
    e:
      | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
      | MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    if (disabled) return;
    e.preventDefault();
    setIsOpen(!isOpen);
    setSearch('');
  };

  const selectItem = (item: IOption) => {
    if (disabled) return;
    onSelect(item);
    setIsOpen(false);
  };

  return (
    <div
      className={`flex ${labelPosition === 'top' ? 'flex-col' : 'gap-4'}`}
      onClick={(e) => e.stopPropagation()}
    >
      {(label || labelEnd || description) && (
        <div className={`mb-2 ${disabled ? 'opacity-60' : ''}`}>
          {(label || labelEnd) && labelPosition !== 'inside' && (
            <div className={`flex items-center justify-between`}>
              {label && <span className="text-lg">{label}</span>}
              {labelEnd}
            </div>
          )}
          {description && <span className="text-sm opacity-60">{description}</span>}
        </div>
      )}
      <div className="relative">
        <div onClick={toggleDropdown} className="cursor-pointer">
          {ToggleButton ? (
            <ToggleButton isOpen={isOpen} />
          ) : (
            <ItemWithFancyBorder className="rounded-xl">
              <button
                className={`flex w-full items-center justify-between rounded-xl bg-black px-6 py-4 text-left ${
                  disabled ? 'cursor-default' : ''
                } ${selectContainerClasses}`}
              >
                {labelPosition === 'inside' && label}
                {value?.name || placeholder || 'Select'}
                <span
                  className={`pointer-events-none inset-y-0 right-1 -mt-[1px] ml-4 flex items-center ${
                    disabled ? 'text-gray-600' : ''
                  }`}
                >
                  <Icon
                    className={`size-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                    icon="fa6-solid:caret-down"
                  />
                </span>
              </button>
            </ItemWithFancyBorder>
          )}
        </div>
        {isOpen && (
          <div className="absolute z-30 mt-2 w-full overflow-hidden rounded-xl border border-[#232323] bg-black shadow-lg transition-all duration-200 ease-in-out">
            {options.length > 6 && (
              <div className="relative border-b border-[#232323]">
                <Icon
                  icon="fa6-solid:magnifying-glass"
                  className="absolute left-3.5 top-3 text-gray-400"
                />
                <input
                  value={search}
                  type="search"
                  placeholder="Search"
                  className="w-full bg-black px-4 py-2 pl-10 text-white placeholder-gray-400"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            )}
            <ul className="max-h-60 overflow-auto">
              {filteredOptions
                .filter((el) => !el.hide)
                .map((item, i) => (
                  <li
                    key={`${item.value}_${i}`}
                    onClick={() => selectItem(item)}
                    className={`cursor-pointer border-b border-[#232323] px-4 py-2.5 transition-colors duration-200 ease-in-out ${
                      value?.value === item.value ? 'bg-black text-[#8648D2]' : 'hover:bg-[#232323]'
                    }`}
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
