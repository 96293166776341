import { FC } from 'react';
import { UICheckbox } from './UICheckbox';

type CheckboxItem =
  | {
      icon: string;
      value: string;
    }
  | string;

type Props = {
  items: CheckboxItem[];
  values: string[] | undefined;
  multiple?: boolean;
  max?: number;
  label?: string;
  description?: string;
  errorMessage?: string;
  showCheckboxes?: boolean;
  disabled?: boolean;
  onChange: (newValues: string[]) => void;
};

export const UICheckboxGroup: FC<Props> = ({
  items,
  values,
  onChange,
  label,
  multiple = true,
  max,
  showCheckboxes = false,
  description,
  errorMessage,
  disabled = false,
}) => {
  const handleCheckboxChange = (value: string) => {
    if (!values) {
      onChange([value]);
      return;
    }
    if (multiple) {
      if (values.includes(value)) {
        onChange(values.filter((v) => v !== value));
      } else {
        // Проверяем, не превышает ли количество выбранных элементов максимум
        if (max && values.length >= max) {
          return;
        }
        onChange([...values, value]);
      }
    } else {
      if (values.includes(value)) {
        onChange([]);
      } else {
        onChange([value]);
      }
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {(label || description) && (
        <div className="col-span-1 flex flex-col gap-0.5 sm:col-span-2 md:col-span-3">
          <span className="block text-lg">{label}</span>
          <span className="text-sm opacity-60">{description}</span>
        </div>
      )}
      {items.map((item) => (
        <UICheckbox
          key={typeof item === 'string' ? item : item.value}
          item={item}
          checked={values?.includes(typeof item === 'string' ? item : item.value)}
          onChange={() => handleCheckboxChange(typeof item === 'string' ? item : item.value)}
          showCheckbox={showCheckboxes}
          disabled={disabled}
        />
      ))}
      {errorMessage && (
        <div className="col-span-1 text-red-700 sm:col-span-2 md:col-span-3">{errorMessage}</div>
      )}
    </div>
  );
};
