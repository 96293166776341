import { useAppKit, useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers';
import { toast } from 'sonner';
import { bondingAbi } from 'src/contracts/abi';
import { ADDRESSES } from 'src/contracts/addresses';
import { useSetModal } from 'src/providers/ModalsProvider';

export const useUnwrapToken = (tokenAddress: string | undefined) => {
  const { walletProvider } = useAppKitProvider<Eip1193Provider>('eip155');
  const { open } = useAppKit();
  const { isConnected, address } = useAppKitAccount();
  const setModal = useSetModal();

  const unwrap = async () => {
    if (!walletProvider) return;
    if (!address) return;
    if (!tokenAddress) return;
    if (!isConnected) {
      open();
      return;
    }

    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();

    const bonding = new ethers.Contract(ADDRESSES.bondingAddress, bondingAbi, signer);

    try {
      setModal({ modalKey: 'loader', title: 'Confirm your transaction in the wallet' });

      const tx = await bonding.unwrapToken(tokenAddress, [address]);

      setModal({
        modalKey: 'loader',
        title: 'Unwrapping',
        txHash: tx.hash,
      });

      await tx.wait();

      setModal({
        modalKey: 'success',
        title: 'Unwrap successful transaction',
        txHash: tx.hash,
      });
    } catch (e: unknown) {
      setModal(null);
      if (e instanceof Error) {
        toast.error('Unable to process unwrap transaction: ' + e.message);
      } else {
        toast.error('Unable to process unwrap transaction');
      }
    }
  };

  return {
    unwrap,
    isConnected,
  };
};
