import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'sonner';
import SendIcon from 'src/assets/images/sendIcon.svg?react';
import { OpacityBox } from 'src/components/ui/OpacityBox.tsx';
import { UIButton } from 'src/components/ui/UIButton';
import { useImmutableCallback } from 'src/hooks/useActualRef';
import { useAgixBotState } from 'src/pages/agents/agents--id/providers/BotProvider.tsx';

export const AgixBotFooter = () => {
  const [{ userId, sending }, setChatState] = useAgixBotState();
  const { id } = useParams();

  const [value, setValue] = useState('');

  const textArea = useRef<HTMLTextAreaElement>(null);
  const userIdRef = useRef(userId);

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  useEffect(() => {
    function messageHandler(event: MessageEvent) {
      if (event.data.type !== 'myTypeee') return;
      setValue(event.data.text);
    }

    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  useEffect(() => {
    const el = textArea.current;

    if (!el) return;

    textArea.current.addEventListener('input', inputCallback);

    textArea.current.addEventListener('keydown', keyDownCallback);
  }, [textArea]);

  const inputCallback = useImmutableCallback(() => {
    const el = textArea.current;

    if (!el) return;

    const numberOfSymbols = value.length;
    const symbolsPerRow = 100;
    el.rows = Math.min(5, Math.max(1, Math.ceil(numberOfSymbols / symbolsPerRow)));
  });

  const keyDownCallback = useImmutableCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.shiftKey) {
        setValue(value + '\n');
      } else {
        event.preventDefault();
        sendUserMessage();
      }
    }
  });

  const sendUserMessage = async function () {
    if (sending) return;
    if (!value) return;

    setChatState((prevState) => ({ ...prevState, sending: true }));

    try {
      await axios.post('https://ait.earth-ai.cloud:3443/handle_tasks', {
        agent_id: id,
        scope_id: '7700002',
        user_id: userId,
        user_message: value,
        channel: 'webchat',
        username: userId,
        steps: [
          {
            field: 'answer',
            input_getter: 'getter_prompt',
            input_getter_kwargs: {
              prompt_var: 'ASSISTANT_PROMPT',
              aither: true,
            },
            no_hallucinations: true,
          },
        ],
      });

      setChatState((prevState) => ({ ...prevState, sending: false, answering: true }));

      setValue('');

      const el = textArea.current;

      if (!el) return;

      el.rows = 1;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      }

      setChatState((prevState) => ({ ...prevState, sending: false, answering: false }));

      const el = textArea.current;

      if (!el) return;

      el.rows = 1;
    }
  };

  return (
    <OpacityBox className="relative mx-auto mt-2 flex w-full items-end gap-4 p-2 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
      <textarea
        id="messageTextArea"
        placeholder="Message"
        className={`h-[60px] flex-1 resize-none rounded-xl border border-gray-600 bg-transparent p-2 font-normal placeholder-gray-400 focus:border-purple-600 focus:ring-0 xl:px-3 xl:py-4 ${sending ? 'opacity-20' : ''}`}
        rows={1}
        value={value}
        ref={textArea}
        disabled={sending}
        onChange={(e) => setValue(e.target.value)}
      />
      <UIButton className="h-[60px]" onClick={sendUserMessage} disabled={sending}>
        <SendIcon />
      </UIButton>
    </OpacityBox>
  );
};
