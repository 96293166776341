import { Icon } from '@iconify/react';
import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { Agent } from 'src/types/agents';
import { BN } from 'src/utils/bigNumber';
import { shortenHash } from 'src/utils/ui';

interface HolderDistributionCardProps {
  agent: Agent;
}

export const HolderDistributionCard: FC<HolderDistributionCardProps> = ({ agent }) => {
  return (
    <ItemWithFancyBorder className="rounded-xl">
      <div className="flex h-full flex-col rounded-xl bg-black p-6">
        <div className="mb-6 flex items-center">
          <span className="text-lg font-bold">Holder Distribution</span>
        </div>
        {agent.token_info.top_holders.map((holder, i) => (
          <div key={holder.address} className="flex justify-between">
            <a
              href={`https://basescan.org/address/${holder.address}`}
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <div className="flex items-center">
                <span className="inline-block w-6">{i + 1}.</span>
                <span className="w-28 group-hover:underline">{shortenHash(holder.address)}</span>
                <Icon icon="fa6-solid:up-right-from-square" className="text-white" />
              </div>
            </a>
            <span className="text-sm opacity-60">
              {BN(holder.balance)
                .div(BN(agent.token_info.supply).times(1e18))
                .times(100)
                .toFixed(2)}
              %
            </span>
          </div>
        ))}
      </div>
    </ItemWithFancyBorder>
  );
};
