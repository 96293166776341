import { useAppKitProvider } from '@reown/appkit/react';
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers';
import { FC, useEffect, useState } from 'react';
import IconSwap from 'src/assets/images/icons/i-swap.svg?react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { FancyButton } from 'src/components/ui/FancyButton';
import { assetTokenAbi, bondingAbi, routerAbi } from 'src/contracts/abi';
import { ADDRESSES } from 'src/contracts/addresses.ts';
import { useImmutableCallback } from 'src/hooks/useActualRef.ts';
import { useBalanceOf } from 'src/hooks/useBalanceOf.tsx';
import { useSetModal } from 'src/providers/ModalsProvider.tsx';
import { Agent } from 'src/types/agents.ts';
import { BN, getAtomicAmount, getDisplayAmount, numberWithSpaces } from 'src/utils/bigNumber.ts';

export const SwapBlock: FC<{ agent: Agent }> = ({ agent }) => {
  const { walletProvider } = useAppKitProvider<Eip1193Provider>('eip155');
  const setModal = useSetModal();

  const { balance: token1Balance, refetch: token1Refetch } = useBalanceOf(ADDRESSES.assetToken);
  const { balance: token2Balance, refetch: token2Refetch } = useBalanceOf(agent.contract_address);

  const [val1, setVal1] = useState('0');
  const [val2, setVal2] = useState('0');
  const [action, setAction] = useState<'buy' | 'sell'>('buy');

  useEffect(() => {
    calculateOutAmount();
  }, [val1, val2]);

  const calculateOutAmount = useImmutableCallback(async (actionIn?: 'buy' | 'sell') => {
    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();

    const actionValue = actionIn || action;
    const routerContract = new ethers.Contract(ADDRESSES.router, routerAbi, signer);

    if (actionValue === 'buy') {
      const resp = await routerContract.simulateBuy(getAtomicAmount(val1), agent.contract_address);

      setVal2(getDisplayAmount(resp[1]));
    } else {
      const resp = await routerContract.simulateSell(getAtomicAmount(val2), agent.contract_address);

      setVal1(getDisplayAmount(resp[1]));
    }
  });

  function handleChangeDirection() {
    if (action === 'buy') {
      setAction('sell');
      calculateOutAmount('sell');
    } else {
      setAction('buy');
      calculateOutAmount('buy');
    }
  }

  function handleSwap() {
    swap();
  }

  async function swap() {
    const routerAddress = ADDRESSES.router;
    const memeTokenAddress = agent.contract_address;

    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      const bonding = new ethers.Contract(ADDRESSES.bondingAddress, bondingAbi, signer);
      const assetOutContract = new ethers.Contract(
        action === 'buy' ? ADDRESSES.assetToken : memeTokenAddress,
        assetTokenAbi,
        signer,
      );

      const value = getAtomicAmount(action === 'buy' ? val1 : val2);

      const currentAllowance = await assetOutContract.allowance(
        await signer.getAddress(),
        routerAddress,
      );

      if (BN(currentAllowance).lt(value)) {
        const maxAmount = ethers.MaxUint256;
        const approveTx = await assetOutContract.approve(routerAddress, maxAmount);

        console.log('approve tx hash:', approveTx.hash);

        setModal({
          modalKey: 'loader',
          title: 'Approving',
          txHash: approveTx.hash,
        });

        const approveRecp = await approveTx.wait();
        if (approveRecp.status === 1) {
          console.log('Approval successful');
        } else {
          setModal(null);
          throw new Error('Failed to approve');
        }
      }

      const tx = await bonding[action](value, memeTokenAddress, {
        gasLimit: 3000000,
      });

      setModal({
        modalKey: 'loader',
        title: 'Swapping',
        txHash: tx.hash,
      });

      await tx.wait();

      token1Refetch();
      token2Refetch();

      setModal({
        modalKey: 'success',
        title: `${action === 'buy' ? 'Buy' : 'Sell'} transaction minted`,
        txHash: tx.hash,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <ItemWithFancyBorder className="rounded-xl">
      <div
        className="flex h-full flex-col rounded-xl p-6"
        style={{
          background:
            'radial-gradient(circle at center 30%, rgba(251,169,49,1) 0%, rgba(61,15,119,1) 50%, rgba(36,13,39,1) 100%)',
        }}
      >
        <h2 className="text-center font-space-grotesk text-3xl font-bold">SWAP</h2>
        <div
          className={`relative mb-2 flex gap-1 ${action === 'sell' ? 'flex-col-reverse' : 'flex-col'}`}
        >
          <div className="bg-[#52208F] px-3 py-4">
            <div>
              <span className="mb-1 block font-space-grotesk font-bold text-black">
                {action === 'buy' ? 'From' : 'To'}
              </span>
              <div className="flex items-center gap-2">
                <div className="relative size-12 flex-shrink-0 overflow-hidden rounded-full">
                  <img
                    src="/images/dummy-token-icon-2.png"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-grow flex-col">
                  <div className="flex justify-between gap-4">
                    <div className="flex flex-col font-space-grotesk">
                      <span className="text-xl font-bold">AITHER</span>
                    </div>
                    <div className="flex flex-grow flex-col text-right font-lekton">
                      <input
                        disabled={action === 'sell'}
                        className="w-full bg-transparent text-right text-2xl font-bold focus:outline-none"
                        value={val1}
                        onChange={(e) => setVal1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <span
                      className="cursor-pointer text-sm opacity-60"
                      onClick={() => setVal1(token1Balance.fullPrecision)}
                    >
                      Balance: {numberWithSpaces(token1Balance.formatted)}
                    </span>
                    <span className="text-sm opacity-60">$0.00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute left-1/2 top-1/2 size-12 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-black"
            onClick={handleChangeDirection}
          >
            <IconSwap className="absolute left-1/2 top-1/2 w-2/3 -translate-x-1/2 -translate-y-1/2" />
          </div>
          <div className="bg-[#5823C2] p-3">
            <div>
              <span className="mb-1 block font-space-grotesk font-bold text-black">
                {action === 'sell' ? 'From' : 'To'}
              </span>
              <div className="flex items-center gap-2">
                <div className="relative size-12 flex-shrink-0 overflow-hidden rounded-full">
                  <img
                    src={agent.image_url}
                    alt={agent.ticker}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-grow flex-col">
                  <div className="flex justify-between gap-4">
                    <div className="flex flex-grow flex-col font-space-grotesk">
                      <span className="text-xl font-bold">{agent.ticker}</span>
                    </div>
                    <div className="flex flex-grow flex-col text-right font-lekton">
                      <input
                        disabled={action === 'buy'}
                        className="w-full bg-transparent text-right text-2xl font-bold focus:outline-none"
                        value={val2}
                        onChange={(e) => setVal2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <span
                      className="cursor-pointer text-sm opacity-60"
                      onClick={() => setVal2(token2Balance.fullPrecision)}
                    >
                      Balance: {numberWithSpaces(token2Balance.formatted)}
                    </span>
                    <span className="text-sm opacity-60">$0.00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FancyButton size="xl" className="w-full" onClick={handleSwap}>
          Swap
        </FancyButton>
      </div>
    </ItemWithFancyBorder>
  );
};
