import { Icon } from '@iconify/react';
import { useAppKitNetwork } from '@reown/appkit/react';
import { FC } from 'react';
import { Modal } from 'src/components/ui/Modal';
import { UIButton } from 'src/components/ui/UIButton';
import { useSetModal } from 'src/providers/ModalsProvider';

export const SuccessModal: FC<{ title: string; txHash?: string }> = ({ title, txHash }) => {
  const setModal = useSetModal();
  const { caipNetwork } = useAppKitNetwork();

  const chainExplorer = caipNetwork?.blockExplorers?.default.url;

  return (
    <Modal size="lg">
      <Icon className="mx-auto my-6 text-9xl text-green-600" icon="fa6-solid:circle-check" />
      <div className="modal-title mb-4 text-center">{title}</div>
      {txHash && (
        <div className="mb-4 flex items-center justify-center text-purple-600">
          <a className="underline" href={`${chainExplorer}/tx/${txHash}`} target={'_blank'}>
            Show transaction
          </a>
          <Icon className="-mt-0.5 ml-3" icon="fa6-solid:arrow-up-right-from-square" />
        </div>
      )}

      <div className="flex justify-center">
        <UIButton className="text-white" onClick={() => setModal(null)}>
          Close
        </UIButton>
      </div>
    </Modal>
  );
};
