import { Icon } from '@iconify/react';
import { CircularProgress } from 'src/components/ui/CircularProgress.tsx';

interface StreamingAvatarTextInputProps {
  label: string;
  placeholder: string;
  input: string;
  onSubmit: () => void;
  setInput: (value: string) => void;
  endContent?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onChangeChatMode: (mode: 'voice_mode' | 'text_mode') => void;
  chatMode: 'voice_mode' | 'text_mode';
}

export default function InteractiveAvatarTextInput({
  input,
  onSubmit,
  setInput,
  onChangeChatMode,
  disabled = false,
  loading = false,
  chatMode,
}: StreamingAvatarTextInputProps) {
  function handleSubmit() {
    if (chatMode === 'voice_mode') {
      onChangeChatMode('text_mode');
      return;
    }

    if (input.trim() === '') {
      return;
    }
    onSubmit();
    setInput('');
  }

  return (
    <div className="px-4">
      <p>Current mode: {chatMode === 'voice_mode' ? 'Voice' : 'Text'}</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="flex items-center gap-2 rounded-xl bg-white bg-opacity-70 py-4 pl-4 pr-2"
      >
        <input
          className="flex-grow resize-none bg-transparent text-black focus:outline-none"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          placeholder="Afraid to talk, or cant talk right now? Chat with me"
          disabled={disabled || chatMode === 'voice_mode' || loading}
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
        <button
          className={`flex size-10 -rotate-90 items-center justify-center rounded-full bg-[#52208F] text-3xl ${chatMode === 'voice_mode' ? 'opacity-40 hover:opacity-70' : 'cursor-default'}`}
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress spinnerSize="xs" />
          ) : (
            <Icon icon="material-symbols-light:send-rounded" className="-mr-1" />
          )}
        </button>
        <button
          className={`flex size-10 items-center justify-center rounded-full bg-[#52208F] text-3xl ${chatMode === 'text_mode' ? 'opacity-40 hover:opacity-70' : 'cursor-default'}`}
          type="submit"
          onClick={() => onChangeChatMode('voice_mode')}
          disabled={loading || chatMode === 'voice_mode'}
        >
          {loading ? (
            <CircularProgress spinnerSize="xs" />
          ) : (
            <Icon icon="material-symbols-light:mic" />
          )}
        </button>
      </form>
    </div>
  );
}
