import { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';

type Props = {
  label?: string;
  description?: string;
  errorMessage?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  forwardedRef?: React.RefObject<HTMLTextAreaElement>;
} & Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

export const UITextArea: FC<Props> = ({
  onChange,
  label,
  description,
  errorMessage,
  forwardedRef,
  ...props
}) => {
  return (
    <label className="flex flex-col gap-2">
      {(label || description) && (
        <div className={`flex flex-col gap-0.5 ${props.disabled ? 'opacity-60' : ''}`}>
          <span className="block text-lg">{label}</span>
          <span className="text-sm opacity-60">{description}</span>
        </div>
      )}
      <ItemWithFancyBorder className="rounded-xl">
        <textarea
          {...props}
          ref={forwardedRef}
          className="block h-full w-full rounded-xl bg-black px-5 py-4"
          onChange={onChange}
        />
      </ItemWithFancyBorder>
      {errorMessage && <div className="text-red-700">{errorMessage}</div>}
    </label>
  );
};
