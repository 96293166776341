import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { useQuery } from '@tanstack/react-query';
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers';
import { assetTokenAbi } from 'src/contracts/abi';
import { getDisplayAmount } from 'src/utils/bigNumber.ts';

const INIT_BALANCE = {
  raw: '',
  formatted: '',
  fullPrecision: '',
};

const fetchBalance = async (walletProvider: Eip1193Provider, token: string, address: string) => {
  const provider = new BrowserProvider(walletProvider);
  const signer = await provider.getSigner();

  const contract = new ethers.Contract(token, assetTokenAbi, signer);

  try {
    const balance = String(await contract.balanceOf(address));
    const decimals = Number(await contract.decimals());

    return {
      raw: balance,
      formatted: getDisplayAmount(balance, { decimals }),
      fullPrecision: getDisplayAmount(balance, { decimals, cut: false }),
    };
  } catch (e) {
    console.error('Error while loading balances', e);
    return INIT_BALANCE;
  }
};

export const useBalanceOf = (token: string) => {
  const { walletProvider } = useAppKitProvider<Eip1193Provider>('eip155');
  const { address } = useAppKitAccount();

  const {
    data: balance = INIT_BALANCE,
    refetch,
    isPending,
    isRefetching,
  } = useQuery({
    queryKey: ['balance', token, address],
    queryFn: () => fetchBalance(walletProvider, token, address!),
    enabled: !!token && !!address && !!walletProvider,
  });

  return { balance, refetch, isPending, isRefetching };
};
