import { Icon } from '@iconify/react';
import { FC } from 'react';
import { toast } from 'sonner';
import { UIDropZone } from 'src/components/ui/UIDropZone.tsx';
import { axiosInstance } from 'src/libs/axios';
interface KnowledgeBaseDropzoneProps {
  disabled?: boolean;
  onDrop: (files: { name: string; url: string }[]) => void;
  onRemove: (file_url: string) => void;
  files: { name: string; url: string }[];
}

export const KnowledgeBaseDropzone: FC<KnowledgeBaseDropzoneProps> = ({
  disabled,
  onDrop,
  files,
  onRemove,
}) => {
  async function handleLoadPdf(files: File[]) {
    try {
      const knoledgeFiles = await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          const sanitizedFileName = file.name
            .replace(/\s+/g, '')
            // eslint-disable-next-line no-control-regex
            .replace(/[^\x00-\x7F]/g, '');
          const renamedFile = new File([file], sanitizedFileName, { type: file.type });
          formData.append('document', renamedFile);
          const resp = await axiosInstance.post<string>('/api/storage/upload_document', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          return { url: resp.data, name: file.name };
        }),
      );

      onDrop(knoledgeFiles);
    } catch (e) {
      toast.error('Problem while uploading pdf');
      console.error(e);
    }
  }

  return (
    <div>
      <UIDropZone
        maxFiles={10}
        maxSize={10 * 1024 * 1024} // 10 MB
        disabled={disabled}
        accept={{
          'application/pdf': ['.pdf'],
          'text/plain': ['.txt'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }}
        onDrop={handleLoadPdf}
        multiple
      />
      {files && files.length > 0 && (
        <div className="mt-4">
          <h3 className="mb-2 text-white">Uploaded Files:</h3>
          <ul className="flex flex-grow flex-wrap gap-3 text-white">
            {files.map((file, index) => (
              <li
                onClick={() => onRemove(file.url)}
                className="flex cursor-pointer items-center gap-2 rounded-xl border border-gray-600 bg-[#2E3031] p-2 text-gray-400 hover:border-red-800 hover:text-red-700"
                key={index}
              >
                <span>{file.name}</span>
                <Icon icon="fa6-solid:xmark" />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
