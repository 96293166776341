import { useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { InViewComponent } from 'src/components/InViewComponent.tsx';
import { useHookWithRefCallback } from 'src/hooks/useHookWithRefCallback.tsx';

export const LastBlock = () => {
  const [ref1, setRef1] = useHookWithRefCallback<HTMLDivElement>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!ref1) return;

    ref1.addEventListener('animationend', () => {
      setShow(true);
    });
  }, [ref1]);

  return (
    <InViewComponent
      inViewClasses="animate__animated animate__fadeIn"
      rootMargin={'0% 0% -300px 0%'}
      ref={setRef1}
    >
      <section className="pt-12 lg:pt-60">
        <h2 className="font-space-grotesk text-3xl lg:text-6xl leading-[1.5em] text-center font-bold mb-10 lg:mb-24">
          Join the Era of <span className="text-[#8446FF]">Autonomous</span> AI
        </h2>
        <div
          className="container mx-auto bg-clip-text text-transparent text-xl lg:text-5xl font-bold"
          style={{
            backgroundImage:
              'linear-gradient(125deg, rgba(255,255,255,1) 40%, rgba(134,72,210,1) 100%)',
          }}
        >
          <div className="w-[94%] mx-auto text-center leading-[1.3em] pb-12 lg:pb-40">
            <div className="relative">
              {show && (
                <TypeAnimation
                  sequence={[
                    'AI agents transcend tools, becoming self-sustaining, revenue-generating assets. Together, we’re forging decentralized ecosystems where every action drives collective prosperity.',
                  ]}
                  splitter={(str) => str.split(/(?= )/)}
                  speed={{ type: 'keyStrokeDelayInMs', value: 300 }}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    backgroundClip: 'text',
                    color: 'transparent',
                    backgroundImage:
                      'linear-gradient(125deg, rgba(255,255,255,1) 40%, rgba(134,72,210,1) 100%)',
                  }}
                />
              )}
              <p className="opacity-0">
                AI agents transcend tools, becoming self-sustaining, revenue-generating assets.
                Together, we’re forging decentralized ecosystems where every action drives
                collective prosperity.
              </p>
            </div>
          </div>
        </div>
      </section>
    </InViewComponent>
  );
};
