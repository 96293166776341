import { useAppKit, useAppKitAccount } from '@reown/appkit/react';
import { FC, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router';
import IconEagle from 'src/assets/images/icons/i-eagle.svg?react';
import IconTg from 'src/assets/images/icons/i-tg.svg?react';
import IconW from 'src/assets/images/icons/i-warpcast.svg?react';
import IconX from 'src/assets/images/icons/i-x.svg?react';
import { shortenHash } from 'src/utils/ui';
import { FancyButton } from '../ui/FancyButton';

type DrawerSidebarType = 'app' | 'default';

interface DrawerSidebarProps {
  isOpen: boolean;
  close: () => void;
  type: DrawerSidebarType;
}

export const DrawerSidebar: FC<DrawerSidebarProps> = ({ isOpen, close, type }) => {
  const location = useLocation();
  const { open } = useAppKit();
  const { address, isConnected } = useAppKitAccount();

  useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [location]);

  return (
    <div
      className={`animate__animated fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-end gap-20 bg-black p-10 ${
        type === 'default' ? 'lg:hidden' : 'xl:hidden'
      } ${isOpen ? 'animate__slideInRight' : 'animate__slideOutRight'}`}
    >
      <button className="text-2xl" onClick={close}>
        X
      </button>
      <div className="flex flex-col items-end gap-6">
        <a onClick={close} className="text-lg text-[#848484]" href="#section2">
          Protocol Overview
        </a>
        <a
          onClick={close}
          className="text-lg text-[#848484]"
          href="/lite-paper"
          target="_blank"
          rel="noreferrer"
        >
          AVM Lite Paper
        </a>
        <a
          href="https://docs.aither.xyz"
          target="_blank"
          rel="noreferrer"
          className="text-lg text-[#848484]"
        >
          Documentation
        </a>
      </div>

      {type === 'default' && (
        <div className="flex flex-col items-end gap-6">
          <a
            href="https://app.uniswap.org/swap?chain=mainnet&inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
            target="_blank"
            rel="noreferrer"
          >
            <FancyButton>Buy $AITHER</FancyButton>
          </a>
          <NavLink to="/agents">
            <FancyButton>Launch app</FancyButton>
          </NavLink>
          <NavLink to="/bridge">
            <FancyButton>Bridge Tokens</FancyButton>
          </NavLink>
        </div>
      )}

      {type === 'app' && (
        <div className="flex flex-col items-end gap-6">
          <NavLink to="create">
            <FancyButton>Create agent</FancyButton>
          </NavLink>

          <NavLink to="/bridge">
            <FancyButton>Bridge Tokens</FancyButton>
          </NavLink>

          {isConnected ? (
            <button className="font-space-grotesk font-bold" onClick={() => open()}>
              {shortenHash(address!)}
            </button>
          ) : (
            <button
              className="ml-8 rounded-[40px] bg-[#3D0F77] px-5 py-2 font-medium"
              onClick={() => open()}
            >
              Connect wallet
            </button>
          )}
        </div>
      )}

      <div className="mt-auto flex items-center gap-5">
        <a href="https://warpcast.com/aither" target="_blank" rel="noreferrer">
          <IconW />
        </a>
        <a href="https://x.com/Aither_protocol" target="_blank" rel="noreferrer">
          <IconX className="w-5" />
        </a>
        <a href="https://t.me/aither_protocol" target="_blank" rel="noreferrer">
          <IconTg />
        </a>
        <a
          href="https://dexscreener.com/ethereum/0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
          target="_blank"
          rel="noreferrer"
        >
          <IconEagle />
        </a>
      </div>
    </div>
  );
};
