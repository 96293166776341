import { HTMLAttributes } from 'react';
import { FCC } from 'src/types/FCC.ts';
import { CircularProgress } from './CircularProgress';

const buttonSize = {
  xl: 'py-5 px-16 text-3xl rounded-xl',
  default: 'px-6 py-4 rounded-xl',
  sm: 'px-2 py-2 rounded-xl text-sm',
} as const;

const buttonTypes = {
  stroke: 'bg-transparent border-[#3D0F77]',
  default: 'bg-[#3D0F77] border-[#3D0F77]',
} as const;

const buttonColors = {
  danger: '',
  default: 'bg-[#3D0F77]',
};

type Props = {
  size?: keyof typeof buttonSize;
  buttonType?: keyof typeof buttonTypes;
  buttonColor?: keyof typeof buttonColors;
  processing?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const UIButton: FCC<Props> = ({
  children,
  size = 'default',
  buttonType = 'default',
  buttonColor = 'default',
  className,
  processing,
  onClick,
  disabled,
  ...props
}) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };
  return (
    <button
      disabled={disabled || processing}
      onClick={handleOnClick}
      {...props}
      className={`border-1 relative flex items-center justify-center font-bold leading-none ${buttonSize[size]} ${buttonColor ? buttonColors[buttonColor] : ''} ${buttonType ? buttonTypes[buttonType] : ''} ${className} hover:opacity-90 active:opacity-80`}
    >
      {children}
      {processing && (
        <div className="absolute inset-0 flex items-center justify-center rounded-xl backdrop-blur-xl">
          <CircularProgress spinnerSize="xs" />
        </div>
      )}
    </button>
  );
};
