import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { ProgressBar } from 'src/components/ui/ProgressBar.tsx';
import { Agent } from 'src/types/agents';
import { BN } from 'src/utils/bigNumber';

interface BondingCurveProps {
  agent: Agent;
}

export const BondingCurve: FC<BondingCurveProps> = ({ agent }) => {
  const progress = BN(agent.token_info?.progress || 0).toFixed(4);

  return (
    <ItemWithFancyBorder className="rounded-xl">
      <div className="flex flex-col rounded-xl bg-black px-6 py-6">
        <div className="mb-6 flex items-center justify-between">
          <span className="text-lg font-bold">Bonding curve</span>
          <span className="text-sm opacity-60">{progress}%</span>
        </div>
        <ProgressBar progress={progress} />
      </div>
    </ItemWithFancyBorder>
  );
};
