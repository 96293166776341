export enum Genre {
  ACTION = 'Action',
  FICTIONAL = 'Fictional',
  FINANCE = 'Finance',
  POLITICS = 'Politics',
  PHILOSOPHY = 'Philosophy',
  ROMANCE = 'Romance',
  HISTORICAL = 'Historical',
  HORROR = 'Horror',
  NO_GENRE = 'No Genre',
}

export enum Goal {
  ROLEPLAY = 'Roleplay',
  ASSISTANT = 'Assistant',
  MASCOT = 'Mascot',
}

export enum Character {
  AI_AGENT = 'Non-human',
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non-binary',
}

export enum Personality {
  BASIC = 'Basic',
  INTROVERT = 'Introvert',
  EXTROVERT = 'Extravert',
  AGGRESSIVE = 'Aggressive',
  CUSTOM = 'Custom',
}

export enum AiModel {
  GPT_4O = 'GPT-4',
  GPT_4O_MINI = 'GPT-4o',
  GEMINI = 'Gemini',
  CLAUDE = 'Claude',
  DEEPSEEK = 'Deepseek',
}

export enum Tool {
  COINGECKO = 'CoinGecko',
  DEXTOOLS = 'Dextools',
  GOOGLE = 'Google',
  WEB_BROWSING = 'Web Browsing',
  CRYPTOPANIC = 'Cryptopanic',
}

export enum EthicalRule {
  STRICT = 'Strict (No Rule Violations)',
  FLEXIBLE = 'Flexible (Context-Based Responses)',
}

export enum SENTIMENT {
  NEUTRAL = 'Neutral',
  POSITIVE = 'Positive',
  HUMOROUS = 'Humorous',
  EMPATHETIC = 'Empathetic',
  FORMAL = 'Formal',
}

export enum TwitterCommentStrategy {
  MOTIVATIONAL = 'Motivational',
  CONSTRUCTIVE = 'Constructive',
  HUMOROUS = 'Humorous',
}

export enum TwitterEmotion {
  Neutral = 'Neutral',
  SLIGHTLY_NEUTRAL = 'Slightly neutral',
  BALANCED = 'Balanced',
  SLIGHTLY_EMOTIONAL = 'Slightly emotional',
  EMOTIONAL = 'Emotional',
}

export enum PRO_ACTIVITY_LVL {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum TELEGRAM_GROUP_ENGAGEMENT_STRATEGY {
  FRIENDLY = 'Friendly',
  PROFESSIONAL = 'Professional',
  PROMOTIONAL = 'Promotional',
}

export enum CRISIS_COMMUNICATION {
  APOLOGETIC = 'Apologetic',
  DEFLECTIVE = 'Deflective',
  INFORMATIVE = 'Informative',
}

export enum CONTENT_DIRECTION {
  MARKET_ANALYSES = 'Market Analyses',
  TRENDING_NEWS_HIGHLIGHTS = 'Trending News Highlights',
  EVENT_COVERAGE = 'Event Coverage',
  MEMES_AND_ENTERTAINMENT = 'Memes and Entertainment',
  INSPIRATIONAL_QUOTES = 'Inspirational Quotes',
  COMMUNITY_ENGAGEMENT = 'Community Engagement',
  NARRATIVE_SEARCH = 'Narrative Search',
  WEB3_MOTIVATION = 'Web3 Motivation',
}

export type NewAgent = {
  amount_to_buy: string;
  personality_info: string;
  pro_activity_level: PRO_ACTIVITY_LVL;
  sentiment: SENTIMENT;
  adaption_mode: boolean;
  agent_id: string;
  creator_contract_address: string;
  name: string;
  description: string;
  image_url: string;
  ticker: string;
  origin: string[] | undefined;
  goal: Goal | undefined;
  genre: Genre;
  character: Character | undefined;
  hobbies: string[];
  personality_type: Personality;
  greeting: string;
  additional_personality_info: string;
  ai_model: AiModel;
  tools: Tool[];
  twitter_content_directions: CONTENT_DIRECTION[];
  ethical_rules: EthicalRule;
  telegram_token: string;
  telegram_automoderation: boolean;
  telegram_group_engagement_strategy: TELEGRAM_GROUP_ENGAGEMENT_STRATEGY;
  telegram_welcome_message: string;
  telegram_crisis_communication: CRISIS_COMMUNICATION;
  twitter_username: string;
  twitter_comment_strategy: TwitterCommentStrategy;
  twitter_emotion: TwitterEmotion;
  twitter_content_examples: string[];
  twitter_content_specific_preferences: string[];
  twitter_add_hashtags: boolean;
  twitter_agent_set_likes: boolean;
  twitter_agent_post_videos: boolean;
  twitter_agent_regular_post: boolean;
  twitter_agent_post_quoted_tweets: boolean;
  twitter_agent_answer_comments: boolean;
  twitter_agent_comment_tweets: boolean;
  twitter_sentiment_control: boolean;
  twitter_crisis_communication: CRISIS_COMMUNICATION;
  knowledge_files: string[];
  knowledge_uploaded_files: { url: string; name: string }[];
  knowledge_websites: string[];
  lorebook: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  socials: {
    website: string | null;
    telegram: string | null;
    twitter: string | null;
  };
  heygen: {
    avatar_id: string;
    voice_id: string;
    knowledge_base_id: string;
    voice_language: string;
    voice_name: string;
  };
};

export type Agent = {
  creator_contract_address: string;
  agent_id: string;
  name: string;
  origin: string[];
  description: string;
  ticker: string;
  image_url: string;
  created_at: string;
  contract_address: string;
  pair_address: string;
  chain: string;
  forum_enabled: boolean;
  telegram_agent_enabled: boolean;
  telegram_username: string;
  twitter_agent_enabled: boolean;
  twitter_agent_username: string;
  uniswap_pair_address: string | null;
  updated_token_address: string | null;
  token_info: {
    grad_threshold: number;
    supply: number;
    price: number;
    price_usd: number;
    market_cap: number;
    market_cap_usd: number;
    liquidity: number;
    volume: number;
    volume_24: number;
    prev_price: number;
    last_updated_at: number;
    pair_reserve_meme: number;
    progress: number;
    top_holders: [
      {
        address: string;
        balance: string;
      },
    ];
    holder_count: number;
  };
  twitter_stats: {
    score: number;
    followers_count: number;
    influencers_count: number;
    projects_count: number;
    venture_capitals_count: number;
  };
  socials: {
    website: string;
    telegram: string;
    twitter: string;
  };
  hobbies: string[];
};

export type TabType = {
  name: string;
  value: string;
};
