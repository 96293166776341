import { useQuery } from '@tanstack/react-query';
import { createChart, ISeriesApi, UTCTimestamp } from 'lightweight-charts';
import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress } from 'src/components/ui/CircularProgress.tsx';
import { UIButton } from 'src/components/ui/UIButton';
import { axiosInstance } from 'src/libs/axios.ts';
import { Agent } from 'src/types/agents.ts';

type MarketDataItem = {
  close: number;
  high: number;
  low: number;
  open: number;
  pair: string;
  subgraph_id: string;
  time: UTCTimestamp;
  volume: number;
  _id: string;
};

// Изменяем тип TimeInterval
type TimeInterval = '1M' | '5M' | '15M' | '1H' | '24H' | '7D';

interface AgentTokenChartProps {
  agent: Agent;
}

const fetchTradeData = async (agentId: string, start: number, end: number) => {
  const params = { agent_id: agentId, start, end };
  const { data } = await axiosInstance.get<MarketDataItem[]>('/api/market/ohlc', {
    params,
  });
  return data.map((el) => ({
    ...el,
    time: Number(el.time) as UTCTimestamp,
    volume: Number(el.volume),
  }));
};

// Обновляем функцию getStartTimestamp
const getStartTimestamp = (interval: TimeInterval): number => {
  const now = Date.now();
  const secondsInMinute = 60;
  const secondsInHour = 60 * 60;

  switch (interval) {
    case '1M':
      return Math.floor(now / 1000) - secondsInMinute;
    case '5M':
      return Math.floor(now / 1000) - 5 * secondsInMinute;
    case '15M':
      return Math.floor(now / 1000) - 15 * secondsInMinute;
    case '1H':
      return Math.floor(now / 1000) - secondsInHour;
    case '24H':
      return Math.floor(now / 1000) - 24 * secondsInHour;
    case '7D':
      return Math.floor(now / 1000) - 7 * 24 * secondsInHour;
  }
};

export const AgentTokenChart: FC<AgentTokenChartProps> = ({ agent }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const candleSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null);
  const [selectedInterval, setSelectedInterval] = useState<TimeInterval>('1M');

  const now = Date.now();
  const start = getStartTimestamp(selectedInterval);
  const end = Math.floor(now / 1000);

  // Основной запрос для исторических данных
  const { data: historicalData, isLoading } = useQuery({
    queryKey: ['tradeData', agent.agent_id, selectedInterval],
    queryFn: () => fetchTradeData(agent.agent_id, start, end),
    staleTime: 30000,
  });

  // Отдельный запрос для последних данных
  const { data: recentData } = useQuery({
    queryKey: ['recentTradeData', agent.agent_id],
    queryFn: async () => {
      const recentStart = Math.floor(Date.now() / 1000) - 10;
      const recentEnd = Math.floor(Date.now() / 1000);
      return fetchTradeData(agent.agent_id, recentStart, recentEnd);
    },
    enabled: !!historicalData?.length,
    refetchInterval: 10000, // Обновляем каждые 10 секунд
    refetchIntervalInBackground: true,
  });

  // Обновляем график при получении новых данных
  useEffect(() => {
    if (!candleSeriesRef.current || !recentData?.length || !historicalData?.length) return;

    const lastHistoricalTime = Number(historicalData[historicalData.length - 1].time);

    // Фильтруем и обновляем только новые данные
    const newData = recentData.filter((item) => Number(item.time) > lastHistoricalTime);

    if (newData.length > 0) {
      newData.forEach((item) => {
        candleSeriesRef.current?.update({
          ...item,
          time: item.time,
        });
      });
    }
  }, [recentData, historicalData]);

  useEffect(() => {
    if (!chartContainerRef.current || !historicalData?.length) return;

    chartContainerRef.current.innerHTML = '';

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: 'black' },
        textColor: '#B3B5BD',
      },
      grid: {
        vertLines: { color: 'rgba(139, 92, 246, 0.1)' },
        horzLines: { color: 'rgba(139, 92, 246, 0.1)' },
      },
      width: chartContainerRef.current.clientWidth,
      height: 400,
    });

    candleSeriesRef.current = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
      priceFormat: {
        precision: 8,
        minMove: 0.00000001,
      },
    });

    // Убедимся, что время в числовом формате при установке исторических данных
    candleSeriesRef.current.setData(
      historicalData.map((item) => ({
        ...item,
        time: item.time,
      })),
    );

    chart.timeScale().fitContent();
    chart.applyOptions({
      timeScale: {
        timeVisible: true, // Включает отображение времени
        secondsVisible: true, // Показывает секунды (если данные с интервалом в секундах)
      },
    });

    const handleResize = () => {
      if (chartContainerRef.current) {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [historicalData]);

  if (isLoading) {
    return (
      <div className="flex h-[400px] w-full flex-col items-center justify-center">
        <div className="mb-4 h-4 w-4">
          <CircularProgress spinnerSize="full" className="fill-white" />
        </div>
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-xl bg-black">
      <div className="m-4 flex items-center justify-between gap-4">
        <div className="grid grid-cols-3 gap-2 sm:grid-cols-6">
          {(['1M', '5M', '15M', '1H', '24H', '7D'] as TimeInterval[]).map((interval) => (
            <UIButton
              key={interval}
              onClick={() => setSelectedInterval(interval)}
              className={`w-full ${selectedInterval === interval ? '' : 'opacity-60'}`}
            >
              {interval}
            </UIButton>
          ))}
        </div>
      </div>
      <div ref={chartContainerRef} className="h-[400px]" />
    </div>
  );
};
