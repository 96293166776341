import {
  createOkxSwapWidget,
  IWidgetParams,
  OkxEvents,
  ProviderType,
  THEME,
  TradeType,
} from '@okxweb3/dex-widget';
import { FC, useEffect, useRef } from 'react';
import { ADDRESSES } from 'src/contracts/addresses.ts';
import { Agent } from 'src/types/agents.ts';

export const OkxWidget: FC<{ agent: Agent }> = ({ agent }) => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) return;

    const params: IWidgetParams = {
      theme: THEME.DARK,
      providerType: ProviderType.EVM,
      tradeType: TradeType.SWAP,
      tokenPair: {
        fromChain: 8453,
        toChain: 8453,
        fromToken: ADDRESSES.assetToken,
        toToken: agent?.updated_token_address || undefined,
      },
    };
    const provider = window.ethereum;

    const listeners = [
      {
        event: OkxEvents.ON_CONNECT_WALLET,
        handler: () => {
          provider.enable();
        },
      },
    ];

    const instance = createOkxSwapWidget(widgetRef.current, {
      params,
      provider,
      listeners,
    });
    return () => {
      instance.destroy();
    };
  }, []);

  return <div ref={widgetRef} className="overflow-hidden rounded-xl [&>iframe]:w-full" />;
};
