import { FC } from 'react';
import { TabType } from 'src/types/agents.ts';

interface TabsNavigationProps {
  tabs: TabType[];
  currentTab: string;
  onTabClick: (tab: TabType) => void;
  disabledTabs: string[];
}

export const TabsNavigation: FC<TabsNavigationProps> = ({
  tabs,
  currentTab,
  onTabClick,
  disabledTabs,
}) => {
  return (
    <div className="my-5 grid grid-cols-1 gap-2 sm:grid-cols-3">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`rounded-xl border-2 px-3 py-2 text-lg ${
            currentTab === tab.value ? 'border-white' : 'border-[#232323]'
          } ${disabledTabs.includes(tab.value) ? 'opacity-60' : ''}`}
          onClick={() => onTabClick(tab)}
          aria-pressed={currentTab === tab.value}
          disabled={disabledTabs.includes(tab.value)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};
