import { NewAgent } from 'src/types/agents';

const basicTabFields = [
  'name',
  'image_url',
  'description',
  'ticker',
  'character',
  'goal',
  'origin',
];

const personalityTabFields = [
  'image_url',
  'name',
  'ticker',
  'character',
  'origin',
  'goal',
  'description',
];

const integrationsTabFields = [
  'image_url',
  'name',
  'ticker',
  'character',
  'origin',
  'goal',
  'description',
  'greeting',
  'additional_personality_info',
];

const launchTabFields = [
  'image_url',
  'name',
  'ticker',
  'character',
  'origin',
  'goal',
  'description',
  'greeting',
  'additional_personality_info',
];

const contentTabFields = [
  'image_url',
  'name',
  'ticker',
  'character',
  'origin',
  'goal',
  'description',
  'greeting',
  'additional_personality_info',
];

const isBasicFieldsInvalid = (values: NewAgent) =>
  !values.image_url ||
  !values.name ||
  !values.ticker ||
  !values.origin ||
  !values.character ||
  !values.goal ||
  !values.description;

const isCustomFieldsInvalid = (values: NewAgent) =>
  values.personality_type === 'Custom' && (!values.greeting || !values.additional_personality_info);

export const TAB_VALIDATION_CONFIG = {
  basic: {
    fields: basicTabFields,
    canNavigateTo: () => true,
    isDisabled: isBasicFieldsInvalid,
  },
  personality: {
    fields: personalityTabFields,
    canNavigateTo: (targetTab: string) => targetTab === 'basic',
    isDisabled: isBasicFieldsInvalid,
  },
  integrations: {
    fields: integrationsTabFields,
    canNavigateTo: (targetTab: string) => ['basic', 'personality'].includes(targetTab),
    isDisabled: (values: NewAgent) => isBasicFieldsInvalid(values) || isCustomFieldsInvalid(values),
  },
  content: {
    fields: contentTabFields,
    canNavigateTo: (targetTab: string) =>
      ['basic', 'personality', 'integrations'].includes(targetTab),
    isDisabled: (values: NewAgent) => isBasicFieldsInvalid(values) || isCustomFieldsInvalid(values),
  },
  'knowledge-base': {
    fields: [],
    canNavigateTo: (targetTab: string) =>
      ['basic', 'personality', 'integrations', 'content'].includes(targetTab),
    isDisabled: (values: NewAgent) =>
      isBasicFieldsInvalid(values) || isCustomFieldsInvalid(values) || values.tools.length === 0,
  },
  launch: {
    fields: launchTabFields,
    canNavigateTo: (targetTab: string) =>
      ['basic', 'personality', 'integrations', 'knowledge-base'].includes(targetTab),
    isDisabled: (values: NewAgent) =>
      isBasicFieldsInvalid(values) || isCustomFieldsInvalid(values) || values.tools.length === 0,
  },
} as const;

export type TabValidationConfig = typeof TAB_VALIDATION_CONFIG;

export const getDisabledTabs = (values: NewAgent): string[] => {
  return Object.entries(TAB_VALIDATION_CONFIG).reduce<string[]>((acc, [tab, config]) => {
    if (config.isDisabled(values)) {
      acc.push(tab);
    }
    return acc;
  }, []);
};
