import { FC, InputHTMLAttributes } from 'react';

type Props = {
  label: string;
  desc?: string;
  checked: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'checked'>;

export const UIRadio: FC<Props> = ({ label, checked, desc, ...props }) => {
  return (
    <label
      className={`flex cursor-pointer items-center gap-4 rounded-xl border-2 transition-all duration-200 ease-in-out ${checked ? 'border-[#8648D2]' : 'border-[#232323]'} bg-black p-3`}
    >
      <div
        className={`flex size-6 items-center justify-center rounded-full border-2 ${checked ? 'border-[#8648D2]' : 'border-[#232323]'}`}
      >
        {checked && (
          <div
            className="size-4 rounded-full border-2 border-transparent bg-[#8648D2]"
            style={{ borderColor: 'transparent' }}
          />
        )}
      </div>
      <input hidden {...props} type="radio" checked={checked} />
      <div>
        <div>{label}</div>
        {desc && <div className="text-xs">{desc}</div>}
      </div>
    </label>
  );
};
