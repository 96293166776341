import { Icon } from '@iconify/react';
import { FC } from 'react';
import { SORT_OPTIONS } from 'src/constants/agents';
import { useAgents } from 'src/providers/AgentsProvider';
import { UIDropdownMenu } from '../ui/UIDropdownMenu';

export const AgentsTableFilter: FC = () => {
  const { sortState } = useAgents();

  const [currentSort, setCurrentSort] = sortState;

  function handleSortSelect(sortOption: (typeof SORT_OPTIONS)[number]) {
    setCurrentSort(sortOption);
  }

  const sortItems = SORT_OPTIONS.map((item) => ({
    content: item.label,
    onClick: () => handleSortSelect(item),
  }));
  return (
    <UIDropdownMenu
      items={sortItems}
      button={{
        className:
          'flex items-center justify-center gap-2 rounded-xl bg-[#191C1E] px-3 py-1.5 border border-[#848484]',
        content: (
          <>
            <span className="font-regular text-sm">Sort by: {currentSort.label}</span>
            <Icon icon="fa6-solid:caret-down" />
          </>
        ),
      }}
    />
  );
};
