import { useFormikContext } from 'formik';
import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { NewAgent } from 'src/types/agents';

export const AgentPreview: FC = () => {
  const { values } = useFormikContext<NewAgent>();
  return (
    <div
      className="col-span-1 min-h-[600px] p-6 sm:p-8"
      style={{
        background:
          'linear-gradient(170deg, rgba(0,0,0,1) 0%, rgba(36,6,74,1) 50%, rgba(22,1,48,1) 100%)',
      }}
    >
      <h2 className="mb-6 text-center text-lg font-medium text-gray-200 sm:mb-8">
        Building your agent...
      </h2>

      <div className="relative">
        <div className="absolute inset-0 animate-glow">
          <ItemWithFancyBorder className="h-full rounded-xl bg-purple-500/20" />
        </div>
        <ItemWithFancyBorder className="relative rounded-xl">
          <div className="mx-auto rounded-xl bg-[#0A001E] p-6 sm:p-8">
            {values.image_url && (
              <div className="mb-8 flex justify-center">
                <img
                  className="size-32 rounded-full object-cover ring-2 ring-purple-500/30 sm:size-44"
                  src={values.image_url}
                  alt={`${values.name} preview`}
                />
              </div>
            )}

            <div className="mx-auto space-y-4 sm:space-y-5">
              {[
                { label: 'Name', value: values.name },
                { label: 'Ticker', value: values.ticker },
                { label: 'Description', value: values.description },
                { label: 'Interests', value: values.origin?.join(', ') },
                { label: 'Goal', value: values.goal },
                { label: 'Character', value: values.character },
                { label: 'Personality', value: values.personality_type },
                { label: 'AI Model', value: values.ai_model },
                { label: 'Tools', value: values.tools?.join(', ') },
              ].map(({ label, value }) => (
                <div key={label} className="flex items-center justify-between gap-4">
                  <span className="text-sm text-white">{label}</span>
                  <span className="text-right text-sm text-white opacity-60">{value}</span>
                </div>
              ))}
            </div>
          </div>
        </ItemWithFancyBorder>
      </div>
    </div>
  );
};
