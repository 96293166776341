import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { Agent } from 'src/types/agents';
import { BN, getDisplayAmount, shortenNumber } from 'src/utils/bigNumber';

interface TokenDataCardProps {
  agent: Agent;
}

export const TokenDataCard: FC<TokenDataCardProps> = ({ agent }) => {
  return (
    <ItemWithFancyBorder className="rounded-xl">
      <div className="h-full rounded-xl bg-black p-6">
        <div className="mb-6 flex items-center">
          <span className="text-lg font-bold">Token Data</span>
        </div>
        <div className="grid grid-cols-2 gap-4 xl:grid-cols-4">
          <div className="flex flex-col">
            <span className="text-lg">MCap</span>
            <span className="text-sm opacity-60">
              {shortenNumber(getDisplayAmount(agent.token_info.market_cap))}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-lg">24h Chg</span>
            <span className="text-sm opacity-60">
              {BN(agent.token_info.price).minus(agent.token_info.prev_price).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-lg">TVL</span>
            <span className="text-sm opacity-60">
              {shortenNumber(getDisplayAmount(agent.token_info.liquidity))}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-lg">24h Vol</span>
            <span className="text-sm opacity-60">
              {shortenNumber(getDisplayAmount(agent.token_info.volume_24))}
            </span>
          </div>
        </div>
      </div>
    </ItemWithFancyBorder>
  );
};
