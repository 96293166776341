import { Icon } from '@iconify/react';
import { FC } from 'react';

const colorClass = {
  info: {
    bgColor: 'bg-white',
    textColor: 'text-white',
  },
  success: {
    bgColor: 'bg-[#52C41A]',
    textColor: 'text-[#52C41A]',
  },
  error: {
    bgColor: 'bg-[#ED1522]',
    textColor: 'text-[#ED1522]',
  },
  warning: {
    bgColor: 'bg-[#FFE662]',
    textColor: 'text-[#FFE662]',
  },
} as const;

export const UIAlert: FC<{
  type: 'info' | 'success' | 'warning' | 'error';
  message: string;
}> = ({ message, type }) => {
  const { bgColor, textColor } = colorClass[type];

  const iconMap = {
    info: 'fa6-solid:circle-info',
    success: 'fa6-solid:circle-check',
    warning: 'fa6-solid:triangle-exclamation',
    error: 'fa6-solid:circle-xmark',
  };

  return (
    <div
      className={`flex items-center gap-4 rounded-xl bg-opacity-20 px-6 py-3 text-xl ${bgColor}`}
    >
      <Icon icon={iconMap[type]} className={textColor} />
      <span>{message}</span>
    </div>
  );
};
