import { useEffect, useRef } from 'react';

/**
 * Custom React hook that returns the previous value of a given variable.
 *
 * @param value The current value whose previous value is tracked
 * @returns The previous value of `value` or undefined if it's the first render
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
