export function getNextUtcMidnightTimestamp() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();

  const nextMidnight = new Date(Date.UTC(year, month, day + 1, 0, 0, 0));

  return nextMidnight.getTime();
}
