import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { FC, ReactNode } from 'react';

const buttonDefaultClassName =
  'inline-flex items-center gap-2 rounded-md bg-gray-800 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white';
const itemDefaultClassName =
  'group flex w-full items-center gap-2 rounded-xl px-3 py-1.5 data-[focus]:bg-white/10';

export interface UIDropDownMenuItem {
  content: ReactNode;
  onClick?: () => void;
  className?: string;
}

interface UIDropdownMenuProps {
  items: UIDropDownMenuItem[];
  button: UIDropDownMenuItem;
}

export const UIDropdownMenu: FC<UIDropdownMenuProps> = ({ items, button }) => {
  return (
    <Menu>
      <MenuButton onClick={button.onClick} className={button.className || buttonDefaultClassName}>
        {button.content}
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom"
        className="z-50 origin-top-right rounded-xl border border-white/5 bg-[#370651] p-1 text-sm/6 text-white shadow-lg shadow-black/50 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {items.map((item, index) => (
          <MenuItem key={index}>
            <button onClick={item.onClick} className={item.className || itemDefaultClassName}>
              {item.content}
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};
