import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import useEmblaCarousel from 'embla-carousel-react';
import { useFormikContext } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { LoadingStub } from 'src/components/ui/LoadingStub';
import { UIDropdownMenu } from 'src/components/ui/UIDropdownMenu';
import { axiosInstance } from 'src/libs/axios';
import { NewAgent } from 'src/types/agents';
import { HeygenAvatar } from 'src/types/heygen';

type Filters = {
  age?: string;
  gender?: string;
  type?: string;
};

export const AvatarCarousel: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [filters, setFilters] = useState<Filters>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { data, isLoading } = useQuery({
    queryKey: ['heygen-avatars'],
    queryFn: () =>
      axiosInstance
        .get<{ avatars: HeygenAvatar[] }>(`/api/heygen/avatars`)
        .then((res) => res.data?.avatars),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
  });

  // Universal filter handler
  const handleFilterChange = (filterType: keyof Filters, value: string | undefined) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  // Filter avatars based on selected filters
  const filteredAvatars = useMemo(() => {
    return data?.filter((avatar) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        if (key === 'gender') return avatar.gender === value.toLowerCase();
        return true;
      });
    });
  }, [data, filters]);

  // Group filtered avatars - changed from 6 to 9 avatars per group
  const avatarGroups = useMemo(() => {
    return filteredAvatars?.reduce((groups: HeygenAvatar[][], avatar, index) => {
      const groupIndex = Math.floor(index / 9);
      if (!groups[groupIndex]) {
        groups[groupIndex] = [];
      }
      groups[groupIndex].push(avatar);
      return groups;
    }, []);
  }, [filteredAvatars]);

  // Add useEffect to update total pages when filtered avatars change
  useEffect(() => {
    if (!emblaApi || !avatarGroups) return;

    const newTotalPages = avatarGroups.length || 1;
    setTotalPages(newTotalPages);

    // Reset to first page if current page is beyond new total
    if (currentPage > newTotalPages) {
      setCurrentPage(1);
      emblaApi.scrollTo(0);
    }
  }, [avatarGroups, emblaApi, currentPage]);

  // Keep the existing useEffect for pagination
  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCurrentPage(emblaApi.selectedScrollSnap() + 1);
    };

    setCurrentPage(emblaApi.selectedScrollSnap() + 1);

    emblaApi.on('select', onSelect);
    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi]);

  return (
    <div className={!values.twitter_agent_post_videos ? 'pointer-events-none opacity-60' : ''}>
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-lg">Choose Your Avatar</h3>
        <div className="flex gap-4">
          <UIDropdownMenu
            items={[
              { content: 'Male', onClick: () => handleFilterChange('gender', 'Male') },
              { content: 'Female', onClick: () => handleFilterChange('gender', 'Female') },
              { content: 'Clear', onClick: () => handleFilterChange('gender', undefined) },
            ]}
            button={{
              className:
                'flex items-center justify-center gap-2 rounded-xl bg-[#370651] px-3 py-1.5 border border-[#848484]',
              content: (
                <>
                  <span className="font-regular text-sm">{filters.gender || 'Gender'}</span>
                  <Icon icon="fa6-solid:caret-down" />
                </>
              ),
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingStub label="Loading avatars..." />
        </div>
      ) : (
        <div className="relative">
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="flex">
              {avatarGroups?.map((group, groupIndex) => (
                <div key={groupIndex} className="relative mr-4 min-w-0 flex-[0_0_100%]">
                  <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:grid-cols-3 sm:grid-rows-3">
                    {group?.map((avatar) => (
                      <div
                        key={avatar.avatar_id}
                        className="relative aspect-square cursor-pointer"
                        onClick={() => {
                          setFieldValue('heygen.avatar_id', avatar.avatar_id);
                        }}
                      >
                        <div
                          className={`h-full w-full overflow-hidden rounded-xl border-2 ${
                            values.heygen.avatar_id === avatar.avatar_id
                              ? 'border-purple-500'
                              : 'border-transparent'
                          }`}
                        >
                          <img
                            src={avatar.preview_image_url}
                            alt="Avatar option"
                            className="h-full w-full object-cover"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            className="absolute -left-4 top-1/2 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full bg-purple-500"
            onClick={() => emblaApi?.scrollPrev()}
            style={{
              background: 'linear-gradient(90deg, #801AB5 0%, #A752D5 100%)',
            }}
          >
            <Icon className="size-6" icon="fa6-solid:caret-left" />
          </button>
          <button
            className="absolute -right-4 top-1/2 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full bg-purple-500"
            onClick={() => emblaApi?.scrollNext()}
            style={{
              background: 'linear-gradient(180deg, #801AB5 0%, #A752D5 100%)',
            }}
          >
            <Icon className="size-6" icon="fa6-solid:caret-right" />
          </button>

          <div className="mt-4 flex justify-center">
            <div className="flex items-center gap-3 rounded-full bg-[#370651] px-4 py-1.5 text-sm">
              <button
                className="text-gray-400 hover:text-white disabled:opacity-60"
                onClick={() => emblaApi?.scrollPrev()}
                disabled={currentPage === 1}
                aria-label="Previous page"
              >
                <Icon icon="fa6-solid:chevron-left" className="size-4" />
              </button>
              <span className="min-w-[4rem] text-center">
                {currentPage} / {totalPages}
              </span>
              <button
                className="text-gray-400 hover:text-white disabled:opacity-60"
                onClick={() => emblaApi?.scrollNext()}
                disabled={currentPage === totalPages}
                aria-label="Next page"
              >
                <Icon icon="fa6-solid:chevron-right" className="size-4" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
