import { Icon } from '@iconify/react/dist/iconify.js';
import { useAppKit, useAppKitAccount } from '@reown/appkit/react';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router';
import HeaderLogo from 'src/assets/images/header-logo.svg?react';
import { AgentsTable } from 'src/components/agents/AgentsTable';
import { DrawerSidebar } from 'src/components/layout/Drawer';
import { Footer } from 'src/components/layout/Footer.tsx';
import { NavBar } from 'src/components/layout/NavBar';
import { FancyButton } from 'src/components/ui/FancyButton.tsx';
import { UIButton } from 'src/components/ui/UIButton';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { SORT_OPTIONS } from 'src/constants/agents.ts';
import { useDebounce } from 'src/hooks/useDebounce.tsx';
import { axiosInstance } from 'src/libs/axios.ts';
import { Agent } from 'src/types/agents.ts';
import { shortenHash } from 'src/utils/ui.ts';

export const AppLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { open } = useAppKit();
  const { address, isConnected } = useAppKitAccount();
  const [showSearch, setShowSearch] = useState(true);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    function handleClick() {
      setShowSearch(false);
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    setSearch('');
    setShowSearch(false);
  }, [location]);

  const { data, isError, isFetching } = useQuery({
    queryKey: ['agents-search', debouncedSearch],
    queryFn: async () => {
      const { sort_order, sort_by } = SORT_OPTIONS[0];
      const params: Record<string, string> = {
        sort_order,
        sort_by,
        limit: '10',
        skip: '0',
        search,
      };
      const res = await axiosInstance.get<{ items_count: string; result: Agent[] }>('/api/agents', {
        params,
      });
      return res.data;
    },
    enabled: !!debouncedSearch,
  });

  function handleConnect() {
    open();
  }

  function handleSearchClick(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.stopPropagation();
    setShowSearch(!showSearch);
  }

  function handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-grow flex-col">
        <div className="relative z-30 border-b border-[#4C237F] bg-[url('/images/header-bg.png')] bg-[length:40%_auto] bg-top bg-no-repeat">
          <div className="container mx-auto flex items-center justify-between px-8 py-4">
            <NavLink to="/">
              <div className="flex items-center gap-4">
                <HeaderLogo className="h-10" />
                <span className="hidden text-xl font-bold sm:block">Aither</span>
              </div>
            </NavLink>

            <div className="hidden flex-col items-center gap-10 opacity-60 xl:flex xl:flex-row">
              <NavBar />
            </div>

            <div className="flex items-center gap-5">
              <NavLink className="hidden md:block" to="create">
                <FancyButton className="h-11">Create Agent</FancyButton>
              </NavLink>
              <FancyButton className="h-11" onClick={handleSearchClick}>
                <Icon icon="fa6-solid:magnifying-glass" />
              </FancyButton>
              {isConnected ? (
                <UIButton className="hidden bg-transparent md:block" onClick={handleConnect}>
                  {shortenHash(address!)}
                </UIButton>
              ) : (
                <UIButton className="hidden bg-transparent md:block" onClick={handleConnect}>
                  Connect wallet
                </UIButton>
              )}
              <button className="xl:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <Icon icon="fa6-solid:bars" className="text-2xl" />
              </button>
            </div>
          </div>

          {showSearch && (
            <div
              className={`animate__animated ${
                showSearch ? 'animate__fadeIn' : 'animate__fadeOut'
              } absolute left-0 right-0 top-[80px] z-40 w-full bg-black/95 py-4 backdrop-blur-2xl`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="container mx-auto">
                <div className="relative px-4">
                  <UIInput
                    placeholder="Search by Agent name"
                    onChange={handleSearchInputChange}
                    value={search}
                    autoFocus
                  />
                  <button
                    className="absolute right-6 top-1/2 -translate-y-1/2 text-2xl text-gray-400"
                    onClick={() => setShowSearch(false)}
                  >
                    <Icon icon="fa6-solid:times" />
                  </button>
                </div>
                <div className="max-h-[60vh] overflow-y-auto px-4 pb-10 pt-8">
                  {isFetching ? (
                    <p className="text-center text-gray-400">Loading...</p>
                  ) : isError ? (
                    <p className="text-center text-red-500">Error occurred while searching</p>
                  ) : !data ? (
                    <p className="text-center text-gray-400">Search results will appear here</p>
                  ) : data.result.length === 0 ? (
                    <p className="text-center text-gray-400">No results found</p>
                  ) : (
                    data.result.map((agent) => (
                      <AgentsTable
                        key={agent.agent_id}
                        agents={[agent]}
                        withSocials
                        withControls={false}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Outlet />
      </div>

      <Footer
        NavBar={
          <div className="flex flex-col items-center gap-10 opacity-60 lg:flex-row">
            <NavBar />
          </div>
        }
      />
      <DrawerSidebar isOpen={isMenuOpen} close={() => setIsMenuOpen(false)} type="app" />
    </div>
  );
};
