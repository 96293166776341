import { useFormikContext } from 'formik';
import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { UISwitch } from 'src/components/ui/UISwitch';
import { NewAgent } from 'src/types/agents';
import { AvatarCarousel } from './AvatarCarousel';
import { VoiceAccordion } from './VoiceAccordion';

export const VideoSettings: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-0.5">
          <span className="text-lg">Enable X (Twitter) Video Posting</span>
          <span className="text-sm opacity-60">
            Activate video posting capabilities for your AI agent along with text-based tweets on X.
          </span>
        </div>

        <ItemWithFancyBorder className="rounded-xl">
          <div
            style={{
              background:
                'linear-gradient(23deg, rgba(61,15,119,1) 0%, rgba(61,15,119,1) 16%, rgba(0,0,0,1) 40%)',
            }}
            className="flex items-center justify-between rounded-xl p-6"
          >
            <span className="text-xl">⭐️ Enable Video Post</span>
            <UISwitch
              disabled={!values.twitter_agent_regular_post}
              value={values.twitter_agent_post_videos}
              onChange={(e) => setFieldValue('twitter_agent_post_videos', e)}
            />
          </div>
        </ItemWithFancyBorder>
      </div>

      <div className="flex flex-col gap-0.5">
        <span className="text-lg">Video Personality Customization</span>
        <span className="text-sm opacity-60">
          Personalize your avatar to match your AI agent&apos;s unique identity.
        </span>
      </div>

      <AvatarCarousel />

      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-0.5">
          <span className="text-lg">Pick Your Voice</span>
        </div>

        <VoiceAccordion />
      </div>
    </>
  );
};
