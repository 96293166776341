import { Link, NavLink } from 'react-router';
import { useSetModal } from 'src/providers/ModalsProvider';

export const NavBar = () => {
  const setModal = useSetModal();

  return (
    <>
      <NavLink to="/agents">Marketplace</NavLink>
      <button onClick={() => setModal({ modalKey: 'how-it-works' })}>How it Works</button>
      <button onClick={() => setModal({ modalKey: 'avm' })}>Connect to AVM</button>
      <Link to="https://docs.aither.xyz/" target="_blank" rel="noreferrer">
        Docs
      </Link>
      <NavLink to="/bridge">Bridge</NavLink>
    </>
  );
};
