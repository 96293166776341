import {
  AiModel,
  Character,
  CONTENT_DIRECTION,
  CRISIS_COMMUNICATION,
  EthicalRule,
  Genre,
  Goal,
  NewAgent,
  Personality,
  PRO_ACTIVITY_LVL,
  SENTIMENT,
  TabType,
  TELEGRAM_GROUP_ENGAGEMENT_STRATEGY,
  Tool,
  TwitterCommentStrategy,
  TwitterEmotion,
} from 'src/types/agents.ts';

export const CATEGORIES = [
  {
    icon: '🤪',
    value: 'Memes',
  },
  {
    icon: '🎮',
    value: 'Games',
  },
  {
    icon: '🧠',
    value: 'Tech',
  },
  {
    icon: '👺',
    value: 'Anime',
  },
  {
    icon: '🍿',
    value: 'Movies',
  },
  {
    icon: '📚',
    value: 'Writer',
  },
  {
    icon: '🎵',
    value: 'Music',
  },
  {
    icon: '😌',
    value: 'Real Life',
  },
  {
    icon: '🎨',
    value: 'Art',
  },
  {
    icon: '⭐',
    value: 'Influencer (KOL)',
  },
  {
    icon: '🦸‍♂️',
    value: 'Fitness / Wellness',
  },
  {
    icon: '👔',
    value: 'Fashion',
  },
  {
    icon: '🍔',
    value: 'Food',
  },
  {
    icon: '⚽️',
    value: 'Sports',
  },
  {
    icon: '🗽',
    value: 'Travel',
  },
  {
    icon: '🏛️',
    value: 'History',
  },
];

export const GOALS = [
  {
    icon: '🎭',
    value: Goal.ROLEPLAY,
  },
  {
    icon: '🤖',
    value: Goal.ASSISTANT,
  },
  {
    icon: '🦄',
    value: Goal.MASCOT,
  },
];

export const GENRES = [
  {
    icon: '',
    value: Genre.NO_GENRE,
  },
  {
    icon: '💥',
    value: Genre.ACTION,
  },
  {
    icon: '👹',
    value: Genre.FICTIONAL,
  },
  {
    icon: '💰',
    value: Genre.FINANCE,
  },
  {
    icon: '💬',
    value: Genre.POLITICS,
  },
  {
    icon: '🧠',
    value: Genre.PHILOSOPHY,
  },
  {
    icon: '🥰',
    value: Genre.ROMANCE,
  },
  {
    icon: '🏛️',
    value: Genre.HISTORICAL,
  },
  {
    icon: '🩸',
    value: Genre.HORROR,
  },
];

export const CHARACTERS = [
  {
    icon: '👨',
    value: Character.MALE,
  },
  {
    icon: '👩',
    value: Character.FEMALE,
  },
  {
    icon: '🙅',
    value: Character.NON_BINARY,
  },
  {
    icon: '🐱',
    value: Character.AI_AGENT,
  },
];

export const PERSONALITIES = [
  {
    label: Personality.BASIC,
    desc: 'Perfect for first-timers!',
    value: Personality.BASIC,
  },
  {
    label: Personality.INTROVERT,
    desc: 'Cozy up with conversations',
    value: Personality.INTROVERT,
  },
  {
    label: Personality.EXTROVERT,
    desc: 'Keep the energy flowing!',
    value: Personality.EXTROVERT,
  },
  {
    label: Personality.CUSTOM,
    desc: 'Build your own Personality',
    value: Personality.CUSTOM,
  },
];

export const TOOLS = [
  '🦎 CoinGecko',
  '🌐 Google',
  '🟦 Dextools',
  '🔗 Fetch HTTP',
  '🐺 Cryptopanic',
];

export const COMING_SOON_TOOLS = [
  'Cookie.fun',
  'Sentient.market',
  'Debank.com',
  'Zerion.io',
  'Defillama.com',
  'De.fi/rekt-database',
  'NFTscan.com',
  'Opensea.io',
  'Artemis.xyz',
  'X-alpha.ai',
  'Morphai.io',
];

export const CONTENT_DIRECTIONS = Object.values(CONTENT_DIRECTION);

export const AGENT_INIT_FORM: NewAgent = {
  socials: { telegram: '', twitter: '', website: '' },
  adaption_mode: false,
  knowledge_websites: [],
  knowledge_uploaded_files: [],
  personality_info: '',
  pro_activity_level: PRO_ACTIVITY_LVL.LOW,
  sentiment: SENTIMENT.FORMAL,
  telegram_automoderation: false,
  telegram_crisis_communication: CRISIS_COMMUNICATION.APOLOGETIC,
  telegram_group_engagement_strategy: TELEGRAM_GROUP_ENGAGEMENT_STRATEGY.FRIENDLY,
  telegram_welcome_message: '',
  twitter_crisis_communication: CRISIS_COMMUNICATION.APOLOGETIC,
  twitter_sentiment_control: false,

  personality_type: Personality.CUSTOM,
  additional_personality_info: '',
  ai_model: AiModel.GPT_4O_MINI,
  creator_contract_address: '',
  character: undefined,
  ethical_rules: EthicalRule.FLEXIBLE,
  genre: Genre.NO_GENRE,
  goal: undefined,
  greeting: '',
  hobbies: [],
  knowledge_files: [],
  lorebook: { additionalProp1: '', additionalProp2: '', additionalProp3: '' },
  origin: undefined,
  ticker: '',
  tools: [Tool.COINGECKO, Tool.GOOGLE, Tool.DEXTOOLS, Tool.WEB_BROWSING, Tool.CRYPTOPANIC],
  twitter_add_hashtags: false,
  twitter_comment_strategy: TwitterCommentStrategy.CONSTRUCTIVE,
  twitter_emotion: TwitterEmotion.BALANCED,
  agent_id: '',
  name: '',
  description: '',
  image_url: '',
  telegram_token: '',
  twitter_username: '',
  twitter_agent_set_likes: false,
  twitter_agent_regular_post: false,
  twitter_agent_post_quoted_tweets: false,
  twitter_agent_answer_comments: false,
  twitter_agent_comment_tweets: false,
  twitter_agent_post_videos: false,
  twitter_content_examples: [''],
  twitter_content_specific_preferences: [''],
  twitter_content_directions: [],
  amount_to_buy: '',

  heygen: {
    avatar_id: '',
    voice_id: '',
    voice_language: '',
    voice_name: '',
    knowledge_base_id: '',
  },
};

export const SORT_OPTIONS = [
  {
    label: 'Market Cap',
    sort_by: 'market_cap',
    sort_order: 'desc',
  },
  // {
  //   label: 'Market Cap asc',
  //   sort_by: 'market_cap',
  //   sort_order: 'ask',
  // },
  {
    label: 'Created At',
    sort_by: 'created_at',
    sort_order: 'desc',
  },
  // {
  //   label: 'Created At asc',
  //   sort_by: 'created_at',
  //   sort_order: 'ask',
  // },
];

export const TABS: TabType[] = [
  {
    name: 'Basic',
    value: 'basic',
  },
  {
    name: 'Personality',
    value: 'personality',
  },
  {
    name: 'Integrations',
    value: 'integrations',
  },
  {
    name: 'Content',
    value: 'content',
  },
  {
    name: 'Knowledge Base',
    value: 'knowledge-base',
  },
  {
    name: 'Launch',
    value: 'launch',
  },
];
