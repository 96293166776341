import { Icon } from '@iconify/react/dist/iconify.js';
import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router';
import { toast } from 'sonner';
import HeaderLogo from 'src/assets/images/header-logo.svg?react';
import IconEagle from 'src/assets/images/icons/i-eagle.svg?react';
import IconTg from 'src/assets/images/icons/i-tg.svg?react';
import IconW from 'src/assets/images/icons/i-warpcast.svg?react';
import IconX from 'src/assets/images/icons/i-x.svg?react';
import { CA } from 'src/constants/contracts.ts';
import { shortenHash } from 'src/utils/ui.ts';

export const Footer: FC<{ NavBar?: ReactNode }> = ({ NavBar }) => {
  function handleCopy() {
    navigator.clipboard.writeText(CA);
    toast.success('Copied!');
  }

  const DefaultNavBar = (
    <nav className="flex w-full max-w-[600px] flex-wrap items-center justify-center gap-6">
      <NavLink
        className="text-base text-[#848484] transition-all duration-200 hover:translate-y-[-2px] hover:text-white sm:text-lg"
        to="/#section2"
      >
        Protocol Overview
      </NavLink>
      <NavLink
        className="text-base text-[#848484] transition-all duration-200 hover:translate-y-[-2px] hover:text-white sm:text-lg"
        to="/lite-paper"
      >
        AVM Lite Paper
      </NavLink>
      <a
        href="https://docs.aither.xyz"
        target="_blank"
        rel="noreferrer"
        className="text-base text-[#848484] transition-all duration-200 hover:translate-y-[-2px] hover:text-white sm:text-lg"
      >
        Documentation
      </a>
    </nav>
  );

  return (
    <footer className="border-t border-t-[#3E1473] bg-[#13001D]">
      <div className="container mx-auto px-4 py-6 sm:px-6 lg:px-8 xl:py-8">
        <div className="grid grid-cols-1 items-center gap-8 lg:grid-cols-[auto_1fr_auto]">
          {/* Logo Section */}
          <NavLink to="/" className="group justify-self-center transition-opacity hover:opacity-90">
            <div className="flex items-center gap-3">
              <HeaderLogo className="h-8 sm:h-10" />
              <span className="text-xl font-bold text-white opacity-90 group-hover:opacity-100">
                Aither
              </span>
            </div>
          </NavLink>

          {/* Navigation Links */}
          <div className="flex justify-center">{NavBar || DefaultNavBar}</div>

          {/* Contract Address and Social Links */}
          <div className="flex flex-col items-center gap-4">
            <div
              className="group flex cursor-pointer items-center justify-between gap-2 rounded-2xl border border-[#3E1473] bg-[#1A0029]/50 p-2 transition-all hover:border-[#4F1C94] hover:bg-[#1A0029] sm:gap-3"
              onClick={handleCopy}
            >
              <span className="text-sm text-[#848484] group-hover:text-white sm:text-base">
                CA:
              </span>
              <div className="flex items-center">
                <span className="px-2 text-sm text-white sm:text-base">{shortenHash(CA)}</span>
                <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-[#3E1473] transition-colors group-hover:bg-[#4F1C94]">
                  <Icon icon="fa6-solid:copy" className="text-white" />
                </div>
              </div>
            </div>

            {/* Social Links */}
            <div className="flex items-center gap-4 sm:gap-5">
              <a
                href="https://warpcast.com/~/channel/aither"
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-80"
              >
                <IconW className="h-5 w-5 sm:h-6 sm:w-6" />
              </a>
              <a
                href="https://x.com/Aither_protocol"
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-80"
              >
                <IconX className="h-5 w-5 sm:h-6 sm:w-6" />
              </a>
              <a
                href="https://t.me/aither_protocol"
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-80"
              >
                <IconTg className="h-5 w-5 sm:h-6 sm:w-6" />
              </a>
              <a
                href="https://dexscreener.com/ethereum/0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
                target="_blank"
                rel="noreferrer"
                className="transition-all hover:opacity-80"
              >
                <IconEagle className="h-5 w-5 sm:h-6 sm:w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
