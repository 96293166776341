import { Icon } from '@iconify/react';
import { useAgents } from 'src/providers/AgentsProvider';

// Helper that returns an array of page numbers (and "..." as strings) for display
function getPageNumbers(
  totalPages: number,
  currentPage: number,
  maxVisible: number = 7,
): Array<number | string> {
  // If the total number of pages is small, just show them all
  if (totalPages <= maxVisible) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const pages: Array<number | string> = [];

  // Always show the first page
  pages.push(1);

  // Calculate the range of pages to display around the current page
  const left = Math.max(currentPage - 2, 2); // start after page 1
  const right = Math.min(currentPage + 2, totalPages - 1); // end before last page

  // If there's a gap between 1 and left, show "..."
  if (left > 2) {
    pages.push('...');
  }

  // Add pages in [left..right]
  for (let page = left; page <= right; page++) {
    pages.push(page);
  }

  // If there's a gap between right and totalPages, show "..."
  if (right < totalPages - 1) {
    pages.push('...');
  }

  // Always show the last page
  pages.push(totalPages);

  return pages;
}

export function AgentsPagination() {
  // Pull our context values
  const { agentsQueryState, paginationState } = useAgents();

  const [pagination, setPagination] = paginationState;
  const { data, isLoading, isError } = agentsQueryState;

  // If loading or error, you might return a spinner or an error message
  if (isLoading || isError) {
    return null;
  }

  // data.items_count is a string; parse it to number
  const totalItems = data?.items_count ? parseInt(data.items_count) : 0;
  const limit = parseInt(pagination.limit, 10);
  const skip = parseInt(pagination.skip, 10);

  const totalPages = totalItems > 0 ? Math.ceil(totalItems / limit) : 1;
  const currentPage = Math.floor(skip / limit) + 1;

  // -- Navigation Helpers --
  const goToPage = (pageNumber: number) => {
    setPagination((prev) => ({
      ...prev,
      skip: ((pageNumber - 1) * limit).toString(),
    }));
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };

  // Generate pages array (with potential "..." values)
  const pages = getPageNumbers(totalPages, currentPage);

  return (
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      {/* Previous Button */}
      <button onClick={goToPrevPage} disabled={currentPage <= 1} className="text-2xl">
        <Icon icon="fa6-solid:chevron-left" />
      </button>

      {/* Page Number Buttons */}
      {pages.map((p, idx) => {
        if (p === '...') {
          return (
            <span key={`ellipsis-${idx}`} style={{ padding: '0 0.25rem' }}>
              ...
            </span>
          );
        }

        // p is a number
        const pageNum = p as number;
        const isActive = pageNum === currentPage;
        return (
          <button
            key={pageNum}
            onClick={() => goToPage(pageNum)}
            className={`border- block size-10 border bg-[#370651] ${isActive ? 'border-[#8648D2]' : 'border-[#370651]'}`}
          >
            {pageNum}
          </button>
        );
      })}

      {/* Next Button */}
      <button onClick={goToNextPage} disabled={currentPage >= totalPages}>
        <Icon icon="fa6-solid:chevron-right" className="text-2xl" />
      </button>
    </div>
  );
}
