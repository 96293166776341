import { FC } from 'react';
import { Modal } from 'src/components/ui/Modal.tsx';
import { UIButton } from 'src/components/ui/UIButton.tsx';
import { AVMModalSettings } from 'src/types/modal.ts';

export const AVMModal: FC<AVMModalSettings> = () => {
  return (
    <Modal>
      <div className="text-center text-2xl">AVM (Agent Virtual Machine)</div>
      <p className="my-4">
        AVM (Agent Virtual Machine) is the backbone of your AI agent, enabling advanced operations,
        integrations, and on-chain functionality. It translates your agent's commands into actions,
        allowing seamless interactions across platforms, improved performance, and the ability to
        scale. Integrating AVM unlocks the full potential of your AI agent by ensuring compatibility
        with decentralized ecosystems and empowering growth.
      </p>
      <p className="my-4">
        If you want an exclusive AI agent tailored specifically to your project's needs, contact us
        today!
      </p>
      <div className="flex items-center justify-center text-white">
        <a href="https://t.me/Whitepiratelove" target="_blank" rel="noreferrer">
          <UIButton>Contact Us</UIButton>
        </a>
      </div>
    </Modal>
  );
};
