import { FC } from 'react';
import { AgentsTable } from 'src/components/agents/AgentsTable';
import { AgentsPagination } from 'src/pages/agents/components/AgentsPagination.tsx';
import { useAgents } from 'src/providers/AgentsProvider.tsx';

export const AgentsPage: FC = () => {
  const { agentsQueryState } = useAgents();

  const { isLoading, data, isError } = agentsQueryState;

  return (
    <div className="container mx-auto flex h-full w-full flex-grow flex-col gap-3 overflow-y-auto px-4">
      <AgentsTable agents={data?.result || []} isLoading={isLoading} isError={isError} />
      <div className="my-8 flex justify-end">
        <AgentsPagination />
      </div>
    </div>
  );
};
