import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { UISelector } from 'src/components/ui/UISelector.tsx';
import { COMING_SOON_TOOLS, TOOLS } from 'src/constants/agents';
import { AiModel, NewAgent } from 'src/types/agents.ts';
import { TelegramIntegration } from './TelegramIntegration';
import { TwitterIntegration } from './TwitterIntegration';

import ClaudeLogoIcon from 'src/assets/images/icons/claude-logo.svg?react';
import DeepSeekLogoIcon from 'src/assets/images/icons/deepseek-logo.svg?react';
import GeminiLogoIcon from 'src/assets/images/icons/gemini-logo.svg?react';
import Gpt4LogoIcon from 'src/assets/images/icons/gpt4-logo.svg?react';
import Gpt4oLogoIcon from 'src/assets/images/icons/gpt4o-logo.svg?react';
import { UICheckboxGroup } from 'src/components/ui/UICheckboxGroup';

const AI_MODELS = [
  {
    icon: <DeepSeekLogoIcon width="181px" height="38px" />,
    value: AiModel.DEEPSEEK,
    name: 'DeepSeek',
  },
  { icon: <Gpt4LogoIcon width="95px" height="34px" />, value: AiModel.GPT_4O, name: 'GPT-4' },
  {
    icon: <Gpt4oLogoIcon width="95px" height="34px" />,
    value: AiModel.GPT_4O_MINI,
    name: 'GPT-4o',
  },
  { icon: <GeminiLogoIcon />, value: AiModel.GEMINI, name: 'Gemini' },
  { icon: <ClaudeLogoIcon />, value: AiModel.CLAUDE, name: 'Claude' },
];

export const IntegrationTab: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  return (
    <>
      <UISelector
        label="AI Model"
        description="Select the model that powers your agent's abilities and performance."
        value={{ name: values.ai_model, value: values.ai_model }}
        options={AI_MODELS}
        onSelect={(val) => setFieldValue('ai_model', val.value)}
      />

      <UICheckboxGroup
        label="Tools"
        description="List of the external tools and services your agent will use."
        items={TOOLS}
        values={TOOLS}
        onChange={() => {}}
        multiple={true}
      />

      <ItemWithFancyBorder className="rounded-xl">
        <Disclosure>
          {({ open }) => (
            <div className="rounded-xl bg-black px-6 py-4">
              <DisclosureButton className="flex w-full items-center justify-between">
                <span className="text-md">Coming Soon</span>
                <Icon
                  className={`size-4 transition-transform duration-200 ${open ? 'rotate-180' : ''}`}
                  icon="fa6-solid:caret-down"
                />
              </DisclosureButton>

              <DisclosurePanel className="mt-4">
                <UICheckboxGroup
                  items={COMING_SOON_TOOLS}
                  values={COMING_SOON_TOOLS}
                  onChange={() => {}}
                  multiple={true}
                />
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      </ItemWithFancyBorder>

      <div className="flex flex-col gap-0.5">
        <span className="text-lg">Platforms</span>
        <span className="text-sm opacity-60">
          Expand your Agent's visibility by deploying it on platforms like Twitter and Telegram.
        </span>
      </div>

      <TwitterIntegration />

      <div className="my-8 h-[2px] w-full bg-gradient-to-r from-transparent via-white/50 to-transparent" />

      <TelegramIntegration />
    </>
  );
};
