import { Icon } from '@iconify/react';
// @ts-ignore
import markdownItClass from '@toycode/markdown-it-class';
// @ts-ignore
import markdownIt from 'markdown-it';
// @ts-ignore
import markdownItLinkAttributes from 'markdown-it-link-attributes';
// @ts-ignore
import markdownItSanitizer from 'markdown-it-sanitizer';
// @ts-ignore
import markdownItSup from 'markdown-it-sup';
import { memo } from 'react';
import { toast } from 'sonner';
import { Message } from 'src/types/conversation';

export const AgiChatMessageItem = memo<{ message: Message }>(function AgiChatMessageItem({
  message,
}) {
  const isAI = message.user_id === 'ai' || !message.user_id;

  const sanitizeHTML = (content: string) =>
    markdownIt({ break: true })
      .use(markdownItClass, {
        img: ['rcw-message-img'],
      })
      .use(markdownItSup)
      .use(markdownItSanitizer)
      .use(markdownItLinkAttributes, { attrs: { target: '_blank', rel: 'noopener' } })
      .render(content);

  async function handleCopy(content: string) {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = sanitizeHTML(content);
    const txt = tempDivElement.textContent || tempDivElement.innerText || '';

    await navigator.clipboard.writeText(txt);

    toast.success('Message coped');
  }

  const steps = message.metadata.result?.answer_appendix?.steps || message.metadata.steps;
  // const plan = message.metadata.plan;

  return (
    <div
      className={`message-container-agi mb-2 flex w-11/12 items-start text-left ${
        isAI ? 'mr-auto justify-start' : 'ml-auto flex-row-reverse justify-end'
      }`}
    >
      <div
        className={`relative rounded-xl border border-white border-opacity-60 bg-opacity-10 px-4 py-2 backdrop-blur-3xl ${
          isAI ? 'ml-4 mr-auto bg-white pr-6 xl:pr-10' : 'ml-auto mr-2 bg-transparent'
        }`}
      >
        <div
          style={{ overflowWrap: 'anywhere' }}
          dangerouslySetInnerHTML={{ __html: sanitizeHTML(message.content) }}
        ></div>
        {/*{isAI && plan && (*/}
        {/*  <div className="opacity-40">*/}
        {/*    <h4>What AI is planing to do:</h4>*/}
        {/*    <div*/}
        {/*      style={{ overflowWrap: 'anywhere' }}*/}
        {/*      dangerouslySetInnerHTML={{ __html: sanitizeHTML(plan) }}*/}
        {/*    ></div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {isAI && steps && steps.length !== 0 && (
          <>
            <div className="flex flex-wrap items-center gap-2">
              {/*{steps.map((step, i) => (*/}
              {/*  <>*/}
              {/*    <button*/}
              {/*      id={`item_${i}`}*/}
              {/*      className="flex flex-shrink-0 items-center gap-1 rounded-xl bg-white bg-opacity-20 p-2"*/}
              {/*    >*/}
              {/*      {step.title}*/}
              {/*      {!step.output && (*/}
              {/*        <Icon className="animate-spin-slow -mt-0.5 ml-2" icon="fa6-solid:spinner" />*/}
              {/*      )}*/}
              {/*    </button>*/}
              {/*    {i !== steps.length - 1 && <Icon icon="fa6-solid:caret-right" />}*/}
              {/*    {step.output && (*/}
              {/*      <Tooltip*/}
              {/*        openOnClick*/}
              {/*        className="!h-80 !opacity-100"*/}
              {/*        style={{*/}
              {/*          color: '#000',*/}
              {/*          backgroundColor: 'white',*/}
              {/*          width: '700px',*/}
              {/*          maxHeight: '250px !important',*/}
              {/*          borderRadius: '14px',*/}
              {/*          zIndex: 10,*/}
              {/*        }}*/}
              {/*        anchorSelect={`#item_${i}`}*/}
              {/*        clickable*/}
              {/*      >*/}
              {/*        <div className="flex h-full gap-4">*/}
              {/*          <div className="flex w-1/2 flex-col overflow-hidden">*/}
              {/*            <div className="mb-1 flex justify-between">*/}
              {/*              <span>Input</span>*/}
              {/*              <button onClick={() => handleCopy(step.input)} className="opacity-20">*/}
              {/*                <Icon icon="fa6-solid:clone" />*/}
              {/*              </button>*/}
              {/*            </div>*/}
              {/*            <div className="flex-grow overflow-auto break-all rounded-xl border border-[#17191B] border-opacity-20 p-2">*/}
              {/*              <div*/}
              {/*                style={{ overflowWrap: 'anywhere' }}*/}
              {/*                dangerouslySetInnerHTML={{ __html: sanitizeHTML(step.input) }}*/}
              {/*              ></div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <div className="flex w-1/2 flex-col overflow-hidden">*/}
              {/*            <div className="mb-1 flex justify-between">*/}
              {/*              <span>Output</span>*/}
              {/*              <button onClick={() => handleCopy(step.output)} className="opacity-20">*/}
              {/*                <Icon icon="fa6-solid:clone" />*/}
              {/*              </button>*/}
              {/*            </div>*/}
              {/*            <div className="flex-grow overflow-auto break-all rounded-xl border border-[#17191B] border-opacity-20 p-2">*/}
              {/*              <div*/}
              {/*                style={{ overflowWrap: 'anywhere' }}*/}
              {/*                dangerouslySetInnerHTML={{ __html: sanitizeHTML(step.output) }}*/}
              {/*              ></div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </Tooltip>*/}
              {/*    )}*/}
              {/*  </>*/}
              {/*))}*/}
            </div>
          </>
        )}
        {isAI && message.content && (
          <div className="absolute right-2 top-2 flex items-center gap-2 opacity-40">
            <button onClick={() => handleCopy(message.content)}>
              <Icon icon="fa6-solid:copy" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
});
