import { FC } from 'react';
import { FancyButtonWithArrow } from 'src/components/ui/FancyButtonWithArrow';
import { TABS } from 'src/constants/agents';

interface NavigationButtonsProps {
  currentTab: string;
  disabledTabs: string[];
  onCreateAgent?: () => void;
  setCurrentTab: (tab: string) => void;
  isConnected?: boolean;
  isWrongNetwork?: boolean;
  isInsufficientFunds?: boolean;
  isPendingLaunchFee?: boolean;
}

export const NavigationButtons: FC<NavigationButtonsProps> = ({
  currentTab,
  disabledTabs,
  onCreateAgent,
  setCurrentTab,
  isConnected,
  isWrongNetwork,
  isInsufficientFunds,
  isPendingLaunchFee,
}) => {
  const isFirstTab = currentTab === TABS[0].value;
  const isLastTab = currentTab === TABS[TABS.length - 1].value;
  const isNextTabDisabled = disabledTabs.includes(
    TABS[TABS.findIndex((tab) => tab.value === currentTab) + 1]?.value,
  );
  function handleNextClick() {
    if (TABS[TABS.length - 1].value === currentTab) {
      return;
    }

    setCurrentTab(TABS[TABS.findIndex((tab) => tab.value === currentTab) + 1].value);
  }

  function handleBackClick() {
    if (TABS[0].value === currentTab) {
      return;
    }

    setCurrentTab(TABS[TABS.findIndex((tab) => tab.value === currentTab) - 1].value);
  }

  const buttonText = () => {
    if (!isConnected) return 'Connect wallet';
    if (isWrongNetwork) return 'Change network';
    if (isInsufficientFunds) return 'Insufficient funds';
    if (isPendingLaunchFee) return 'Pending launch fee';
    return 'Create';
  };

  if (isLastTab) {
    return (
      <div className="my-6 flex justify-end gap-4 px-0 sm:my-10 sm:gap-8">
        <button className="text-lg" onClick={handleBackClick}>
          Back
        </button>
        <FancyButtonWithArrow onClick={onCreateAgent} disabled={isInsufficientFunds}>
          {buttonText()}
        </FancyButtonWithArrow>
      </div>
    );
  }

  if (isFirstTab) {
    return (
      <div className="my-6 flex justify-end gap-4 sm:my-10 sm:gap-8">
        <FancyButtonWithArrow disabled={isNextTabDisabled} onClick={handleNextClick}>
          Next
        </FancyButtonWithArrow>
      </div>
    );
  }

  return (
    <div className="my-6 flex justify-end gap-4 sm:my-10 sm:gap-8">
      <button className="text-lg" onClick={handleBackClick}>
        Back
      </button>
      <FancyButtonWithArrow disabled={isNextTabDisabled} onClick={handleNextClick}>
        Next
      </FancyButtonWithArrow>
    </div>
  );
};
