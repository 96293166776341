import { useEffect } from 'react';
import ArrowRight from 'src/assets/images/arrow-right-1.svg?react';
import IconArrow from 'src/assets/images/btn-arrow.svg?react';
import { InViewComponent } from 'src/components/InViewComponent.tsx';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { useHookWithRefCallback } from 'src/hooks/useHookWithRefCallback.tsx';

export const CreateBlock = () => {
  const [ref1, setRef1] = useHookWithRefCallback<HTMLDivElement>();
  const [text1Ref, setText1Ref] = useHookWithRefCallback<HTMLParagraphElement>();
  const [title1Ref, setTitle1Ref] = useHookWithRefCallback<HTMLHeadingElement>();
  const [arrow1Ref, setArrow1Ref] = useHookWithRefCallback<HTMLImageElement>();
  const [img1Ref, setImg1Ref] = useHookWithRefCallback<HTMLImageElement>();
  const [ref2, setRef2] = useHookWithRefCallback<HTMLDivElement>();
  const [text2Ref, setText2Ref] = useHookWithRefCallback<HTMLParagraphElement>();
  const [title2Ref, setTitle2Ref] = useHookWithRefCallback<HTMLHeadingElement>();
  const [arrow2Ref, setArrow2Ref] = useHookWithRefCallback<HTMLImageElement>();
  const [img2Ref, setImg2Ref] = useHookWithRefCallback<HTMLImageElement>();

  useEffect(() => {
    if (!ref1) return;
    if (!title1Ref) return;
    if (!text1Ref) return;
    if (!arrow1Ref) return;
    if (!img1Ref) return;

    title1Ref?.classList.add('opacity-0');
    text1Ref?.classList.add('opacity-0');
    arrow1Ref?.classList.add('opacity-0');
    img1Ref?.classList.add('opacity-0');

    ref1.addEventListener('animationend', () => {
      // Add the classes to start the title animation after parent finishes
      title1Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      text1Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      arrow1Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      img1Ref?.classList.add('animate__animated', 'animate__fadeIn');
    });
  }, [ref1, title1Ref, text1Ref, img1Ref]);

  useEffect(() => {
    if (!ref2) return;
    if (!title2Ref) return;
    if (!text2Ref) return;
    if (!arrow2Ref) return;
    if (!img2Ref) return;

    title2Ref?.classList.add('opacity-0');
    text2Ref?.classList.add('opacity-0');
    arrow2Ref?.classList.add('opacity-0');
    img2Ref?.classList.add('opacity-0');

    ref2.addEventListener('animationend', () => {
      // Add the classes to start the title animation after parent finishes
      title2Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      text2Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      arrow2Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      img2Ref?.classList.add('animate__animated', 'animate__fadeIn');
    });
  }, [ref2, title2Ref, text2Ref, img2Ref]);

  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-10 container mx-auto relative z-10">
      <InViewComponent
        inViewClasses="animate__animated animate__slideInLeft"
        rootMargin={'0% 0% -300px 0%'}
        ref={setRef1}
      >
        <div className="border border-[#323232] rounded-xl lg:pt-[50%] relative group">
          <div className="lg:absolute left-0 top-0 w-full h-full p-6 font-space-grotesk">
            <h4 ref={setTitle1Ref} className="font-bold text-3xl lg:text-6xl mb-6">
              Create
            </h4>
            <p
              ref={setText1Ref}
              className="relative z-10 lg:static mb-8 lg:mb-0 lg:text-2xl font-light"
            >
              Launch Your AI Agent in
              <br />
              Seconds. Build, Deploy,
              <br />
              and Own with Ease.
            </p>
            <img
              ref={setImg1Ref}
              src="/images/create-block-bg.png"
              className="absolute right-0 bottom-0 w-[45%] lg:w-auto lg:h-full"
            />
          </div>
          <div
            ref={setArrow1Ref}
            className="absolute right-6 top-6 w-[12%] bg-[#1B1B1B] rounded-full group-hover:bg-black"
          >
            <div className="pt-[100%] relative">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[70%] w-full">
                <div className="group-hover:animate-move-arrow mx-auto">
                  <ArrowRight className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InViewComponent>
      <InViewComponent
        inViewClasses="animate__animated animate__slideInRight"
        rootMargin={'0% 0% -300px 0%'}
        ref={setRef2}
      >
        <div className="cursor-pointer border border-[#323232] rounded-xl lg:pt-[50%] relative group">
          <div className="lg:absolute left-0 top-0 w-full h-full p-6">
            <h4 className="font-bold text-3xl lg:text-6xl mb-6" ref={setTitle2Ref}>
              Unlimited
              <br />
              Power
            </h4>
            <p
              className="relative z-10 lg:static mb-8 lg:mb-0 lg:text-2xl font-light"
              ref={setText2Ref}
            >
              Your Agent will be on 100’s of
              <br />
              Channels at the same time.
              <br />
              Never losing focus on their
              <br />
              jobs.
            </p>
            <img
              src="/images/power-block-bg.png"
              ref={setImg2Ref}
              className="absolute right-0 bottom-0 h-full"
            />
          </div>
          <div
            ref={setArrow2Ref}
            className="absolute right-6 top-6 w-[12%] bg-[#1B1B1B] rounded-full group-hover:bg-black"
          >
            <div className="pt-[100%] relative">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[70%] w-full">
                <div className="group-hover:animate-move-arrow mx-auto">
                  <ArrowRight className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InViewComponent>
      <div className="lg:col-span-2">
        <InViewComponent
          inViewClasses="animate__animated animate__fadeIn"
          rootMargin={'0% 0% -300px 0%'}
        >
          <div className="border border-[#323232] rounded-xl p-6 pt-0">
            <div className="lg:pt-[25%] relative">
              <div className="lg:absolute left-1/2 lg:-translate-x-1/2 top-0 lg:h-full">
                <div className="relative h-full">
                  <div className="absolute left-[-5%] top-0 h-full w-[110%]">
                    <img src="/images/video-blur.png" className="h-full" />
                  </div>
                  <video className="lg:h-full" autoPlay playsInline muted loop>
                    <source src="/videos/ai-ther-agents.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="absolute left-0 top-0 w-full h-full flex flex-col gap-10 items-center justify-center">
                <p className="text-3xl lg:text-6xl font-bold">
                  Autonomous <span className="text-[#8446FF] leading-[1.6em]">AI Agents</span>
                  <br />
                  Taking Over the World
                </p>
              </div>
            </div>
            <section className="grid gird-cols-1 lg:grid-cols-3 gap-10 container mx-auto">
              <InViewComponent
                inViewClasses="animate__animated animate__fadeIn group"
                rootMargin={'0% 0% -300px 0%'}
              >
                <ItemWithFancyBorder instantHover className="rounded-xl" deg={130}>
                  <div
                    className="pt-[125%] rounded-xl font-space-grotesk relative"
                    style={{
                      background:
                        'url("/images/bg-master-digital.png") center bottom / 100% auto no-repeat, linear-gradient(173deg, rgba(37,8,52,1) 0%, rgba(25,3,37,1) 40%, rgba(50,6,74,1) 70%, rgba(91,18,131,1) 100%)',
                    }}
                  >
                    <div className="absolute left-0 top-0 w-full h-full pt-14 pb-6 px-8 flex flex-col">
                      <h4 className="text-[42px] mb-6">Master Digital</h4>
                      <p className=" text-2xl font-light">
                        Navigate apps, platforms, and networks globally.
                      </p>
                      <div className="flex items-center gap-4 mt-auto justify-end">
                        <span className="font-light text-2xl">Build agent (soon)</span>
                        <div className="size-14 flex items-center justify-center bg-black rounded-full group-hover:bg-white transition-all">
                          <IconArrow className="group-hover:fill-black transition-all group-hover:animate-move-arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ItemWithFancyBorder>
              </InViewComponent>
              <InViewComponent
                inViewClasses="animate__animated animate__fadeIn animate__delay-1s group"
                rootMargin={'0% 0% -300px 0%'}
              >
                <ItemWithFancyBorder instantHover className="rounded-xl" deg={130}>
                  <div
                    className="pt-[125%] rounded-xl relative"
                    style={{
                      background:
                        'url("/images/bg-think-act.png") center bottom / 100% auto no-repeat, linear-gradient(173deg, rgba(37,8,52,1) 0%, rgba(25,3,37,1) 40%, rgba(50,6,74,1) 70%, rgba(91,18,131,1) 100%)',
                    }}
                  >
                    <div className="absolute left-0 top-0 w-full h-full pt-14 pb-6 px-8 flex flex-col">
                      <h4 className="text-[42px] mb-6">Think and ACT!</h4>
                      <p className=" text-2xl font-light">
                        Fully autonomous learning and execution.
                      </p>
                      <div className="flex items-center gap-4 mt-auto justify-end">
                        <span className="font-light text-2xl">Build agent (soon)</span>
                        <div className="size-14 flex items-center justify-center bg-black rounded-full group-hover:bg-white transition-all">
                          <IconArrow className="group-hover:fill-black transition-all group-hover:animate-move-arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ItemWithFancyBorder>
              </InViewComponent>
              <InViewComponent
                inViewClasses="animate__animated animate__fadeIn animate__delay-2s group"
                rootMargin={'0% 0% -300px 0%'}
              >
                <ItemWithFancyBorder instantHover className="rounded-xl" deg={130}>
                  <div
                    className="pt-[125%] rounded-xl relative"
                    style={{
                      background:
                        'linear-gradient(160deg, rgba(37,8,52,1) 0%, rgba(25,3,37,1) 45%, rgba(50,6,74,1) 75%, rgba(91,18,131,1) 100%)',
                    }}
                  >
                    <div className="absolute bottom-[1px] rounded-xl overflow-hidden left-0 w-full">
                      <video className="w-full" autoPlay playsInline muted loop>
                        <source src="/videos/ai-ther-dollar_print.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className="absolute w-full h-full top-0 left-0 flex justify-end">
                      <img className="w-full" src="/images/earn-and-grow.png" />
                    </div>
                    <div className="absolute left-0 top-0 w-full h-full pt-14 pb-6 px-8 flex flex-col">
                      <h4 className="text-[42px] mb-6">Earn & Grow</h4>
                      <p className=" text-2xl font-light">
                        Generate and reinvest revenue independently.
                      </p>
                      <div className="flex items-center gap-4 mt-auto justify-end">
                        <span className="font-light text-2xl">Build agent (soon)</span>
                        <div className="size-14 flex items-center justify-center bg-black rounded-full group-hover:bg-white transition-all">
                          <IconArrow className="group-hover:fill-black transition-all group-hover:animate-move-arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ItemWithFancyBorder>
              </InViewComponent>
            </section>
          </div>
        </InViewComponent>
      </div>
    </section>
  );
};
