import { axiosInstance } from 'src/libs/axios';

export const checkTelegramBotToken = async (telegram_token: string, agent_id: string) => {
  if (!telegram_token || !agent_id) {
    return undefined;
  }
  try {
    const resp = await axiosInstance.post<{ username: string }>(
      `/api/agents/${agent_id}/connect_telegram_token`,
      { token: telegram_token },
    );
    return resp.data;
  } catch (error) {
    console.error('Check Telegram Token fail', error);
    return undefined;
  }
};

export const checkTelegramBotStarted = async (agent_id: string) => {
  if (!agent_id) {
    return undefined;
  }
  try {
    const resp = await axiosInstance.get<{ username: string }>(
      `/api/agents/${agent_id}/check_telegram_connected_user`,
    );
    return resp.data;
  } catch (error) {
    console.error('Check Telegram Token fail', error);
    return undefined;
  }
};

export const checkTwitterAuth = async (agent_id: string) => {
  try {
    const resp = await axiosInstance.get<{
      name: string;
      username: string;
      id: string;
    }>(`/api/agents/check_twitter_auth/${agent_id}`);
    return resp.data;
  } catch (error) {
    console.error('Check (X) Twitter Auth fail', error);
    return undefined;
  }
};

export const createAuthLink = async (agent_id: string, redirect_url: string) => {
  const resp = await axiosInstance.post(
    `/api/create_auth_link`,
    {},
    {
      params: { agent_id, redirect_url },
    },
  );
  return resp.data;
};
