import { createBrowserRouter, Navigate, RouteObject } from 'react-router';
import { Modals } from 'src/components/modals/Modals.tsx';
import { AppLayout } from 'src/layout/appLayout.tsx';
import { DefaultLayout } from 'src/layout/defaultLayout.tsx';
import {
  AgentsCreate,
  AgentsIdPage,
  AgentsPage,
  BridgePage,
  HomePage,
  PdfHandlerPage,
  StreamPage,
} from 'src/pages';
import { AgentsProvider } from 'src/providers/AgentsProvider.tsx';
import { BotProvider } from './pages/agents/agents--id/providers/BotProvider';

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <>
        <Modals />
        <DefaultLayout />
      </>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: 'stream', element: <StreamPage /> },
      { path: 'bridge', element: <BridgePage /> },
    ],
  },
  {
    path: '/agents',
    element: (
      <AgentsProvider>
        <Modals />
        <AppLayout />
      </AgentsProvider>
    ),
    children: [
      { index: true, element: <AgentsPage /> },
      { path: 'create', element: <AgentsCreate /> },
      {
        path: ':id',
        element: <BotProvider lsKey={'bot-user-id'} />,
        children: [{ index: true, element: <AgentsIdPage /> }],
      },
    ],
  },
  {
    path: '/lite-paper',
    element: <Navigate replace to="/lite-paper.pdf" />,
  },
  {
    path: '/lite-paper.pdf',
    element: <PdfHandlerPage />,
  },
];

export const router = createBrowserRouter(routes);
