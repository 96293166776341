import StreamingAvatar, {
  AvatarQuality,
  StartAvatarResponse,
  StreamingEvents,
  TaskMode,
  TaskType,
  VoiceEmotion,
} from '@heygen/streaming-avatar';
import { useAppKit, useAppKitAccount } from '@reown/appkit/react';
import { FC, useEffect, useRef, useState } from 'react';
import IconX from 'src/assets/images/icons/i-x.svg?react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { LoadingStub } from 'src/components/ui/LoadingStub.tsx';
import { UIButton } from 'src/components/ui/UIButton.tsx';
import { useImmutableCallback } from 'src/hooks/useActualRef.ts';
import { usePrevious } from 'src/hooks/usePrevios.ts';
import { useTimeDifference } from 'src/hooks/useTimeDifference.ts';
import { axiosInstance } from 'src/libs/axios.ts';
import InteractiveAvatarTextInput from 'src/pages/stream/components/InteractiveAvatarTextInput.tsx';
import { useLSRemainingTimer } from 'src/pages/stream/hooks/useLSRemainingTimer.tsx';
import { useServerRemainingTimer } from 'src/pages/stream/hooks/useServerRemainingTimer.tsx';
import { getNextUtcMidnightTimestamp } from 'src/utils/time.utils.ts';

export const StreamPage: FC = () => {
  const { isConnected } = useAppKitAccount();
  const { open } = useAppKit();

  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [isChangingMode, setIsChangingMode] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [, setDebug] = useState<string>();
  const [language] = useState<string>('en');
  const [, setData] = useState<StartAvatarResponse>();
  const [text, setText] = useState<string>('');
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);
  const [chatMode, setChatMode] = useState<'voice_mode' | 'text_mode'>('voice_mode');
  const [, setIsUserTalking] = useState(false);
  const [isEnablingMic, setIsEnablingMic] = useState(false);

  const [refreshTime] = useState(getNextUtcMidnightTimestamp());
  const lsRemainingTime = useLSRemainingTimer(!!stream, endSession);
  const serverRemainingTime = useServerRemainingTimer(!!stream, endSession);

  const timeUntilRefresh = useTimeDifference(refreshTime);

  async function requestMicPermission() {
    setIsEnablingMic(true);

    let result = false;
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });

      console.log('Microphone enabled!');

      result = true;
    } catch (e: unknown) {
      if (e instanceof Error && e.name === 'NotAllowedError') {
        console.warn('Microphone access was denied by the user.');
      } else {
        console.error('Error accessing microphone:', e);
      }
      result = false;
    } finally {
      setIsEnablingMic(false);
    }

    return result;
  }

  async function fetchAccessToken() {
    try {
      const response = await axiosInstance.post(
        'https://api.heygen.com/v1/streaming.create_token',
        {},
        {
          headers: {
            'x-api-key': import.meta.env.VITE_HEYGEN_API_KEY,
          },
        },
      );

      const token = response.data.data.token;

      console.log('Access Token:', token); // Log the token to verify

      return token;
    } catch (error) {
      console.error('Error fetching access token:', error);
    }

    return '';
  }

  async function startSession(type: 'voice_mode' | 'text_mode') {
    setIsLoadingSession(true);
    const newToken = await fetchAccessToken();

    avatar.current = new StreamingAvatar({
      token: newToken,
    });

    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log('Avatar started talking', e);
    });

    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log('Avatar stopped talking', e);
    });

    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log('Stream disconnected');
      setStream(undefined);
    });

    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log('>>>>> Stream ready:', event.detail);
      avatar.current
        ?.speak({ text: 'Hello! Who are you?', taskType: TaskType.TALK, taskMode: TaskMode.SYNC })
        .catch((e) => {
          setDebug(e.message);
        });
      setStream(event.detail);
    });

    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      console.log('>>>>> User started talking:', event);
      setIsUserTalking(true);
    });

    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      console.log('>>>>> User stopped talking:', event);
      setIsUserTalking(false);
    });

    if (type === 'voice_mode') await requestMicPermission();

    try {
      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: '336b72634e644335ad40bd56462fc780',
        knowledgeId: 'ffbd8bbc315b494497ea5349c429234c',
        voice: {
          voiceId: '424999e54823470597ed07cd7ef70cca',
          rate: 1,
          emotion: VoiceEmotion.EXCITED,
        },
        language: language,
        disableIdleTimeout: false,
      });

      setData(res);

      if (type === 'voice_mode')
        await avatar.current?.startVoiceChat({
          useSilencePrompt: true,
        });

      setChatMode(type);
    } catch (error) {
      console.error('Error starting avatar session:', error);
    } finally {
      setIsLoadingSession(false);
    }
  }

  async function handleSpeak() {
    setIsLoadingRepeat(true);
    if (!avatar.current) {
      setDebug('Avatar API not initialized');

      return;
    }
    await avatar.current
      .speak({ text: text, taskType: TaskType.TALK, taskMode: TaskMode.SYNC })
      .catch((e) => {
        setDebug(e.message);
      });
    setIsLoadingRepeat(false);
  }

  async function endSession() {
    await avatar.current?.stopAvatar();
  }

  const handleChangeChatMode = useImmutableCallback(async (v: 'voice_mode' | 'text_mode') => {
    if (v === chatMode) {
      return;
    }

    setIsChangingMode(true);

    if (v === 'text_mode') {
      avatar.current?.closeVoiceChat();
    } else {
      await avatar.current?.startVoiceChat({
        useSilencePrompt: true,
      });
    }
    setIsChangingMode(false);
    setChatMode(v);
  });

  const previousText = usePrevious(text);
  useEffect(() => {
    if (!previousText && text) {
      avatar.current?.startListening();
    } else if (previousText && !text) {
      avatar?.current?.stopListening();
    }
  }, [text, previousText]);

  useEffect(() => {
    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug('Playing');
      };
    }
  }, [mediaStream, stream]);

  return (
    <div
      className="flex-grow pb-10 lg:py-5"
      style={{
        background: 'url("/images/bg-page-stream.png") center / cover no-repeat',
      }}
    >
      <div className="mx-auto mt-28 max-w-[500px] lg:mt-40">
        {!isConnected && (
          <ItemWithFancyBorder className="mb-4 rounded-xl">
            <div className="flex items-center justify-between rounded-xl bg-black p-4">
              <span className="text-sm">Want to spend more time with Masha?</span>
              <UIButton onClick={() => open()}>Connect wallet</UIButton>
            </div>
          </ItemWithFancyBorder>
        )}
        <ItemWithFancyBorder className="rounded-xl">
          <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl bg-black pt-[120%]">
            <div className="absolute left-0 top-0 h-full w-full">
              {(isConnected && serverRemainingTime === '0') ||
              (!isConnected && lsRemainingTime === '0') ? (
                <div className="flex h-full w-full flex-col items-center justify-center gap-8 self-center px-6 text-center">
                  <h4 className="text-2xl">Oh no! Times up</h4>
                  <p>
                    Your Free time is finished. Return in{' '}
                    <span className="rounded-xl bg-gray-100 px-2 py-0.5 text-[#3B1E76]">
                      {timeUntilRefresh}
                    </span>{' '}
                    To unlock +10 minutes, hold 5,000 AITHER. Unlimited chatting is available with
                    20,000 AITHER holdings.
                  </p>
                  <a
                    href="https://app.uniswap.org/swap?chain=mainnet&inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <UIButton>Buy $AITHER</UIButton>
                  </a>
                </div>
              ) : stream ? (
                <div className="h-full w-full overflow-hidden rounded-xl">
                  <video
                    ref={mediaStream}
                    autoPlay
                    playsInline
                    className="h-full w-full object-cover"
                  >
                    <track kind="captions" />
                  </video>
                  <div className="absolute bottom-4 w-full">
                    <InteractiveAvatarTextInput
                      input={text}
                      label="Chat"
                      loading={isLoadingRepeat || isChangingMode}
                      placeholder="Type something for the avatar to respond"
                      setInput={setText}
                      onSubmit={handleSpeak}
                      onChangeChatMode={handleChangeChatMode}
                      chatMode={chatMode}
                    />
                  </div>
                </div>
              ) : isEnablingMic ? (
                <div className="flex h-full w-full flex-col">
                  <LoadingStub className="h-full w-full" label="Enabling microphone" />
                </div>
              ) : isLoadingSession ? (
                <div className="flex h-full w-full flex-col">
                  <LoadingStub className="h-full w-full" label="Loading agent" />
                </div>
              ) : (
                <div className="flex h-full w-full flex-col items-center justify-center gap-8 self-center">
                  <UIButton onClick={() => startSession('voice_mode')}>
                    Start voice session
                  </UIButton>
                  <UIButton onClick={() => startSession('text_mode')}>Start chat session</UIButton>
                  <p className="mt-4">You are able to change chat mode later</p>
                </div>
              )}
            </div>
          </div>
        </ItemWithFancyBorder>
        <div className="flex justify-between">
          <div className="flex flex-col gap-2 py-4">
            <div>
              <div className="flex items-center gap-4 text-xs">
                <span
                  className={`ml-1 size-3 rounded-full ${stream ? 'bg-green-400' : 'bg-red-500'}`}
                />
                <span>Live Chat with Masha - Aither's First AI Agent</span>
              </div>
            </div>
            <a href="" className="hover:underline">
              <div className="flex items-center gap-2 text-xs">
                <IconX className="w-6" />
                <span>Follow Masha on Twitter</span>
              </div>
            </a>
          </div>
          <div className="mt-3 flex flex-col">
            <span>Time left: {isConnected ? serverRemainingTime : lsRemainingTime}</span>
          </div>
        </div>
        {stream && <UIButton onClick={endSession}>End session</UIButton>}
      </div>
    </div>
  );
};
