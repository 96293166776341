import { FC } from 'react';
import { useSetModal } from 'src/providers/ModalsProvider.tsx';
import { DisconnectAgentIntegrationModalSettings } from 'src/types/modal';
import { Modal } from '../ui/Modal';
import { UIButton } from '../ui/UIButton';

export const DisconnectAgentIntegrationModal: FC<DisconnectAgentIntegrationModalSettings> = ({
  type,
  onDisconnect,
}) => {
  const setModal = useSetModal();
  return (
    <Modal>
      <div className="modal-title">Disconnect {type}</div>

      <div className="mb-4 flex gap-2">
        <UIButton className="w-full" onClick={() => setModal(null)}>
          No
        </UIButton>
        <UIButton
          className="w-full"
          onClick={() => {
            setModal(null);
            onDisconnect();
          }}
        >
          Yes
        </UIButton>
      </div>
    </Modal>
  );
};
