import { useAppKitAccount } from '@reown/appkit/react';
import { useEffect, useState } from 'react';
import { useTimeDifference } from 'src/hooks/useTimeDifference.ts';
import { getNextUtcMidnightTimestamp } from 'src/utils/time.utils.ts';

const INITIAL_REMAINING_TIME = 2 * 60 * 1000 + 1000;

const saveToLocalStorage = (remainingTimeValue: number) => {
  localStorage.setItem(
    'rt',
    JSON.stringify({
      remainingTime: remainingTimeValue,
    }),
  );
};

export const useLSRemainingTimer = (isStreaming: boolean, endSession: () => void) => {
  const { isConnected } = useAppKitAccount();
  const [remainingTime, setRemainingTime] = useState<number>(INITIAL_REMAINING_TIME);
  const midnightTimestamp = getNextUtcMidnightTimestamp();

  const timeUntil = useTimeDifference(Date.now() + remainingTime, { withColon: true });

  useEffect(() => {
    const now = Date.now();
    const stored = localStorage.getItem('rt');

    if (!stored) {
      // No stored data: set fresh remindTime
      saveToLocalStorage(INITIAL_REMAINING_TIME);
    } else {
      try {
        const data = JSON.parse(stored);
        const { remainingTime: savedRemainingTime } = data || {};

        // If remindTime not stored properly, set fresh
        if (savedRemainingTime === undefined) {
          saveToLocalStorage(INITIAL_REMAINING_TIME);
          return;
        }

        // Check if Midnight
        if (now >= midnightTimestamp) {
          // Reset it
          saveToLocalStorage(INITIAL_REMAINING_TIME);
        } else {
          // Continue from stored remainingTime
          setRemainingTime(savedRemainingTime);
        }
      } catch (e) {
        console.error(e);
        // If parsing fails, just recalc
        saveToLocalStorage(INITIAL_REMAINING_TIME);
      }
    }
  }, []);

  useEffect(() => {
    if (!isStreaming) return;
    if (isConnected) return;
    if (!remainingTime) return;

    const intervalId = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1000) {
          clearInterval(intervalId);
          // Set to 0 once it runs out
          const data = localStorage.getItem('rt');

          if (data) saveToLocalStorage(0);
          endSession();

          return 0;
        }

        const newVal = prev - 1000;

        // Update localStorage to keep the countdown in sync
        const data = localStorage.getItem('rt');

        if (data) saveToLocalStorage(newVal);

        return newVal;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime, isStreaming, isConnected]);

  return timeUntil;
};
