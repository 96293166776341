import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import IconDel from 'src/assets/images/icons/i-del.svg?react';
import { UIButton } from 'src/components/ui/UIButton';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { NewAgent } from 'src/types/agents';
import { KnowledgeBaseDropzone } from '../Dropzone';

export const KnowledgeBaseTab: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  const [linkValue, setLinkValue] = useState('');
  const [importedItems, setImportedItems] = useState<string[]>([]);

  useEffect(() => {
    setFieldValue('knowledge_websites', importedItems);
  }, [importedItems]);

  function handleImport() {
    if (!linkValue) return;
    setImportedItems((prev) => [...prev, linkValue]);
  }

  function handleDeleteImportItem(itemForDelete: string) {
    setImportedItems((prev) => [...prev.filter((item) => item !== itemForDelete)]);
  }

  return (
    <>
      <div className="flex items-end gap-2">
        <div className="flex-grow">
          <UIInput
            onChange={(e) => setLinkValue(e.target.value)}
            placeholder="Enter URL http:///..."
            value={linkValue}
            label="Custom Knowledge"
            description="Link URLs or upload documents to enrich your character agent's knowledge."
          />
        </div>
        <UIButton className="h-[60px]" onClick={handleImport}>
          Import
        </UIButton>
      </div>
      {values.knowledge_websites.length > 0 && (
        <div className="my-4 flex flex-col items-start gap-2">
          {values.knowledge_websites.map((el) => (
            <div className="flex items-center gap-4 whitespace-nowrap rounded-xl border border-[#8648D2] px-4 py-1">
              <span>{el}</span>
              <div className="relative cursor-pointer" onClick={() => handleDeleteImportItem(el)}>
                <span
                  className="absolute"
                  style={{
                    left: '-.5em',
                    top: '-.5em',
                    width: 'calc(100% + 1em)',
                    height: 'calc(100% + 1em)',
                  }}
                />
                <IconDel className="w-2" />
              </div>
            </div>
          ))}
        </div>
      )}
      <KnowledgeBaseDropzone
        onDrop={(file_urls) => setFieldValue('knowledge_uploaded_files', file_urls)}
        onRemove={(url) => {
          const newFiles = values.knowledge_uploaded_files.filter((file) => file.url !== url);
          setFieldValue('knowledge_uploaded_files', newFiles);
        }}
        files={values.knowledge_uploaded_files}
        disabled={values.knowledge_uploaded_files.length > 10}
      />
    </>
  );
};
