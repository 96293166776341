import { FC } from 'react';
import { useNavigate } from 'react-router';
import IconTg from 'src/assets/images/icons/i-tg.svg?react';
import IconX from 'src/assets/images/icons/i-x.svg?react';
import { Agent } from 'src/types/agents.ts';
import { getDisplayAmount, shortenNumber } from 'src/utils/bigNumber.ts';
import { LoadingStub } from '../ui/LoadingStub';
import { UIAlert } from '../ui/UIAlert';
import { AgentsTableFilter } from './AgentsTableFilter';

interface AgentsTableProps {
  agents: Agent[];
  withControls?: boolean;
  withSocials?: boolean;

  isLoading?: boolean;
  isError?: boolean;
}

export const AgentsTable: FC<AgentsTableProps> = ({
  agents,
  withControls = true,
  withSocials = false,
  isLoading = false,
  isError = false,
}) => {
  const navigate = useNavigate();

  if (isLoading) return <LoadingStub label="Fetching data" />;
  if (isError)
    return (
      <div className="mt-4">
        <UIAlert type="error" message="Error while fetching agents" />
      </div>
    );

  return (
    <div
      style={{ minHeight: `${agents.length * 120 + (withControls ? 120 : 0)}px` }}
      className="overflow-x-auto"
    >
      <table
        style={{ borderCollapse: 'separate', borderSpacing: '0px 20px' }}
        className="mt-4 w-auto min-w-full max-w-none whitespace-nowrap text-left"
      >
        {withControls && (
          <thead>
            <tr>
              <th scope="col" className="min-w-[232px] pb-3">
                <AgentsTableFilter />
              </th>
              <th scope="col" className="min-w-48 pb-3">
                <span className="text-white/50">Interests</span>
              </th>
              <th scope="col" className="min-w-24 pb-3">
                <span className="text-white/50">MCAP</span>
              </th>
              <th scope="col" className="min-w-24 pb-3">
                <span className="text-white/50">Ticker</span>
              </th>
              <th scope="col" className="min-w-24 pb-3">
                <span className="text-white/50">Holders</span>
              </th>
              <th scope="col" className="min-w-24 pb-3">
                <span className="text-white/50">24H Vol</span>
              </th>
              {withSocials && (
                <th scope="col" className="min-w-24 pb-3">
                  <span className="text-white/50">Socials</span>
                </th>
              )}
            </tr>
          </thead>
        )}

        <tbody>
          {agents.map((agent) => (
            <tr
              onClick={() => navigate(`/agents/${agent.agent_id}`)}
              key={agent.agent_id}
              className="cursor-pointer"
              style={{
                background: 'linear-gradient(180deg, rgba(43,13,61,1) 0%, rgba(36,13,39,1) 100%)',
              }}
            >
              <td
                className="min-w-[232px] max-w-[232px] rounded-bl-2xl rounded-tl-2xl py-7 pl-7"
                style={{
                  borderLeft: '1px solid #45256C',
                  borderTop: '1px solid #45256C',
                  borderBottom: '1px solid #45256C',
                }}
              >
                <div className="flex items-center gap-4">
                  <div className="h-[76px] w-[76px] overflow-hidden rounded-full">
                    <img
                      src={agent.image_url}
                      className="h-full w-full object-cover"
                      alt={agent.name}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span>Agent</span>
                    <span className="min-w-24 max-w-24 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold">
                      {agent.name}
                    </span>
                  </div>
                </div>
              </td>
              <td
                className="min-w-24 max-w-48 py-7 text-sm"
                style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
              >
                <div className="flex flex-col">
                  <span>Interests</span>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold">
                    {agent.origin?.join(', ')}
                  </span>
                </div>
              </td>
              <td
                className="min-w-24 py-7 text-white"
                style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
              >
                <div className="flex flex-col">
                  <span>MCAP</span>
                  <span className="text-xl font-bold">
                    ${shortenNumber(agent.token_info?.market_cap_usd)}
                  </span>
                </div>
              </td>
              <td
                className="min-w-24 py-7 text-sm"
                style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
              >
                <div className="flex flex-col">
                  <span>Ticker</span>
                  <span className="max-w-32 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold">
                    ${agent.ticker}
                  </span>
                </div>
              </td>
              <td
                className="min-w-24 py-7 text-white"
                style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
              >
                <div className="flex flex-col">
                  <span>Holders</span>
                  <span className="text-xl font-bold">
                    {shortenNumber(
                      getDisplayAmount(agent.token_info?.holder_count || 0, { decimals: 0 }),
                      0,
                    )}
                  </span>
                </div>
              </td>
              <td
                className={`min-w-24 py-7 text-white ${withSocials ? '' : 'rounded-br-2xl rounded-tr-2xl pr-7'}`}
                style={{
                  borderTop: '1px solid #45256C',
                  borderBottom: '1px solid #45256C',
                  ...(!withSocials && { borderRight: '1px solid #45256C' }),
                }}
              >
                <div className="flex flex-col">
                  <span>Volume</span>
                  <span className="text-xl font-bold">
                    ${shortenNumber(getDisplayAmount(agent.token_info.volume_24 || 0))}
                  </span>
                </div>
              </td>
              {withSocials && (
                <td
                  className="min-w-24 rounded-br-2xl rounded-tr-2xl py-7 pr-7 text-white"
                  style={{
                    borderRight: '1px solid #45256C',
                    borderTop: '1px solid #45256C',
                    borderBottom: '1px solid #45256C',
                  }}
                >
                  <div className="flex flex-col">
                    <span>Socials</span>
                    <div className="flex items-center gap-2">
                      {agent.twitter_agent_username && (
                        <a
                          href={`https://x.com/${agent.twitter_agent_username}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconX className="w-5" />
                        </a>
                      )}
                      {agent.telegram_username && (
                        <a
                          href={`https://t.me/${agent.telegram_username}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconTg className="w-5" />
                        </a>
                      )}
                      {!agent.twitter_agent_username && !agent.telegram_username && '-'}
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
