import { Icon } from '@iconify/react';
import { useAppKitNetwork } from '@reown/appkit/react';
import { FC } from 'react';
import { CircularProgress } from 'src/components/ui/CircularProgress';
import { Modal } from 'src/components/ui/Modal';

export const LoaderModal: FC<{ title: string; txHash?: string }> = ({ title, txHash }) => {
  const { caipNetwork } = useAppKitNetwork();

  const chainExplorer = caipNetwork?.blockExplorers?.default.url;

  return (
    <Modal size="lg">
      <div className="mb-8 flex flex-col items-center justify-center">
        <div className="mb-4 mt-8 h-32 w-32">
          <CircularProgress spinnerSize="full" />
        </div>
        <span>Loading...</span>
      </div>
      <div className="mb-7 text-center text-xl">{title}</div>
      {txHash && (
        <div className="flex items-center justify-center text-purple-600">
          <a className="underline" href={`${chainExplorer}/tx/${txHash}`} target={'_blank'}>
            Show transaction status
          </a>
          <Icon className="-mt-0.5 ml-3" icon="fa6-solid:arrow-up-right-from-square" />
        </div>
      )}
    </Modal>
  );
};
