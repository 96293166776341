import { base } from '@reown/appkit/networks';
import {
  useAppKit,
  useAppKitAccount,
  useAppKitNetwork,
  useAppKitProvider,
} from '@reown/appkit/react';
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';
import { assetTokenAbi, bondingAbi, boundingUtilsAbi } from 'src/contracts/abi';
import { ADDRESSES } from 'src/contracts/addresses';
import { axiosInstance } from 'src/libs/axios';
import { useSetModal } from 'src/providers/ModalsProvider';
import { NewAgent } from 'src/types/agents';
import { BN, getAtomicAmount, getDisplayAmount, numberWithSpaces } from 'src/utils/bigNumber';
import { useBalanceOf } from './useBalanceOf';

interface Socials {
  telegram: string | null;
  twitter: string | null;
  website: string | null;
}

export const useAgentCreation = () => {
  const { values, setFieldValue, resetForm } = useFormikContext<NewAgent>();
  const setModal = useSetModal();
  const { walletProvider } = useAppKitProvider<Eip1193Provider>('eip155');
  const { address, isConnected } = useAppKitAccount();
  const { chainId, switchNetwork } = useAppKitNetwork();
  const { open } = useAppKit();
  const [launchFee, setLaunchFee] = useState('0');
  const [initialBuy, setInitialBuy] = useState('0');
  const { balance: agixBalance } = useBalanceOf(ADDRESSES.assetToken);
  const navigate = useNavigate();
  const agentIdRef = useRef(values.agent_id);
  const agentNameRef = useRef(values.name);

  const isInsufficientFunds = BN(agixBalance.raw).lt(
    getAtomicAmount(
      BN(values.amount_to_buy || 0)
        .plus(launchFee)
        .toString(),
    ),
  );
  const isWrongNetwork = chainId !== base.id;

  const launch = async () => {
    if (!address || !walletProvider) throw new Error('No address found');

    const bondingAddress = ADDRESSES.bondingAddress;

    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();

    const bonding = new ethers.Contract(bondingAddress, bondingAbi, signer);
    const assetToken = new ethers.Contract(ADDRESSES.assetToken, assetTokenAbi, signer);

    const launchFee: bigint = await bonding.fee();
    const buyFor: bigint = ethers.parseEther(values.amount_to_buy?.toString() || '0');
    const purchaseAmount = BN(launchFee.toString()).plus(buyFor.toString()).toString();

    const name = values.name;
    const ticker = values.ticker;
    const cores: string[] = [];
    const desc = values.description;
    const img = values.image_url;
    const urls = ['', '', '', ''];
    const backendAgentId = values.agent_id;

    const launchArgs = [name, ticker, cores, desc, img, urls, purchaseAmount, backendAgentId];

    const currentAllowance: bigint = await assetToken.allowance(address, bondingAddress);

    if (BN(currentAllowance.toString()).lt(purchaseAmount)) {
      setModal({ modalKey: 'loader', title: 'Confirm your transaction in the wallet' });
      const approveTx = await assetToken.approve(bondingAddress, purchaseAmount);
      setModal({
        modalKey: 'loader',
        title: 'Approving',
        txHash: approveTx.hash,
      });

      const approveRecp = await approveTx.wait();
      if (approveRecp.status === 1) {
        console.log('Approve successful');
      } else {
        throw new Error('Failed to approve');
      }
    }

    setModal({ modalKey: 'loader', title: 'Confirm your transaction in the wallet' });

    const tx = await bonding.launch(...launchArgs);

    setModal({
      modalKey: 'loader',
      title: 'Launching Agent',
      txHash: tx.hash,
    });

    const launchReceipt = await tx.wait();

    setModal({
      modalKey: 'loader',
      title: 'Setting Up Agent ~ 1min',
    });

    if (launchReceipt.status === 1) {
      console.log('Launch successful');
    } else {
      throw new Error('Failed to launch');
    }

    setTimeout(() => {
      setModal(null);
      toast.success('Agent created successfully');
      navigate(`/agents/${agentIdRef.current}`);
    }, 15000);
  };

  const handleCreate = async () => {
    if (!isConnected) return open();
    if (isWrongNetwork) return switchNetwork(base);
    if (launchFee === '0') return;
    if (!address) return;

    const formToSend = { ...values };

    formToSend.creator_contract_address = address;

    formToSend.socials = {
      telegram: formToSend.socials.telegram || null,
      twitter: formToSend.socials.twitter || null,
      website: formToSend.socials.website || null,
    } as Socials;

    formToSend.heygen = {
      ...formToSend.heygen,
      avatar_id: formToSend.heygen.avatar_id || '',
      voice_id: formToSend.heygen.voice_id || '',
      knowledge_base_id: formToSend.heygen.knowledge_base_id || '',
    };

    try {
      await axiosInstance.post('/api/agents', formToSend);
      await launch();

      resetForm();
      localStorage.removeItem('create_agent');
    } catch (error) {
      setModal(null);
      console.error(error);
      toast.error('Error while creating agents');
    }
  };

  useEffect(() => {
    if (!isWrongNetwork && walletProvider) {
      getLaunchFee();
      agentIdRef.current = values.agent_id;
      agentNameRef.current = values.name;
    }
  }, [walletProvider, isWrongNetwork]);

  useEffect(() => {
    if (!isWrongNetwork && walletProvider) {
      calculatePurchaseAmount();
    }
  }, [values.amount_to_buy, walletProvider, isWrongNetwork]);

  async function calculatePurchaseAmount() {
    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    const bondingUtils = new ethers.Contract(ADDRESSES.boundingUtils, boundingUtilsAbi, signer);

    const paymentAmount = ethers.parseEther(
      (Number(launchFee) + (Number(values.amount_to_buy) || 0)).toString(),
    );

    const result = await bondingUtils.simulateLaunch(paymentAmount);
    setInitialBuy(ethers.formatEther(result[3]));
  }

  async function getLaunchFee() {
    const bondingAddress = ADDRESSES.bondingAddress;

    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    const bonding = new ethers.Contract(bondingAddress, bondingAbi, signer);
    const launchFee = (await bonding.fee()).toString();

    setLaunchFee(numberWithSpaces(getDisplayAmount(launchFee)));
  }

  const handleReviewAgent = () => navigate(`/agents/${agentIdRef.current}`);

  return {
    handleCreate,
    handleReviewAgent,
    launchFee,
    amount: values.amount_to_buy,
    changeAmount: (amount: string) => {
      setFieldValue('amount_to_buy', amount);
    },
    name: agentNameRef.current,
    agentId: agentIdRef.current,
    ticker: values.ticker,
    emission: BN(1e18),
    initialBuy,
    isInsufficientFunds,
    isConnected,
    isWrongNetwork,
  };
};
