import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router';
import { CreateBlock } from 'src/components/homePage/CreateBlock.tsx';
import { DeployBlock } from 'src/components/homePage/DeployBlock.tsx';
import { LastBlock } from 'src/components/homePage/LastBlock.tsx';
import { InViewComponent } from 'src/components/InViewComponent.tsx';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { FancyButtonWithArrow } from 'src/components/ui/FancyButtonWithArrow.tsx';

export const HomePage: FC = () => {
  const test = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageClick = () => {
    if (!imgRef.current) return;

    if (imgRef.current.requestFullscreen) {
      imgRef.current.requestFullscreen();
    }
  };

  useEffect(() => {
    // @ts-expect-error unicorn studio
    window.UnicornStudio.init();

    // @ts-expect-error unicorn studio
    return () => window.UnicornStudio.destroy();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const videoElements = document.querySelectorAll('video');
      videoElements.forEach(function (videoElement) {
        videoElement.controls = false;

        videoElement.addEventListener('play', function () {
          this.controls = false;
        });

        videoElement.addEventListener('pause', function () {
          this.controls = false;
        });
      });
    }, 3000);
  }, []);

  return (
    <div>
      <div className="pb-10 lg:py-20">
        <section className="container mx-auto mb-10 flex w-full flex-col gap-8 lg:flex-row">
          <div
            className="relative overflow-hidden rounded-[30px] bg-[url('/images/header-bg-mob.png')] bg-[length:auto_100%] bg-center bg-no-repeat pt-[140%] lg:w-1/2 lg:bg-[url('/images/bg-main-top.jpg')] lg:bg-cover lg:pt-[48%]"
            style={{
              backgroundImage: '',
            }}
          >
            <div className="animate__animated animate__slow animate__fadeIn absolute left-1/2 top-[55%] flex w-full -translate-x-1/2 -translate-y-[56%] flex-col items-center text-center lg:top-1/2">
              <p className="mb-8 font-space-grotesk text-2xl font-bold lg:text-4xl">
                The First Agent Virtual Machine <span className="text-[#8446FF]">(AVM)</span> for
                Modular AI Agents.
              </p>
              <p className="mb-8 font-space-grotesk text-lg">
                Buy or Launch Revenue-Generating AI Agents on No-code Launchpad.
              </p>
              <a href="/lite-paper" target="_blank" rel="noreferrer">
                <FancyButtonWithArrow>Read Lite Paper</FancyButtonWithArrow>
              </a>
            </div>
          </div>
          <div
            className="relative overflow-hidden rounded-[30px] bg-[url('/images/bg-main-top-2.jpg')] bg-[length:auto_100%] bg-center bg-no-repeat pt-[140%] lg:w-1/2 lg:bg-cover lg:pt-[48%]"
            style={{
              backgroundImage: '',
            }}
          >
            <div className="animate__animated animate__slow animate__fadeIn absolute left-1/2 top-[55%] flex w-full -translate-x-1/2 -translate-y-[56%] flex-col items-center text-center lg:top-1/2">
              <p className="mb-8 font-space-grotesk text-2xl font-bold lg:text-4xl">
                Ready to join
                <br />
                the conversation with Masha?
              </p>
              <p className="mb-8 font-space-grotesk text-lg">
                Masha is our First Agent build with the AVM framework
              </p>
              <Link to="stream">
                <FancyButtonWithArrow>Talk to Masha</FancyButtonWithArrow>
              </Link>
            </div>
          </div>
        </section>
        <CreateBlock />
        <section className="container mx-auto overflow-hidden lg:mt-[-10%]" id="section1">
          <div
            className="relative flex flex-col items-center pb-10 pt-10 lg:block lg:pb-0 lg:pt-[70%]"
            style={{
              backgroundImage: 'url("/images/bg-roles.png")',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <InViewComponent
              inViewClasses="animate__animated animate__fadeIn hidden lg:block"
              rootMargin={'0% 0% 500px 0%'}
            >
              <div>
                <img
                  src="/images/chip-down.png"
                  className="absolute left-1/2 top-[-11.5%] hidden w-[37%] -translate-x-[55%] lg:block"
                />
              </div>
            </InViewComponent>
            <p className="left-10 top-[47%] text-left font-space-grotesk text-3xl font-bold leading-[1.5em] lg:absolute lg:-translate-y-1/2 lg:text-6xl">
              <span className="text-[#8446FF]">Limitless</span> Roles,
              <br />
              Infinite Influence
            </p>
            <InViewComponent
              inViewClasses="animate__animated animate__fadeIn animate__delay"
              rootMargin={'0% 0% 300px 0%'}
            >
              <img
                src="/images/chip-up.png"
                className="bottom-[8.5%] left-[55%] mx-auto w-[50%] lg:absolute lg:w-[37%] lg:-translate-x-1/2"
              />
            </InViewComponent>
          </div>
        </section>
        <section className="container relative mx-auto -mt-[15%] mb-20 lg:mb-48 lg:pb-[5%]">
          <InViewComponent
            inViewClasses="animate__animated animate__fadeIn animate__delay-2s group"
            rootMargin={'0% 0% 300px 0%'}
          >
            <div className="relative mx-auto w-4/5 lg:w-[37%]">
              <img className="block w-full rounded-[100px]" src="/images/tt.jpg" />
              <div className="absolute bottom-0 left-0 w-[108%] -translate-x-[3%]">
                <img className="block" src="/images/tt-bg.png" />
              </div>
              <video
                autoPlay
                playsInline
                muted
                loop
                className="absolute left-0 top-0 h-full w-full rounded-xl object-cover"
              >
                <source src="/videos/tt.mp4" type="video/mp4" />
              </video>
              <div className="absolute right-0 top-4 flex w-full items-center justify-between px-4">
                <span className="text-[#8446FF] lg:text-xl">Trisha - Influencer</span>
                <div className="flex items-center gap-2 text-xs lg:gap-4">
                  <div className="size-2 rounded-full bg-[#00FB00] lg:size-3"></div>
                  <span>Live now</span>
                </div>
              </div>
            </div>
          </InViewComponent>
          <h4 className="bottom-0 left-[23%] mt-10 text-center font-space-grotesk text-3xl leading-[1.5em] lg:absolute lg:mt-0 lg:text-left lg:text-6xl">
            Create
            <br />
            Who you need.
          </h4>
        </section>
        <DeployBlock />
        <section className="relative py-10 font-space-grotesk font-bold lg:py-[25vw]">
          <div className="container mx-auto">
            <h2 className="mb-16 text-center text-3xl lg:text-left lg:text-6xl">
              Tech integrations
            </h2>
            <ul className="ml-12 flex flex-col gap-8 text-xl lg:ml-0 lg:text-3xl">
              <li className="relative">
                <span>Multi-Chain Interoperability</span>
                <span
                  className="absolute right-full top-1/2 h-1 w-full -translate-y-1/2 bg-white"
                  style={{ right: 'calc(100% + 1em)' }}
                ></span>
              </li>
              <li className="relative">
                <span>High-Frequency Trading Infrastructure</span>
                <span
                  className="absolute right-full top-1/2 h-1 w-full -translate-y-1/2 bg-white"
                  style={{ right: 'calc(100% + 1em)' }}
                ></span>
              </li>
              <li className="relative">
                <span>Autonomous API Execution</span>
                <span
                  className="absolute right-full top-1/2 h-1 w-full -translate-y-1/2 bg-white"
                  style={{ right: 'calc(100% + 1em)' }}
                ></span>
              </li>
              <li className="relative">
                <span>Real-Time On-Chain Intelligence</span>
                <span
                  className="absolute right-full top-1/2 h-1 w-full -translate-y-1/2 bg-white"
                  style={{ right: 'calc(100% + 1em)' }}
                ></span>
              </li>
            </ul>
          </div>
          <div className="absolute right-0 top-1/2 hidden w-1/3 -translate-y-1/2 lg:block">
            <img className="" src="/images/tech-icons.png" />
          </div>
        </section>
        <section className="container mx-auto mb-36 mt-20">
          <ItemWithFancyBorder className="mx-auto rounded-xl lg:w-[80%]">
            <div className="relative rounded-xl bg-black lg:pt-[55%]">
              <div
                ref={test}
                data-us-project-src="/IKEqWwv2VG2WySGK461b.json"
                className="left-0 top-0 h-[35vh] w-full lg:absolute lg:h-full"
              ></div>
              <div className="left-0 top-0 flex h-full w-full flex-col items-start px-4 pb-12 pt-20 lg:absolute lg:px-16 lg:pb-0">
                <h4 className="mb-4 font-space-grotesk text-3xl font-bold leading-[1.5em] lg:text-6xl">
                  Co-Own Revenue-
                  <br />
                  Generating AI Assets
                </h4>
                <p className="mb-8 text-2xl font-light">In the new AI-driven economy.</p>
                <div className="mx-auto lg:mx-0">
                  <FancyButtonWithArrow size="xl">Arriving Shortly</FancyButtonWithArrow>
                </div>
              </div>
            </div>
          </ItemWithFancyBorder>
        </section>
        <section className="container mx-auto">
          <h2 className="mb-24 text-center font-space-grotesk text-3xl font-bold leading-[1.5em] lg:text-6xl">
            Blockchain-Powered Co-Ownership
          </h2>
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
            <InViewComponent
              inViewClasses="animate__animated animate__fadeIn group"
              rootMargin={'0% 0% -300px 0%'}
            >
              <ItemWithFancyBorder className="rounded-xl" deg={130}>
                <div
                  className="flex h-full flex-col items-center justify-between rounded-xl px-9 pb-9 pt-12 font-space-grotesk"
                  style={{
                    background:
                      'linear-gradient(23deg, rgba(61,15,119,1) 0%, rgba(61,15,119,1) 16%, rgba(0,0,0,1) 40%)',
                  }}
                >
                  <img src="/images/robot-matroshka.png" className="mx-auto mb-10 block w-1/3" />
                  <p className="text-2xl font-bold">
                    Enable transnational co-ownership of autonomous AI agents.
                  </p>
                </div>
              </ItemWithFancyBorder>
            </InViewComponent>
            <InViewComponent
              inViewClasses="animate__animated animate__fadeIn animate__delay-1s group"
              rootMargin={'0% 0% -300px 0%'}
            >
              <ItemWithFancyBorder className="rounded-xl" deg={130}>
                <div
                  className="flex h-full flex-col items-center justify-between rounded-xl px-9 pb-9 pt-12 font-space-grotesk"
                  style={{
                    background:
                      'linear-gradient(23deg, rgba(61,15,119,1) 0%, rgba(61,15,119,1) 16%, rgba(0,0,0,1) 40%)',
                  }}
                >
                  <img
                    src="/images/multi-dimensional.png"
                    className="mx-auto mb-10 block w-[20%]"
                  />
                  <p className="text-2xl font-bold">
                    Unlock multi-dimensional synergy and inter-systemic convergence.
                  </p>
                </div>
              </ItemWithFancyBorder>
            </InViewComponent>
            <InViewComponent
              inViewClasses="animate__animated animate__fadeIn animate__delay-2s group"
              rootMargin={'0% 0% -300px 0%'}
            >
              <ItemWithFancyBorder className="rounded-xl" deg={130}>
                <div
                  className="flex h-full flex-col items-center justify-between rounded-xl px-9 pb-9 pt-12 font-space-grotesk"
                  style={{
                    background:
                      'linear-gradient(23deg, rgba(61,15,119,1) 0%, rgba(61,15,119,1) 16%, rgba(0,0,0,1) 40%)',
                  }}
                >
                  <img src="/images/algo.png" className="mx-auto mb-10 block w-1/4" />
                  <p className="text-2xl font-bold">
                    Ensure algorithmic transparency in revenue distribution.
                  </p>
                </div>
              </ItemWithFancyBorder>
            </InViewComponent>
          </div>
        </section>
        <section className="container mx-auto pt-10 lg:pt-60" id="section2">
          <img
            className="cursor-pointer"
            ref={imgRef}
            onClick={handleImageClick}
            src="/images/graph@2x.jpg"
          />
          <div className="mt-10 flex flex-col items-center justify-center gap-5 lg:mt-32 lg:flex-row">
            <a href="/lite-paper" target="_blank" rel="noreferrer">
              <FancyButtonWithArrow>Read Lite Paper</FancyButtonWithArrow>
            </a>
            <a href="https://docs.aither.xyz" target="_blank" rel="noreferrer">
              <FancyButtonWithArrow>Read Documentation</FancyButtonWithArrow>
            </a>
          </div>
        </section>
        <LastBlock />
      </div>
    </div>
  );
};
