import { base, mainnet } from '@reown/appkit/networks';
import {
  useAppKit,
  useAppKitAccount,
  useAppKitNetwork,
  useAppKitProvider,
} from '@reown/appkit/react';
import { useMutation } from '@tanstack/react-query';
import { Eip1193Provider, ethers } from 'ethers';
import { useState } from 'react';
import { toast } from 'sonner';
import IconAither from 'src/assets/images/icons/i-aither.svg?react';
import { UIButton } from 'src/components/ui/UIButton.tsx';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { UISelector } from 'src/components/ui/UISelector.tsx';
import { aitherErc20Abi, aitherOftAbi, bridgeAdapterAbi } from 'src/contracts/abi';
import { BRIDGE } from 'src/contracts/addresses';
import { useBalanceOf } from 'src/hooks/useBalanceOf';
import { SendParam } from 'src/types/bridge';
import { BN, numberWithSpaces } from 'src/utils/bigNumber';
import { addressToBytes32 } from 'src/utils/bridge';

// Добавляем типы для сетей
type Network = 'Ethereum' | 'Base';

// Опции для выпадающего списка
const networkOptions = [
  { value: 'Ethereum', name: 'Ethereum' },
  { value: 'Base', name: 'Base' },
];

const handleBridge = async (
  walletProvider: Eip1193Provider,
  direction: 'ETHToBase' | 'BaseToETH',
  amount: string,
  setTxHash: (txHash: string) => void,
) => {
  const provider = new ethers.BrowserProvider(walletProvider);
  const signer = await provider.getSigner();

  const tokenAddress =
    direction === 'ETHToBase' ? BRIDGE.MAINNET.TOKEN_ADDRESS : BRIDGE.BASE.TOKEN_ADDRESS;

  // Подключаемся к контракту токена
  const token = new ethers.Contract(
    tokenAddress,
    direction === 'ETHToBase' ? aitherErc20Abi : aitherOftAbi,
    signer,
  );

  const decimals = await token.decimals();
  const amountInWei = ethers.parseUnits(amount, decimals);

  const address = await signer.getAddress();

  // Создаем параметры для отправки
  const sendParam: SendParam = {
    dstEid: direction === 'ETHToBase' ? BRIDGE.CHAIN_EID.BASE : BRIDGE.CHAIN_EID.MAINNET,
    to: addressToBytes32(address),
    amountLD: amountInWei,
    minAmountLD: amountInWei,
    extraOptions: ethers.getBytes('0x'),
    composeMsg: ethers.getBytes('0x'),
    oftCmd: ethers.getBytes('0x'),
  };

  if (direction === 'ETHToBase') {
    // Проверяем allowance для ETH -> Base
    const currentAllowance = await token.allowance(address, BRIDGE.MAINNET.BRIDGE_ADAPTER_ADDRESS);

    if (BN(currentAllowance).lt(amount)) {
      const approveTx = await token.approve(BRIDGE.MAINNET.BRIDGE_ADAPTER_ADDRESS, amountInWei);
      await approveTx.wait();
    }

    const adapter = new ethers.Contract(
      BRIDGE.MAINNET.BRIDGE_ADAPTER_ADDRESS,
      bridgeAdapterAbi,
      signer,
    );

    const feeQuote = await adapter.quoteSend(sendParam, false);
    const tx = await adapter.send(
      sendParam,
      {
        nativeFee: feeQuote.nativeFee,
        lzTokenFee: 0,
      },
      address,
      { value: feeQuote.nativeFee },
    );
    setTxHash(tx.hash);
    toast.success('Bridge successfully started! You can track progress in LayerZero Scan');
    await tx.wait();
  } else {
    // Base -> ETH логика
    const feeQuote = await token.quoteSend(sendParam, false);
    const tx = await token.send(
      sendParam,
      { nativeFee: feeQuote.nativeFee, lzTokenFee: 0 },
      address,
      { value: feeQuote.nativeFee },
    );
    setTxHash(tx.hash);
    toast.success('Bridge successfully started! You can track progress in LayerZero Scan');
    await tx.wait();
  }
};

export const BridgePage: React.FC = () => {
  const [amount, setAmount] = useState<string>('');
  const { walletProvider } = useAppKitProvider<Eip1193Provider>('eip155');
  const { open } = useAppKit();
  const { isConnected } = useAppKitAccount();
  const [txHash, setTxHash] = useState<string | null>(null);
  // Состояния для выбора сетей
  const { chainId, switchNetwork } = useAppKitNetwork();
  const [fromNetwork, setFromNetwork] = useState<Network>(
    chainId === mainnet.id ? 'Ethereum' : 'Base',
  );
  const [toNetwork, setToNetwork] = useState<Network>(chainId === mainnet.id ? 'Base' : 'Ethereum');

  const {
    balance,
    isPending: isPendingBalance,
    refetch: refetchBalance,
    isRefetching: isRefetchingBalance,
  } = useBalanceOf(
    fromNetwork === 'Ethereum' ? BRIDGE.MAINNET.TOKEN_ADDRESS : BRIDGE.BASE.TOKEN_ADDRESS,
  );

  const isWrongNetwork =
    (fromNetwork === 'Ethereum' && chainId !== mainnet.id) ||
    (fromNetwork === 'Base' && chainId !== base.id);

  const isInsufficientFunds = Number(amount) > Number(balance?.formatted || '0');

  const handleConnectWallet = async () => {
    try {
      await open();
    } catch {
      toast.error('Failed to connect wallet');
    }
  };

  const handleNetworkSwitch = async () => {
    try {
      await switchNetwork(fromNetwork === 'Ethereum' ? mainnet : base);
    } catch {
      toast.error('Failed to switch network');
    }
  };

  const { mutate, isPending: loading } = useMutation({
    mutationFn: async (variables: {
      walletProvider: Eip1193Provider;
      direction: 'ETHToBase' | 'BaseToETH';
      amount: string;
      setTxHash: (txHash: string) => void;
    }) =>
      handleBridge(
        variables.walletProvider,
        variables.direction,
        variables.amount,
        variables.setTxHash,
      ),
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  // Обработчик изменения сети в блоке From
  const handleFromNetworkChange = (selected: Network) => {
    switchNetwork(selected === 'Ethereum' ? mainnet : base);
    setFromNetwork(selected);
    setToNetwork(selected === 'Ethereum' ? 'Base' : 'Ethereum');
    refetchBalance();
  };

  const handleAction = () => {
    if (!isConnected) {
      return handleConnectWallet();
    }
    if (isWrongNetwork) {
      return handleNetworkSwitch();
    }
    if (isInsufficientFunds) {
      return;
    }
    mutate({
      walletProvider,
      direction: fromNetwork === 'Ethereum' ? 'ETHToBase' : 'BaseToETH',
      amount,
      setTxHash,
    });
  };

  return (
    <div className="mx-auto mt-28 w-full max-w-md space-y-6 p-4 lg:mt-[188px]">
      <h2 className="mb-6 text-2xl font-bold">Bridge</h2>
      {/* Блок From */}
      <div
        className="rounded-xl p-4"
        style={{
          background:
            'linear-gradient(160deg, rgba(37,8,52,1) 0%, rgba(25,3,37,1) 45%, rgba(50,6,74,1) 75%, rgba(91,18,131,1) 100%)',
        }}
      >
        <h3 className="mb-2 text-sm font-medium text-gray-400">From</h3>
        <div className="space-y-4">
          <UISelector
            options={networkOptions}
            value={networkOptions.find((opt) => opt.value === fromNetwork)}
            onSelect={(opt) => handleFromNetworkChange(opt.value as Network)}
            selectContainerClasses="w-full"
          />
          <div className="flex w-full items-start gap-2">
            <div className="flex w-full flex-col gap-2">
              <UIInput
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                name="amount-of-bridge-tokens"
              />
              <div className="text-sm text-gray-400">
                {isPendingBalance || isRefetchingBalance
                  ? 'Loading balance...'
                  : `Balance: ${numberWithSpaces(balance.formatted || '0')}`}
              </div>
            </div>
            <div className="flex max-w-[30%] items-center justify-center gap-[6px] bg-[#52208F] px-2 py-4">
              <div>
                <IconAither className="h-6 w-6 rounded-full" />
              </div>
              <span className="font-regular inline-block overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                Aither
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Блок To */}
      <div
        className="rounded-xl p-4"
        style={{
          background:
            'linear-gradient(160deg, rgba(37,8,52,1) 0%, rgba(25,3,37,1) 45%, rgba(50,6,74,1) 75%, rgba(91,18,131,1) 100%)',
        }}
      >
        <h3 className="mb-2 text-sm font-medium text-gray-400">To</h3>
        <UISelector
          options={networkOptions}
          value={networkOptions.find((opt) => opt.value === toNetwork)}
          onSelect={() => {}} // Запрещаем изменение вручную
          selectContainerClasses="w-full"
          disabled
        />
      </div>

      <UIButton
        onClick={handleAction}
        processing={loading}
        disabled={
          (isConnected && !amount) ||
          (isConnected && isInsufficientFunds) ||
          (isConnected && isRefetchingBalance) ||
          (isConnected && isPendingBalance)
        }
        className="w-full"
      >
        {!isConnected
          ? 'Connect wallet'
          : isWrongNetwork
            ? 'Change network'
            : isInsufficientFunds
              ? 'Insufficient funds'
              : 'Bridge'}
      </UIButton>

      {txHash && (
        <div className="text-sm text-gray-600">
          Track progress:{' '}
          <a
            href={`https://layerzeroscan.com/tx/${txHash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            LayerZero Scan
          </a>
        </div>
      )}
    </div>
  );
};
