import { forwardRef, HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { useHookWithRefCallback } from 'src/hooks/useHookWithRefCallback.tsx';

type Props = {
  deg?: number | string;
  hoverEffect?: boolean;
  instantHover?: boolean;
  children?: ReactNode;
  borderColor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const ItemWithFancyBorder = forwardRef<HTMLDivElement, Props>(
  ({ children, deg = 87, hoverEffect, instantHover, borderColor, ...props }, forwardedRef) => {
    const [bgRef, setBgRef] = useHookWithRefCallback();
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
      if (!hoverEffect) return;
      if (!bgRef) return;

      const { width, height } = bgRef.getBoundingClientRect();

      const maxSize = Math.max(width, height) * 1.3;

      bgRef.style.left = `-${(maxSize - width) / 2}px`;
      bgRef.style.top = `-${(maxSize - height) / 2}px`;
      bgRef.style.width = `${maxSize}px`;
      bgRef.style.height = `${maxSize}px`;
    }, [bgRef]);

    function handleMouseEnter() {
      setHovered(true);
    }

    function handleMouseLeave() {
      setHovered(false);
    }

    return (
      <div
        ref={forwardedRef}
        {...props}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={setBgRef}
          className={`absolute z-10 h-full w-full transition-all ${(hovered && hoverEffect) || instantHover ? 'animate-spin' : ''}`}
          style={{
            background:
              borderColor ||
              `linear-gradient(${deg}deg, rgba(59,30,118,1) 32%, rgba(255,255,255,1) 43%, rgba(0,0,0,1) 60%, rgba(55,6,81,1) 96%)`,
          }}
        ></div>
        <div
          className="relative z-20"
          style={{
            padding: '2px',
          }}
        >
          {children}
        </div>
      </div>
    );
  },
);
