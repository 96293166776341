import { Form, Formik } from 'formik';
import { FC } from 'react';
import { AGENT_INIT_FORM } from 'src/constants/agents';
import { BuildAgentForm } from './components/BuildAgentForm';
import { validationSchema } from './validationSchema';

export const AgentsCreate: FC = () => {
  return (
    <Formik
      initialValues={AGENT_INIT_FORM}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <BuildAgentForm />
        </Form>
      )}
    </Formik>
  );
};
