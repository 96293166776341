import axios from 'axios';
import { API_URL } from 'src/constants/api.ts';

export const axiosInstance = axios.create({
  baseURL: API_URL,
});

const MAX_RETRIES = 3;

axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  async (error) => {
    const config = error.config;

    // If no config is found or if the error was caused by something
    // other than receiving a response (like network error), just reject
    if (!config) {
      return Promise.reject(error);
    }

    // Set the retry count if not set before
    config.retryCount = config.retryCount || 0;

    // Retry if status is 500 and we haven't reached the max retry limit
    if (error.response && error.response.status === 500 && config.retryCount < MAX_RETRIES) {
      config.retryCount += 1;
      // Retry the request
      return axiosInstance(config);
    }

    return Promise.reject(error);
  },
);
