import { forwardRef, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

type InViewComponentProps = {
  inViewClasses: string;
  threshold?: number;
  rootMargin?: string;
  children?: ReactNode;
};

export const InViewComponent = forwardRef<HTMLDivElement, InViewComponentProps>(
  ({ children, inViewClasses, threshold = 0.1, rootMargin = '0px' }, forwardedRef) => {
    const { ref: inViewRef, inView } = useInView({
      rootMargin,
      threshold,
      triggerOnce: true,
    });

    return (
      <div
        ref={(node) => {
          // Set the inViewRef
          inViewRef(node);

          // Set the forwarded ref (from parent)
          if (forwardedRef) {
            if (typeof forwardedRef === 'function') {
              forwardedRef(node);
            } else if ('current' in forwardedRef) {
              forwardedRef.current = node;
            }
          }
        }}
        className={inView ? `in-view ${inViewClasses}` : 'opacity-0'}
      >
        {children}
      </div>
    );
  },
);
