export const ADDRESSES = {
  factory: import.meta.env.VITE_FACTORY_ADDRESS,
  assetToken: import.meta.env.VITE_ASSET_TOKEN_ADDRESS,
  bondingAddress: import.meta.env.VITE_BONDING_ADDRESS,
  router: import.meta.env.VITE_ROUTER_ADDRESS,
  boundingUtils: import.meta.env.VITE_BONDING_UTILS_ADDRESS,
};

export const BRIDGE = {
  MAINNET: {
    TOKEN_ADDRESS: import.meta.env.VITE_MAINNET_TOKEN_ADDRESS,
    BRIDGE_ADAPTER_ADDRESS: import.meta.env.VITE_MAINNET_TOKEN_BRIDGE_ADAPTER_ADDRESS,
  },
  BASE: {
    TOKEN_ADDRESS: import.meta.env.VITE_BASE_CHAIN_TOKEN_ADDRESS,
  },
  CHAIN_EID: {
    BASE: 30184,
    MAINNET: 30101,
  },
};
