import { Icon } from '@iconify/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { CustomScroll } from 'react-custom-scroll';
import { LoadingStub } from 'src/components/ui/LoadingStub';
import { UIAlert } from 'src/components/ui/UIAlert';
import { AgiAnswering } from 'src/pages/agents/agents--id/components/chat/AgiAnswering.tsx';
import { AgiChatMessageItem } from 'src/pages/agents/agents--id/components/chat/AgiChatMessageItem.tsx';
import { useAgixBotState } from 'src/pages/agents/agents--id/providers/BotProvider.tsx';
import { History, Message, NewMessage } from 'src/types/conversation';

export const AgixBotContent = () => {
  const [{ userId, answering }, setSwapBotState] = useAgixBotState();

  const lastMessageId = useRef<number>(0);
  const lastMessagePartial = useRef<boolean>(false);
  const waitForNewMessagesTOut = useRef<NodeJS.Timeout>();
  const container = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const {
    data: history,
    isFetching,
    isError: historyError,
    refetch: refetchHistory,
  } = useQuery({
    queryKey: ['chatHistory', userId],
    queryFn: () => getChatHistory(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => clearTimeout(waitForNewMessagesTOut.current);
  }, []);

  const getChatHistory: () => Promise<Message[]> = async () => {
    if (!userId) return Promise.resolve([] as Message[]);

    const resp = await axios.post<History>('https://ait.earth-ai.cloud:3443/conversation_history', {
      user_id: userId,
    });

    const conversation = resp.data.conversation;

    if (conversation.length === 0) {
      lastMessageId.current = 0;
      waitForNewMessage();
      return [] as Message[];
    }

    const parts = conversation[conversation.length - 1].msg_id.split('-');

    lastMessageId.current = parseInt(parts[parts.length - 1]);
    waitForNewMessage();

    return conversation;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!container.current) return;
      container.current.scrollTop = container.current.scrollHeight;
    }, 200);

    return () => clearTimeout(timeout);
  }, [history]);

  const waitForNewMessage = () => {
    try {
      axios
        .get<NewMessage>(
          `https://ait.earth-ai.cloud:3443/wait_for_new_message/${userId}/${lastMessageId.current}`,
        )
        .then((resp) => {
          if (resp.data.status !== 'ok') {
            console.log('Integratly: No new messages');
            waitForNewMessagesTOut.current = setTimeout(() => waitForNewMessage(), 2000);
            return;
          }

          const message = resp.data.message;
          console.log('Integratly: listenForMessages message === >', message);
          const idParts = message.msg_id.split('-');
          const newMessageId = parseInt(idParts[idParts.length - 1]);

          if (message.metadata.partial) {
            queryClient.setQueryData(['chatHistory', userId], (oldData: Message[] | undefined) => {
              if (!oldData) return [message];

              if (lastMessagePartial.current)
                return [...oldData.slice(0, oldData.length - 1), message];

              return [...oldData, message];
            });

            lastMessagePartial.current = true;

            waitForNewMessagesTOut.current = setTimeout(() => waitForNewMessage(), 2000);
            return;
          }

          if (newMessageId > lastMessageId.current) {
            lastMessageId.current = newMessageId;

            if (resp.data.status === 'ok') {
              queryClient.setQueryData(
                ['chatHistory', userId],
                (oldData: Message[] | undefined) => {
                  if (!oldData) return [message];

                  if (lastMessagePartial.current) {
                    lastMessagePartial.current = false;
                    return [...oldData.slice(0, oldData.length - 1), message];
                  }

                  return [...oldData, message];
                },
              );

              if (message.user_id === 'ai')
                setSwapBotState((prevState) => ({ ...prevState, answering: false }));

              waitForNewMessagesTOut.current = setTimeout(() => {
                waitForNewMessage();
              }, 200);
            }
          }
        })
        .catch((e) => {
          console.log('Error restart waitForNewMessage in 2 sec');
          console.error(e);
          setTimeout(() => waitForNewMessage(), 2000);
        });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'a') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="h-full w-full overflow-hidden pt-2 text-sm">
      <CustomScroll className="flex h-full flex-col" keepAtBottom heightRelativeToParent="100%">
        {isFetching ? (
          <LoadingStub className="h-full" label="Loading conversation history..." />
        ) : historyError ? (
          <div className="flex h-full w-full flex-col items-center justify-center gap-2">
            <UIAlert type="error" message="Error while loading history" />
            <button onClick={() => refetchHistory()}>
              <Icon icon="material-symbols:refresh" className="-mr-2 text-lg" />
              <span>Retry</span>
            </button>
          </div>
        ) : (
          history!.map((message, i: number) => (
            <AgiChatMessageItem key={message.msg_id + i} message={message} />
          ))
        )}
        {answering && <AgiAnswering />}
      </CustomScroll>
    </div>
  );
};
