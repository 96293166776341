import {
  AiModel,
  Character,
  CONTENT_DIRECTION,
  CRISIS_COMMUNICATION,
  EthicalRule,
  Genre,
  Goal,
  Personality,
  PRO_ACTIVITY_LVL,
  SENTIMENT,
  TELEGRAM_GROUP_ENGAGEMENT_STRATEGY,
  Tool,
  TwitterCommentStrategy,
  TwitterEmotion,
} from 'src/types/agents';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  // Basic Tab Fields
  agent_id: Yup.string().required('Agent ID is required'),
  creator_contract_address: Yup.string().optional(),
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  image_url: Yup.string().url('Invalid URL').required('Image URL is required'),
  ticker: Yup.string().required('Ticker is required'),
  character: Yup.string().oneOf(Object.values(Character)).required('Character is required'),
  goal: Yup.string().oneOf(Object.values(Goal)).required('Goal is required'),
  origin: Yup.array().of(Yup.string()).required('Origin is required'),
  genre: Yup.string().oneOf(Object.values(Genre)).required('Genre is required'),
  hobbies: Yup.array().of(Yup.string()).min(1, 'At least one hobby is required'),

  // Personality Tab Fields
  personality_type: Yup.string()
    .oneOf(Object.values(Personality))
    .required('Personality type is required'),
  greeting: Yup.string().when('personality_type', {
    is: Personality.CUSTOM,
    then: (schema) => schema.required('Greeting is required for custom personality'),
    otherwise: (schema) => schema.optional(),
  }),
  additional_personality_info: Yup.string().when('personality_type', {
    is: Personality.CUSTOM,
    then: (schema) =>
      schema.required('Additional personality info is required for custom personality'),
    otherwise: (schema) => schema.optional(),
  }),
  personality_info: Yup.string().optional(),
  pro_activity_level: Yup.string()
    .oneOf(Object.values(PRO_ACTIVITY_LVL))
    .required('Pro-activity level is required'),
  sentiment: Yup.string().oneOf(Object.values(SENTIMENT)).required('Sentiment is required'),
  adaption_mode: Yup.boolean().required(),

  // Integration Tab Fields
  ai_model: Yup.string().oneOf(Object.values(AiModel)).required('AI model is required'),
  tools: Yup.array()
    .of(Yup.string().oneOf(Object.values(Tool)))
    .min(1, 'At least one tool is required'),
  ethical_rules: Yup.string()
    .oneOf(Object.values(EthicalRule))
    .required('Ethical rules are required'),

  // Twitter Integration
  twitter_username: Yup.string().optional(),
  twitter_comment_strategy: Yup.string().oneOf(Object.values(TwitterCommentStrategy)).optional(),
  twitter_emotion: Yup.string().oneOf(Object.values(TwitterEmotion)).optional(),
  twitter_content_examples: Yup.array().of(Yup.string()).optional(),
  twitter_content_specific_preferences: Yup.array().of(Yup.string()).optional(),
  twitter_content_directions: Yup.array()
    .of(Yup.string().oneOf(Object.values(CONTENT_DIRECTION)))
    .optional(),
  twitter_add_hashtags: Yup.boolean().optional(),
  twitter_agent_set_likes: Yup.boolean().optional(),
  twitter_agent_post_videos: Yup.boolean().optional(),
  twitter_agent_regular_post: Yup.boolean().optional(),
  twitter_agent_post_quoted_tweets: Yup.boolean().optional(),
  twitter_agent_answer_comments: Yup.boolean().optional(),
  twitter_agent_comment_tweets: Yup.boolean().optional(),
  twitter_sentiment_control: Yup.boolean().optional(),
  twitter_crisis_communication: Yup.string().oneOf(Object.values(CRISIS_COMMUNICATION)).optional(),

  // Telegram Integration
  telegram_token: Yup.string().optional(),
  telegram_automoderation: Yup.boolean().optional(),
  telegram_group_engagement_strategy: Yup.string()
    .oneOf(Object.values(TELEGRAM_GROUP_ENGAGEMENT_STRATEGY))
    .optional(),
  telegram_welcome_message: Yup.string().optional(),
  telegram_crisis_communication: Yup.string().oneOf(Object.values(CRISIS_COMMUNICATION)).optional(),

  // Knowledge Base Tab Fields
  knowledge_files: Yup.array().of(Yup.string()).optional(),
  knowledge_uploaded_files: Yup.array()
    .of(
      Yup.object({
        url: Yup.string().url('Invalid URL').required(),
        name: Yup.string().required(),
      }),
    )
    .optional(),
  knowledge_websites: Yup.array().of(Yup.string().url('Invalid URL')).optional(),

  // Additional Fields
  amount_to_buy: Yup.string().optional(),
  lorebook: Yup.object({
    additionalProp1: Yup.string().optional(),
    additionalProp2: Yup.string().optional(),
    additionalProp3: Yup.string().optional(),
  }).optional(),
  socials: Yup.object({
    website: Yup.string().nullable(),
    telegram: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
  }).optional(),
  heygen: Yup.object({
    avatar_id: Yup.string().optional(),
    voice_id: Yup.string().optional(),
    knowledge_base_id: Yup.string().optional(),
    voice_language: Yup.string().optional(),
    voice_name: Yup.string().optional(),
  }).optional(),
});
