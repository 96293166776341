import { FC } from 'react';

export const ProgressBar: FC<{ progress: string }> = ({ progress }) => {
  return (
    <div className="h-5 w-full overflow-hidden rounded-xl bg-[#242424] p-1">
      <div
        className="h-full rounded-xl transition-all duration-300"
        style={{
          width: `${progress}%`,
          background: 'linear-gradient(90deg, rgba(82,32,143,1) 0%, rgba(55,6,81,1) 100%)',
        }}
      ></div>
    </div>
  );
};
