import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AVMModal } from 'src/components/modals/AVMModal.tsx';
import { ConfirmModal } from 'src/components/modals/ConfirmModal';
import { HowItWorksModal } from 'src/components/modals/HowItWorksModal.tsx';
import { LoaderModal } from 'src/components/modals/LoaderModal';
import { SuccessModal } from 'src/components/modals/SuccessModal';
import { useModal, useSetModal } from 'src/providers/ModalsProvider.tsx';
import { ModalSettings } from 'src/types/modal.ts';
import { DisconnectAgentIntegrationModal } from './DisconnectAgentIntegrationModal';

const renderModal = (modal: ModalSettings) => {
  switch (modal.modalKey) {
    case 'loader':
      return <LoaderModal {...modal} />;
    case 'confirm':
      return <ConfirmModal {...modal} />;
    case 'success':
      return <SuccessModal {...modal} />;
    case 'avm':
      return <AVMModal {...modal} />;
    case 'how-it-works':
      return <HowItWorksModal {...modal} />;
    case 'disconnect-agent-integration-modal':
      return <DisconnectAgentIntegrationModal {...modal} />;
    default:
      return null;
  }
};

export const Modals = () => {
  const modal = useModal();
  const setModal = useSetModal();
  const location = useLocation();

  useEffect(() => {
    setModal(null);
  }, [location]);

  return modal ? renderModal(modal) : null;
};
