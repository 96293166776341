import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Outlet } from 'react-router';
import { SORT_OPTIONS } from 'src/constants/agents.ts';
import { axiosInstance } from 'src/libs/axios.ts';
import { Agent } from 'src/types/agents.ts';
import { FCC } from 'src/types/FCC.ts';

type Pagination = { limit: string; skip: string };
type Sort = (typeof SORT_OPTIONS)[number];

type AgentsProviderState = {
  agentsQueryState: UseQueryResult<{ items_count: string; result: Agent[] }>;
  paginationState: [Pagination, Dispatch<SetStateAction<Pagination>>];
  sortState: [Sort, Dispatch<SetStateAction<Sort>>];
};

const initPagination: Pagination = {
  limit: '10',
  skip: '0',
};

const agentsProviderState: AgentsProviderState = {
  agentsQueryState: {} as UseQueryResult<{ items_count: string; result: Agent[] }>,
  paginationState: [initPagination, () => {}],
  sortState: [SORT_OPTIONS[0], () => {}],
};

const AgentsProviderCtx = createContext(agentsProviderState);

export const AgentsProvider: FCC = ({ children }) => {
  const sortState = useState(SORT_OPTIONS[0]);
  const paginationState = useState(initPagination);

  const agentsQueryState = useQuery({
    queryKey: ['agents', paginationState[0], sortState[0]],
    queryFn: async () => {
      const { sort_order, sort_by } = sortState[0];
      const params: Record<string, string> = { sort_order, sort_by, ...paginationState[0] };
      const res = await axiosInstance.get<{ items_count: string; result: Agent[] }>('/api/agents', {
        params,
      });
      return res.data;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <AgentsProviderCtx.Provider
      value={{
        agentsQueryState,
        sortState,
        paginationState,
      }}
    >
      {children || <Outlet />}
    </AgentsProviderCtx.Provider>
  );
};

export const useAgents = () => useContext(AgentsProviderCtx);
