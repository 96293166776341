import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { axiosInstance } from 'src/libs/axios';
import { formatDropzoneFileSizeError } from 'src/utils/input.ts';

interface AgentImageDropzoneProps {
  onDrop: (image_url?: string) => void;
  accept?: Accept;
  agentId: string;
  imageUrl?: string;
}

export const AgentImageDropzone: FC<AgentImageDropzoneProps> = ({ onDrop, accept, imageUrl }) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (imageUrl) {
      setPreview(imageUrl);
    }
  }, [imageUrl]);

  const { mutate, isError, isPending } = useMutation({
    mutationFn: async (file: File): Promise<string> => {
      const formData = new FormData();
      const sanitizedFileName = file.name
        .replace(/\s+/g, '')
        // eslint-disable-next-line no-control-regex
        .replace(/[^\x00-\x7F]/g, '');
      const renamedFile = new File([file], sanitizedFileName, { type: file.type });
      formData.append('image_file', renamedFile);

      const { data } = await axiosInstance.post<string>('/api/storage/upload_image', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    },
    onSuccess: (data) => {
      onDrop(data);
      setPreview(data);
    },
    onError: (error) => {
      toast.error('Error while uploading image: ' + error.message);
    },
  });

  const handleDrop = (acceptedFiles: File[]) => {
    const singleFile = acceptedFiles[0];
    if (!singleFile) return;
    mutate(singleFile);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections } =
    useDropzone({
      onDrop: handleDrop,
      accept,
      maxSize: 10 * 1024 * 1024, // 10 MB
    });

  return (
    <div
      {...getRootProps()}
      className={`flex cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dashed px-4 py-4 text-center ${
        isDragAccept ? 'border-white' : isDragReject ? 'border-red-500' : 'border-[#232323]'
      }`}
    >
      <input {...getInputProps()} />
      {!preview && !isPending && !isError && (
        <>
          {isDragActive ? (
            isDragAccept ? (
              <span>Drop the image here ...</span>
            ) : (
              <span className="text-red-500">Image will be rejected</span>
            )
          ) : (
            <div className="flex flex-col items-center justify-center gap-3">
              <p>
                Drag & Drop your image or <span className="underline">Browse</span>
              </p>
            </div>
          )}
        </>
      )}
      {fileRejections && fileRejections.length > 0 && (
        <span className="mt-8 text-red-500">
          {formatDropzoneFileSizeError(fileRejections?.[0].errors?.[0].message)}
        </span>
      )}
      {isPending && <span>Uploading...</span>}
      {isError && <span>Error uploading image</span>}
      {preview && (
        <div className="flex items-center justify-between gap-4">
          <span>Image uploaded</span>
        </div>
      )}
    </div>
  );
};
