import { useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router';
import IBurger from 'src/assets/images/burger.svg?react';
import HeaderLogo from 'src/assets/images/header-logo.svg?react';
import IconEagle from 'src/assets/images/icons/i-eagle.svg?react';
import IconTg from 'src/assets/images/icons/i-tg.svg?react';
import IconW from 'src/assets/images/icons/i-warpcast.svg?react';
import IconX from 'src/assets/images/icons/i-x.svg?react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { DrawerSidebar } from 'src/components/layout/Drawer';
import { Footer } from 'src/components/layout/Footer.tsx';

export const DefaultLayout = () => {
  const location = useLocation();

  return (
    <div className="flex min-h-screen flex-col">
      <div className={`flex-grow ${location.pathname.includes('/stream') ? 'flex flex-col' : ''}`}>
        <Header />
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="container fixed left-1/2 top-4 z-30 mx-auto -translate-x-1/2 lg:top-12 lg:px-4">
        <div className="w-full">
          <ItemWithFancyBorder className="mx-auto w-full rounded-[80px]">
            <div className="relative rounded-[80px] bg-black px-8 py-2 lg:py-5">
              <div className="flex items-center justify-between lg:hidden">
                <NavLink to="/">
                  <div className="flex items-center gap-5">
                    <HeaderLogo className="h-12" />
                    <span className="text-2xl font-bold">aither</span>
                  </div>
                </NavLink>
                <button onClick={() => setShowMenu(true)}>
                  <IBurger />
                </button>
              </div>
              <div className="hidden items-center justify-between gap-4 lg:flex">
                <NavLink to="/">
                  <div className="flex items-center gap-5">
                    <img src="/images/header-logo.svg" alt="logo" className="h-12" />
                    <span className="text-2xl font-bold lg:hidden xl:block">aither</span>
                  </div>
                </NavLink>
                <div className="flex items-center gap-6 2xl:gap-10">
                  <a className="text-lg text-[#848484]" href="#section2">
                    Protocol Overview
                  </a>
                  <a
                    className="text-lg text-[#848484]"
                    href="/lite-paper"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AVM Lite Paper
                  </a>
                  <a
                    href="https://docs.aither.xyz"
                    target="_blank"
                    rel="noreferrer"
                    className="text-lg text-[#848484]"
                  >
                    Documentation
                  </a>
                  <div className="flex items-center gap-5">
                    <a
                      href="https://dexscreener.com/ethereum/0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconEagle />
                    </a>
                    <a href="https://warpcast.com/aither" target="_blank" rel="noreferrer">
                      <IconW />
                    </a>
                    <a href="https://x.com/Aither_protocol" target="_blank" rel="noreferrer">
                      <IconX className="w-5" />
                    </a>
                    <a href="https://t.me/aither_protocol" target="_blank" rel="noreferrer">
                      <IconTg />
                    </a>
                    <a
                      href="https://app.uniswap.org/swap?chain=mainnet&inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x6f365eb3686eE95BdefbAe71f1728D62C0af7Ab1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="ml-4 rounded-[40px] bg-[#3D0F77] px-5 py-2 font-medium">
                        Buy $AITHER
                      </button>
                    </a>
                    <NavLink to="/agents">
                      <button className="rounded-[40px] bg-[#3D0F77] px-5 py-2 font-medium">
                        Launch app
                      </button>
                    </NavLink>
                    <NavLink to="/bridge">
                      <button className="rounded-[40px] bg-[#3D0F77] px-5 py-2 font-medium">
                        Bridge Tokens
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </ItemWithFancyBorder>
        </div>
      </div>
      <DrawerSidebar isOpen={showMenu} close={() => setShowMenu(false)} type="default" />
    </>
  );
};
