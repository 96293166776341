import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { useAgentCreation } from 'src/hooks/useAgentCreation';
import { useDebounce } from 'src/hooks/useDebounce';
import { NewAgent } from 'src/types/agents';
import { BN } from 'src/utils/bigNumber.ts';
import { NavigationButtons } from '../NavigationButtons';

type Props = {
  currentTab: string;
  disabledTabs: string[];
  setCurrentTab: (tab: string) => void;
};

const EMISSION_AMOUNT = BN(1e18);

export const LaunchTab: FC<Props> = ({ currentTab, disabledTabs, setCurrentTab }) => {
  const { values } = useFormikContext<NewAgent>();
  const {
    handleCreate,
    changeAmount,
    isConnected,
    isWrongNetwork,
    isInsufficientFunds,
    launchFee,
    initialBuy,
  } = useAgentCreation();
  const calculatedPercentsOfEmission = BN(initialBuy || 0)
    .div(EMISSION_AMOUNT)
    .times(100)
    .toFixed(2);

  const [amount, setAmount] = useState('');
  const debouncedAmount = useDebounce(amount, 500);

  useEffect(() => {
    changeAmount(debouncedAmount);
  }, [debouncedAmount]);

  return (
    <>
      <UIInput
        label={`For how many AITHER you want to make your initial buy`}
        description="It’s optional, but buying a small amount of coins can help protect your token from snipers."
        placeholder="0"
        value={amount}
        onChange={(e) => {
          const value = e.target.value;
          if (isNaN(Number(value)) || BN(value).gt(EMISSION_AMOUNT)) {
            return;
          }
          setAmount(value);
        }}
      />
      <ItemWithFancyBorder className="rounded-xl">
        <div className="rounded-xl bg-black p-8">
          <div className="mx-auto flex max-w-[500px] flex-col gap-4">
            <div className="flex items-center justify-between">
              <span>Agent Creation Fee</span>
              <span>{launchFee} AITHER</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Your Initial Buy</span>
              <div>
                <span>{initialBuy}</span> | <span>{calculatedPercentsOfEmission}%</span>
              </div>
            </div>
            <div className="h-0.5 w-full bg-white opacity-40"></div>
            <div className="flex items-center justify-between">
              <span>Total</span>
              <span>
                {BN(values.amount_to_buy || 0)
                  .plus(launchFee)
                  .toString()}{' '}
                AITHER
              </span>
            </div>
          </div>
        </div>
      </ItemWithFancyBorder>
      <NavigationButtons
        currentTab={currentTab}
        disabledTabs={disabledTabs}
        setCurrentTab={setCurrentTab}
        onCreateAgent={handleCreate}
        isConnected={isConnected}
        isWrongNetwork={isWrongNetwork}
        isInsufficientFunds={isInsufficientFunds}
        isPendingLaunchFee={launchFee === '0'}
      />
    </>
  );
};
