import { FC, InputHTMLAttributes } from 'react';

type Props = {
  item: { icon: string; value: string } | string;
  checked?: boolean;
  showCheckbox: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'checked' | 'value'>;

export const UICheckbox: FC<Props> = ({ item, checked, showCheckbox, ...props }) => {
  return (
    <label
      className={`inline-block cursor-pointer rounded-xl border-2 bg-black px-1 py-2 text-center transition-all duration-200 ease-in-out ${checked ? 'border-[#8648D2]' : 'border-[#232323]'} hover:bg-[#232323]`}
    >
      <div className="flex items-center justify-center gap-4">
        {showCheckbox && (
          <div className="relative size-4 border-2 border-[#232323]">
            {checked && (
              <span
                className="lef-1/2 absolute top-1/2 h-1 w-3 -translate-x-1/2 -translate-y-1/2 -rotate-45 border-b-2 border-l-2 border-[#8648D2]"
                style={{ top: 'calc(50% - 1px)' }}
              ></span>
            )}
          </div>
        )}
        <span>{typeof item === 'string' ? item : `${item.icon} ${item.value}`}</span>
        <input
          hidden
          {...props}
          type="checkbox"
          checked={checked}
          value={typeof item === 'string' ? item : item.value}
        />
      </div>
    </label>
  );
};
