import { Icon } from '@iconify/react';

export const AgiAnswering = () => {
  return (
    <div className="message-container-agi mb-2 mr-auto flex w-11/12 items-start justify-start text-left">
      <div className="ml-4 mr-auto rounded-xl border border-white border-opacity-60 bg-white bg-opacity-30 p-4 backdrop-blur-3xl">
        <Icon className="animate-spin" icon="fa6-solid:spinner" />
      </div>
    </div>
  );
};
