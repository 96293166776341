import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { AGENT_INIT_FORM, TABS } from 'src/constants/agents.ts';
import { NewAgent, TabType } from 'src/types/agents.ts';
import { convertUUIDToNumber } from 'src/utils/bigNumber.ts';
import { v4 as uuidv4 } from 'uuid';
import { TAB_VALIDATION_CONFIG, getDisabledTabs } from '../validation.ts';
import { AgentPreview } from './AgentPreview.tsx';
import { NavigationButtons } from './NavigationButtons.tsx';
import {
  BasicTab,
  ContentTab,
  IntegrationTab,
  KnowledgeBaseTab,
  LaunchTab,
  PersonalityTab,
} from './Tabs';
import { TabsNavigation } from './TabsNavigation.tsx';

export const BuildAgentForm: FC = () => {
  const { values, setFieldValue, setFormikState } = useFormikContext<NewAgent>();
  const [currentTab, setCurrentTab] = useState<string>(TABS[0].value);
  const [urlSearchParams] = useSearchParams();

  function handleSelectTab(tab: string) {
    const currentTabConfig =
      TAB_VALIDATION_CONFIG[currentTab as keyof typeof TAB_VALIDATION_CONFIG];

    if (!currentTabConfig) {
      setCurrentTab(tab);
      return;
    }

    // Allow navigation to previous tabs or if current tab is not disabled
    if (currentTabConfig.canNavigateTo(tab) || !currentTabConfig.isDisabled(values)) {
      setCurrentTab(tab);
    }
  }

  const disabledTabs = getDisabledTabs(values);

  useEffect(() => {
    const tab = urlSearchParams.get('tab');
    const agentForm = localStorage.getItem('create_agent');

    if (tab) {
      setCurrentTab(tab as (typeof TABS)[number]['value']);
    }

    if (agentForm) {
      const savedState = JSON.parse(agentForm);
      if (savedState) {
        // Migrate saved state to include any new fields from DEFAULT_FORM_VALUES
        const migratedState = { ...AGENT_INIT_FORM, ...savedState };
        if (typeof migratedState.origin === 'string') {
          migratedState.origin = [migratedState.origin];
        }
        setFormikState({
          errors: {},
          isSubmitting: false,
          isValidating: false,
          status: undefined,
          submitCount: 0,
          touched: {},
          values: migratedState,
        });
        localStorage.setItem('create_ai_agent', JSON.stringify(migratedState));
      } else {
        const agent_id = convertUUIDToNumber(uuidv4());
        setFieldValue('agent_id', agent_id);
      }
    } else {
      const agent_id = convertUUIDToNumber(uuidv4());
      setFieldValue('agent_id', agent_id);
    }
  }, []);

  useEffect(() => {
    if (!values.agent_id) return;

    localStorage.setItem('create_agent', JSON.stringify(values));
  }, [values]);

  function handleTabClick(tab: TabType) {
    handleSelectTab(tab.value);
  }

  return (
    <div className="container mx-auto h-full flex-grow">
      <div className="grid h-full grid-cols-1 lg:grid-cols-3">
        {/* Left Column */}
        <div className="col-span-2 px-4">
          {/* Tabs */}
          <TabsNavigation
            tabs={TABS}
            currentTab={currentTab}
            onTabClick={handleTabClick}
            disabledTabs={disabledTabs}
          />

          {/* Content */}
          <div className="flex items-start">
            <div className="flex w-full flex-col items-stretch gap-4 sm:gap-6">
              {currentTab === 'basic' ? (
                <BasicTab />
              ) : currentTab === 'personality' ? (
                <PersonalityTab />
              ) : currentTab === 'integrations' ? (
                <IntegrationTab />
              ) : currentTab === 'content' ? (
                <ContentTab />
              ) : currentTab === 'knowledge-base' ? (
                <KnowledgeBaseTab />
              ) : (
                <LaunchTab
                  currentTab={currentTab}
                  disabledTabs={disabledTabs}
                  setCurrentTab={setCurrentTab}
                />
              )}
            </div>
          </div>

          {/* Navigation for non launch tab */}
          {currentTab !== 'launch' && (
            <NavigationButtons
              currentTab={currentTab}
              disabledTabs={disabledTabs}
              setCurrentTab={setCurrentTab}
            />
          )}
        </div>

        {/* Right Column */}
        <AgentPreview />
      </div>
    </div>
  );
};
