import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimeDifference = (
  end: number | string | null | undefined,
  {
    short = false,
    withColon = false,
    type = 'until',
  }: {
    short?: boolean;
    withColon?: boolean;
    type?: 'until' | 'after';
  } = {},
) => {
  const [pastTime, setPastTime] = useState('0');
  const interval = useRef<ReturnType<typeof setInterval>>();

  const calculatePastTime = useCallback(() => {
    const endTime = moment(Number(end));
    const now = moment().utc();

    // Calculate the duration between now and the end time
    const duration = moment.duration(type === 'until' ? endTime.diff(now) : now.diff(endTime));

    // Check if the duration is negative (end time has passed)
    if (duration.asSeconds() < 0) {
      setPastTime('0');
      clearInterval(interval.current); // Stop the interval as well
      return;
    }

    // Extract each component from the duration
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Set the with the calculated duration components
    // Ensure to check each time component and append it to the string only if it's significant
    setPastTime(
      `${years ? `${years}${short ? 'Y' : ' year'}${years > 1 && !short ? 's' : ''} ` : ''}` +
        `${months ? `${months}${short ? 'M' : ' month'}${months > 1 && !short ? 's' : ''} ` : ''}` +
        `${days ? `${days}${short ? 'd' : ' day'}${days > 1 && !short ? 's' : ''} ` : ''}` +
        `${hours ? `${hours < 10 ? '0' : ''}${hours}${withColon ? ':' : short ? 'h' : ' hour'}${!withColon && hours > 1 && !short ? 's' : ''}${withColon ? '' : ' '}` : ''}` +
        `${minutes || (!months && !days && !hours) ? `${minutes < 10 ? '0' : ''}${minutes}${withColon ? '' : short ? 'm' : ' min'}${!hours ? (withColon ? ':' : ' ') : ''}` : ''}` +
        `${!months && !days && !hours ? `${seconds < 10 ? '0' : ''}${seconds}${!withColon ? (short ? 's' : ' sec') : ''}` : ''}`,
    );
  }, [end, short]);

  useEffect(() => {
    if (end === 0 || end === '0' || !end) {
      setPastTime('0');
      return;
    }

    calculatePastTime();

    interval.current = setInterval(() => {
      calculatePastTime();
    }, 1000);

    return () => clearInterval(interval.current);
  }, [calculatePastTime, end]);

  return pastTime;
};
