import { FC } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { formatDropzoneFileSizeError } from 'src/utils/input.ts';

type Props = {
  onDrop: (files: File[]) => void;
  multiple?: boolean;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  disabled?: boolean;
};

export const UIDropZone: FC<Props> = ({
  onDrop,
  multiple = false,
  accept,
  maxFiles,
  maxSize,
  disabled = false,
}) => {
  const handleDrop = (acceptedFiles: File[]) => {
    if (multiple) {
      onDrop(acceptedFiles);
    } else {
      const singleFile = acceptedFiles[0];
      if (!singleFile) return;
      onDrop([acceptedFiles[0]]);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      onDrop: handleDrop,
      multiple,
      accept,
      maxFiles,
      maxSize,
      disabled,
    });

  return (
    <div
      {...getRootProps()}
      className={`flex cursor-pointer flex-col rounded-xl border-2 border-dashed px-4 py-4 text-center ${
        disabled ? 'cursor-not-allowed opacity-60' : ''
      } ${isDragAccept ? 'border-white' : isDragReject ? 'border-red-500' : 'border-[#232323]'}`}
    >
      <input {...getInputProps()} />
      <p className="text-white">
        {isDragActive ? (
          isDragAccept ? (
            <span>Drop the files here ...</span>
          ) : (
            <span className="text-red-500">Some files will be rejected</span>
          )
        ) : (
          <span>
            Drag & Drop your files or <span className="underline">Browse</span>
          </span>
        )}
      </p>

      {fileRejections && fileRejections.length > 0 && (
        <div className="mt-6 flex flex-col gap-3">
          {fileRejections.map((item) => (
            <span className="text-red-500">
              {item.file.name} - {formatDropzoneFileSizeError(item.errors?.[0].message)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
