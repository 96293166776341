import { useFormikContext } from 'formik';
import { FC } from 'react';
// import IconXSM from 'src/assets/images/icons/i-x.svg?react';
import { UISelector } from 'src/components/ui/UISelector';
import { UISwitch } from 'src/components/ui/UISwitch';
import { CRISIS_COMMUNICATION, NewAgent, TwitterCommentStrategy } from 'src/types/agents';

export const AdvancedSettings: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  return (
    <>
      {/* <div className="flex items-center gap-2 text-xl">
        <IconXSM className="w-6" /> (Twitter) Advanced Customization
      </div> */}

      <UISelector
        label="Comments Strategy"
        description="Select the tone for comments."
        disabled={!values.twitter_username}
        options={Object.values(TwitterCommentStrategy).map((el) => ({ name: el, value: el }))}
        value={{ name: values.twitter_comment_strategy, value: values.twitter_comment_strategy }}
        onSelect={(e) => setFieldValue('twitter_comment_strategy', e.value)}
      />

      <UISwitch
        label="Sentiment Control:"
        description="Filter out tweets with aggressive or overly critical tones."
        labelPosition="left"
        disabled={!values.twitter_username}
        value={values.twitter_sentiment_control}
        onChange={(e) => setFieldValue('twitter_sentiment_control', e)}
      />

      <UISelector
        label="Crisis Communication"
        description="Choose a response style for sensitive situations."
        disabled={!values.twitter_username}
        options={Object.values(CRISIS_COMMUNICATION).map((el) => ({ name: el, value: el }))}
        value={{
          name: values.twitter_crisis_communication,
          value: values.twitter_crisis_communication,
        }}
        onSelect={(e) => setFieldValue('twitter_crisis_communication', e.value)}
      />
    </>
  );
};
