import { FC } from 'react';
import { NavLink } from 'react-router';
import { Modal } from 'src/components/ui/Modal.tsx';
import { UIButton } from 'src/components/ui/UIButton.tsx';
import { HowItWorksModalSettings } from 'src/types/modal.ts';

export const HowItWorksModal: FC<HowItWorksModalSettings> = () => {
  return (
    <Modal>
      <div className="text-center text-2xl">AVM (Agent Virtual Machine)</div>
      <p className="my-4">
        1.Create
        <br />
        Build an AI Agent tailored to your needs or community with ease.
      </p>
      <p className="my-4">
        2. Launch
        <br />
        Start gaining traction and attention by sharing your agent with the world.
      </p>
      <p className="my-4">
        3. Monetize
        <br />
        Grow your community and expand your agent’s functionality to unlock monetization
        opportunities. Ready to get started?
      </p>
      <div className="flex items-center justify-center text-white">
        <NavLink to="/agents/create">
          <UIButton>Let's Begin!</UIButton>
        </NavLink>
      </div>
    </Modal>
  );
};
