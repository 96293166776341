import { useFormikContext } from 'formik';
import { FC } from 'react';
import { UIRadioGroup } from 'src/components/ui/UIRadioGroup.tsx';
import { UISelector } from 'src/components/ui/UISelector.tsx';
import { UISwitch } from 'src/components/ui/UISwitch.tsx';
import { UITextArea } from 'src/components/ui/UITextArea.tsx';
import { PERSONALITIES } from 'src/constants/agents.ts';
import {
  EthicalRule,
  NewAgent,
  Personality,
  PRO_ACTIVITY_LVL,
  SENTIMENT,
} from 'src/types/agents.ts';

export const PersonalityTab: FC = () => {
  const { values, setFieldValue, getFieldProps, errors } = useFormikContext<NewAgent>();

  function handleRadioChange(propName: string, propValue: string) {
    setFieldValue(propName as keyof NewAgent, propValue as NewAgent[keyof NewAgent]);
  }

  return (
    <>
      <UIRadioGroup
        options={PERSONALITIES}
        name="personality_type"
        value={values.personality_type}
        onChange={handleRadioChange}
        label="Personality Type"
        description="Choose the personality type that best describes your agent."
      />

      {values.personality_type === Personality.CUSTOM && (
        <>
          <UITextArea
            label="Greeting"
            description="How your Agent starts the conversation."
            placeholder="Hello! I'm {Agent}, your new companion. What are we diving into today?"
            {...getFieldProps('greeting')}
            errorMessage={errors?.greeting}
          />
          <UITextArea
            label="Persona"
            description="Your Agent’s personality and traits."
            placeholder="{Agent} is an engaging and dynamic individual, blending unique talents with a touch of creativity to make every interaction memorable."
            {...getFieldProps('additional_personality_info')}
            errorMessage={errors?.additional_personality_info}
          />
          <UISelector
            label="Pro-Activity Level"
            description="Choose how actively the agent responds to tasks and initiates actions."
            value={{ value: values.pro_activity_level, name: values.pro_activity_level }}
            options={Object.values(PRO_ACTIVITY_LVL).map((el) => ({ name: el, value: el }))}
            onSelect={(e) => setFieldValue('pro_activity_level', e.value)}
          />
          <UISelector
            label="Sentiment Control"
            description="Set the tone of the agent’s responses to align with user preferences."
            value={{ value: values.sentiment, name: values.sentiment }}
            options={Object.values(SENTIMENT).map((el) => ({ name: el, value: el }))}
            onSelect={(e) => setFieldValue('sentiment', e.value)}
          />
          <UISelector
            label="AI Ethical Behavior Rules"
            description="Follows ethical guidelines to ensure responsible behavior."
            options={Object.values(EthicalRule).map((el) => ({
              name: el,
              value: el,
            }))}
            value={{
              name: values.ethical_rules,
              value: values.ethical_rules,
            }}
            onSelect={(e) => setFieldValue('ethical_rules', e.value)}
          />
          <UISwitch
            label="Adaption Mode:"
            labelPosition="left"
            description="Allow the agent to adjust its behavior dynamically in different scenarios."
            value={values.adaption_mode}
            onChange={(e) => setFieldValue('adaption_mode', e)}
          />
          <div className="flex flex-col gap-2">
            <div className="mb-2 inline-block max-w-fit rounded-xl bg-[#8648D2] px-3 py-1 text-xs">
              Soon
            </div>
            <UISwitch
              label="Learning feedback loop:"
              labelPosition="left"
              description="Enable continuous improvement of the agent based on its performance."
              disabled
              value={false}
            />
          </div>
        </>
      )}
    </>
  );
};
