import { RefCallback, useCallback, useState } from 'react';

export function useHookWithRefCallback<T extends HTMLElement>() {
  const [refState, setRefState] = useState<T | null>(null);

  const setRef = useCallback<RefCallback<T>>((node) => {
    setRefState(node);
  }, []);

  return [refState, setRef] as const;
}
