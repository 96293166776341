import { Icon } from '@iconify/react';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';
import { UIButton } from 'src/components/ui/UIButton';
import { UIInput } from 'src/components/ui/UIInput';
import { NewAgent } from 'src/types/agents';

export const ContentExamples: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  function handleContentExamplesChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target as { name: keyof NewAgent; value: string };

    const newContentExamples = values.twitter_content_examples.map((example, index) =>
      index === Number(name.split('.')[1]) ? value : example,
    );
    setFieldValue('twitter_content_examples', newContentExamples as string[]);
  }
  return (
    <>
      <div className="flex flex-col gap-3">
        {values.twitter_content_examples.map((_example, index) => (
          <div className="flex items-end gap-3" key={index}>
            <div className="flex-grow">
              <UIInput
                label={index === 0 ? 'Tweet Content Example' : ''}
                name={`twitter_content_examples.${index}`}
                description={
                  index === 0
                    ? 'Provide a detailed vision for the type of content your AI agent should post.'
                    : ''
                }
                placeholder="What do you want me to write about?"
                value={values.twitter_content_examples[index]}
                onChange={handleContentExamplesChange}
              />
            </div>

            {values.twitter_content_examples.length <= 10 &&
            values.twitter_content_examples.length - 1 === index ? (
              <UIButton
                className="h-[60px]"
                onClick={() =>
                  setFieldValue('twitter_content_examples', [
                    ...values.twitter_content_examples,
                    '',
                  ])
                }
              >
                <Icon icon="fa6-solid:plus" />
              </UIButton>
            ) : (
              <UIButton
                className="h-[60px]"
                onClick={() => {
                  const newPrompts = values.twitter_content_examples.filter((_, i) => i !== index);
                  setFieldValue('twitter_content_examples', newPrompts);
                }}
              >
                <Icon icon="fa6-solid:minus" />
              </UIButton>
            )}
          </div>
        ))}
      </div>
    </>
  );
};
