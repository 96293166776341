import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, ReactNode } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';

type TButtonSize = 'xl' | 'default';

const buttonSize: Record<TButtonSize, string> = {
  xl: 'py-2 px-16 text-xl lg:text-2xl',
  default: 'py-2 px-8 lg:text-xl',
};

export const FancyButton = forwardRef<
  HTMLDivElement,
  Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'size'> & {
    children?: ReactNode;
    size?: TButtonSize;
  }
>(({ children, size = 'default', className, ...props }, forwardedRef) => {
  return (
    <ItemWithFancyBorder hoverEffect className="group rounded-[60px]" ref={forwardedRef}>
      <button
        className={`flex items-center justify-center gap-6 rounded-[60px] ${buttonSize[size]} font-space-grotesk font-light ${className}`}
        style={{
          background: 'linear-gradient(90deg, rgba(1,1,1,1) 0%, rgba(55,6,81,1) 100%)',
        }}
        {...props}
      >
        <span>{children}</span>
      </button>
    </ItemWithFancyBorder>
  );
});
