import { useMutation } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { toast } from 'sonner';
import { UIButton } from 'src/components/ui/UIButton';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { checkTelegramBotToken } from 'src/pages/agents/agents--create/api';
import { useSetModal } from 'src/providers/ModalsProvider';
import {
  CRISIS_COMMUNICATION,
  NewAgent,
  TELEGRAM_GROUP_ENGAGEMENT_STRATEGY,
} from 'src/types/agents.ts';

export const TelegramIntegration: FC = () => {
  const { values, setFieldValue, setValues } = useFormikContext<NewAgent>();
  const [telegramToken, setTelegramToken] = useState(values.telegram_token);
  const setModal = useSetModal();

  const { mutate: mutateConnectTelegram, isPending: isPendingConnectTelegram } = useMutation({
    mutationKey: ['handleTelegramConnect'],
    mutationFn: (token: string) => checkTelegramBotToken(token, values.agent_id),
    onSuccess: (data) => {
      if (data) {
        setFieldValue('telegram_bot_username', data.username);
        setFieldValue('telegram_token', telegramToken);
      } else {
        toast.error('Failed to connect Telegram');
      }
    },
    onError: () => {
      toast.error('Failed to connect Telegram');
    },
  });

  const onTelegramDisconnect = () => {
    const resetState = {
      telegram_token: '',
      telegram_automoderation: false,
      telegram_crisis_communication: CRISIS_COMMUNICATION.APOLOGETIC,
      telegram_group_engagement_strategy: TELEGRAM_GROUP_ENGAGEMENT_STRATEGY.FRIENDLY,
      telegram_welcome_message: '',
    };

    setValues((prev) => ({ ...prev, ...resetState }));
  };

  const handleTelegramDisconnect = () => {
    setModal({
      modalKey: 'disconnect-agent-integration-modal',
      onDisconnect: onTelegramDisconnect,
      type: 'Telegram',
    });
  };

  return (
    <>
      <div className="flex items-end gap-2">
        <div className="flex-grow">
          <UIInput
            label="Connect Telegram"
            description="To link your AI Agent to your Telegram account, generate a bot token using @BotFather."
            name="telegram_token"
            placeholder="2309ja..."
            value={telegramToken}
            onChange={(e) => setTelegramToken(e.target.value)}
          />
        </div>
        {!values.telegram_token && (
          <UIButton
            onClick={() => mutateConnectTelegram(telegramToken)}
            processing={isPendingConnectTelegram}
            className="h-[60px]"
          >
            Connect
          </UIButton>
        )}
        {values.telegram_token && (
          <UIButton onClick={handleTelegramDisconnect} className="h-[60px]">
            Disconnect
          </UIButton>
        )}
      </div>
    </>
  );
};
