import { Icon } from '@iconify/react';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';
import { UIButton } from 'src/components/ui/UIButton';
import { UIInput } from 'src/components/ui/UIInput';
import { NewAgent } from 'src/types/agents';

export const ContentSpecificPreferences: FC = () => {
  const { values, setFieldValue } = useFormikContext<NewAgent>();
  function handleContentSpecificPreferencesChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = e.target as { name: keyof NewAgent; value: string };

    const newContentSpecificPreferences = values.twitter_content_specific_preferences.map(
      (example, index) => (index === Number(name.split('.')[1]) ? value : example),
    );
    setFieldValue(
      'twitter_content_specific_preferences',
      newContentSpecificPreferences as string[],
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {values.twitter_content_specific_preferences.map((_example, index) => (
        <div className="flex items-end gap-3" key={index}>
          <div className="flex-grow">
            <UIInput
              label={index === 0 ? 'Specific Preferences/Restrictions' : ''}
              description={
                index === 0
                  ? 'Specify any restrictions your AI agent should follow when generating posts.'
                  : ''
              }
              name={`twitter_content_specific_preferences.${index}`}
              placeholder="E.g. Avoid certain topics or hashtags"
              value={values.twitter_content_specific_preferences[index]}
              onChange={handleContentSpecificPreferencesChange}
            />
          </div>

          {values.twitter_content_specific_preferences.length <= 10 &&
          values.twitter_content_specific_preferences.length - 1 === index ? (
            <UIButton
              className="h-[60px]"
              onClick={() =>
                setFieldValue('twitter_content_specific_preferences', [
                  ...values.twitter_content_specific_preferences,
                  '',
                ])
              }
            >
              <Icon icon="fa6-solid:plus" />
            </UIButton>
          ) : (
            <UIButton
              className="h-[60px]"
              onClick={() => {
                const newPrompts = values.twitter_content_specific_preferences.filter(
                  (_, i) => i !== index,
                );
                setFieldValue('twitter_content_specific_preferences', newPrompts);
              }}
            >
              <Icon icon="fa6-solid:minus" />
            </UIButton>
          )}
        </div>
      ))}
    </div>
  );
};
