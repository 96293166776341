import { FC, isValidElement, ReactNode } from 'react';

const THEME_COLORS = {
  default: {
    bgActive: 'bg-[#3D0F77]',
    bgInactive: 'bg-gray-500',
  },
  light: {
    bgActive: 'bg-[#BCAFB6]',
    bgInactive: 'bg-[#BCAFB6]',
  },
};

type Props = {
  value: boolean;
  size?: 'lg';
  theme?: 'default' | 'light';
  label?: string | ReactNode;
  labelPosition?: 'left' | 'right';
  description?: string;
  disabled?: boolean;
  onChange?: (e: boolean) => void;
};

export const UISwitch: FC<Props> = ({
  value,
  label,
  labelPosition = 'right',
  onChange,
  size = 'lg',
  description,
  disabled = false,
  theme = 'default',
}) => {
  const colors = THEME_COLORS[theme];

  return (
    <div className="flex flex-col">
      <div
        className={`flex ${disabled ? '' : 'cursor-pointer'} items-center space-x-4`}
        onClick={() => (disabled ? void null : onChange?.(!value))}
      >
        {labelPosition === 'left' &&
          (isValidElement(label) ? (
            label
          ) : (
            <div className={`${size === 'lg' ? 'text-lg' : ''} ${disabled ? 'opacity-60' : ''}`}>
              {label}
            </div>
          ))}
        <div
          className={`relative ${size === 'lg' ? 'h-[26px] w-[54px]' : 'h-[18px] w-[32px]'} ${
            value ? colors.bgActive : colors.bgInactive
          } rounded-full`}
        >
          <div
            className={`absolute ${
              size === 'lg' ? 'h-[22px] w-[22px]' : 'h-[14px] w-[14px]'
            } ${disabled ? 'opacity-60' : ''} left-[2px] top-[2px] transform rounded-full transition-transform duration-200 ${
              value
                ? `${size === 'lg' ? 'translate-x-[26px]' : 'translate-x-[13px]'} bg-white`
                : 'translate-x-0 bg-gray-600'
            }`}
          ></div>
        </div>
        {labelPosition === 'right' &&
          (isValidElement(label) ? (
            label
          ) : (
            <div className={size === 'lg' ? 'text-lg' : ''}>{label}</div>
          ))}
      </div>
      {description && <p className="mt-2 text-sm opacity-60">{description}</p>}
    </div>
  );
};
