import { useFormikContext } from 'formik';
import { FC } from 'react';
import { UICheckboxGroup } from 'src/components/ui/UICheckboxGroup.tsx';
import { UIInput } from 'src/components/ui/UIInput.tsx';
import { CATEGORIES, CHARACTERS, GOALS } from 'src/constants/agents.ts';
import { NewAgent } from 'src/types/agents.ts';
import { AgentImageDropzone } from '../Dropzone';

export const BasicTab: FC = () => {
  const { values, setFieldValue, getFieldProps, errors } = useFormikContext<NewAgent>();

  function handleCheckboxGroupChange(e: string[], name: keyof NewAgent) {
    setFieldValue(name, e[0]);
  }

  return (
    <>
      <UIInput
        {...getFieldProps('name')}
        errorMessage={errors?.name}
        label="Name Your Agent"
        description="Choose a name for your agent."
      />
      <UIInput
        description="Assign a unique token-identifier for your agent."
        label="Ticker"
        {...getFieldProps('ticker')}
        errorMessage={errors?.ticker}
      />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-0.5">
          <span className="block text-lg">Agent Picture</span>
          <span className="text-sm opacity-60">Upload an image to represent your agent.</span>
        </div>
        <AgentImageDropzone
          onDrop={(file) => setFieldValue('image_url', file)}
          accept={{ 'image/*': ['.jpeg', '.png', '.jpg', '.webp'] }}
          agentId={values.agent_id}
          imageUrl={values.image_url}
        />
        {errors?.image_url && <p className="text-red-700">{errors?.image_url}</p>}
      </div>
      <UIInput
        {...getFieldProps('description')}
        errorMessage={errors?.description}
        label="Agent Intro"
        description="Write a brief introduction explaining your agent's purpose."
      />

      <div className="my-6 h-[2px] w-full bg-gradient-to-r from-transparent via-white/50 to-transparent" />

      <div className="flex flex-col gap-0.5">
        <span className="block text-lg">Project Links</span>
        <span className="text-sm opacity-60">
          Use this form to add additional supportive details about your project, if available.
        </span>
      </div>
      <UIInput
        {...getFieldProps('socials.website')}
        errorMessage={errors?.socials?.website}
        label="Website"
        placeholder="https://...."
      />
      <UIInput
        {...getFieldProps('socials.twitter')}
        errorMessage={errors?.socials?.twitter}
        label="X (Twitter)"
        placeholder="x.com/..."
      />
      <UIInput
        {...getFieldProps('socials.telegram')}
        errorMessage={errors?.socials?.telegram}
        label="Telegram"
        placeholder="t.me/..."
      />

      <div className="my-6 h-[2px] w-full bg-gradient-to-r from-transparent via-white/50 to-transparent" />

      <UICheckboxGroup
        label="Interests"
        max={3}
        items={CATEGORIES}
        values={values.origin}
        onChange={(e) => setFieldValue('origin', e)}
        multiple={true}
        errorMessage={errors?.origin}
      />

      <UICheckboxGroup
        label="Goal"
        items={GOALS}
        values={values.goal ? [values.goal] : undefined}
        onChange={(e) => handleCheckboxGroupChange(e, 'goal')}
        multiple={false}
        errorMessage={errors?.goal}
      />

      <UICheckboxGroup
        label="Character"
        items={CHARACTERS}
        values={values.character ? [values.character] : undefined}
        onChange={(e) => handleCheckboxGroupChange(e, 'character')}
        multiple={false}
        errorMessage={errors?.character}
      />
    </>
  );
};
