import { Icon } from '@iconify/react/dist/iconify.js';
import { FC, useState } from 'react';
import { toast } from 'sonner';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { AgixBotContent } from 'src/pages/agents/agents--id/components/chat/AgixBotContent.tsx';
import { AgixBotFooter } from 'src/pages/agents/agents--id/components/chat/AgixBotFooter.tsx';
import { TradesTab } from 'src/pages/agents/agents--id/components/TradesTab.tsx';
import { Agent } from 'src/types/agents.ts';
import { shortenHash } from 'src/utils/ui';

const TABS = [
  {
    name: 'About',
    value: 'about',
  },
  {
    name: 'Trades',
    value: 'trades',
  },
  {
    name: 'Chat',
    value: 'chat',
  },
  {
    name: 'Developer',
    value: 'developer',
  },
];

export const TabsBlock: FC<{ agent: Agent }> = ({ agent }) => {
  const [currentTab, setCurrentTab] = useState(TABS.length > 0 ? TABS[0].value : '');

  function handleTabClick(tab: (typeof TABS)[number]) {
    setCurrentTab(tab.value);
  }

  function handleCopy() {
    navigator.clipboard.writeText(agent.creator_contract_address || '');
    toast.success('Copied!');
  }

  return (
    <ItemWithFancyBorder className="overflow-hidden rounded-xl">
      <div className="flex h-full flex-col overflow-hidden rounded-xl bg-black p-5">
        <div className="mb-5 grid grid-cols-2 gap-2 sm:grid-cols-4">
          {TABS.map((el) => (
            <button
              key={el.value}
              className={`rounded-xl border px-3 py-2 text-lg ${
                currentTab === el.value ? 'border-white' : 'border-[#232323]'
              }`}
              onClick={() => handleTabClick(el)}
              aria-pressed={currentTab === el.value}
            >
              {el.name}
            </button>
          ))}
        </div>
        {currentTab === 'about' ? (
          <p className="font-space-grotesk leading-7">{agent.description}</p>
        ) : currentTab === 'trades' ? (
          <TradesTab agent={agent} />
        ) : currentTab === 'chat' ? (
          <div className="flex h-[800px] flex-grow flex-col">
            <AgixBotContent />
            <AgixBotFooter />
          </div>
        ) : currentTab === 'developer' ? (
          <div
            className="group flex cursor-pointer items-center justify-between gap-2 rounded-2xl border border-[#3E1473] bg-[#1A0029]/50 p-2 transition-all hover:border-[#4F1C94] hover:bg-[#1A0029] sm:gap-3"
            onClick={handleCopy}
          >
            <span className="text-sm text-[#848484] group-hover:text-white sm:text-base">
              AI Agent Developer Address:
            </span>
            <div className="flex items-center">
              <span className="px-2 text-sm text-white sm:text-base">
                {shortenHash(agent.creator_contract_address || '')}
              </span>
              <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-[#3E1473] transition-colors group-hover:bg-[#4F1C94]">
                <Icon icon="fa6-solid:copy" className="text-white" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </ItemWithFancyBorder>
  );
};
