import { Icon } from '@iconify/react/dist/iconify.js';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { Link, useParams } from 'react-router';
import { toast } from 'sonner';
import { AgentsTable } from 'src/components/agents/AgentsTable';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { FancyButton } from 'src/components/ui/FancyButton';
import { LoadingStub } from 'src/components/ui/LoadingStub';
import { UIAlert } from 'src/components/ui/UIAlert';
import { UIButton } from 'src/components/ui/UIButton';
import { useBalanceOf } from 'src/hooks/useBalanceOf';
import { useUnwrapToken } from 'src/hooks/useUnwrapToken';
import { axiosInstance } from 'src/libs/axios';
import { BN } from 'src/utils/bigNumber';
import { shortenHash } from 'src/utils/ui';
import { AgentTokenChart } from './components/AgentTokenChart';
import { BondingCurve } from './components/BoundingCurve';
import { HolderDistributionCard } from './components/HolderDistributionCard';
import { OkxWidget } from './components/OkxWidget';
import { SwapBlock } from './components/SwapBlock';
import { TabsBlock } from './components/TabsBlock';
import { TokenDataCard } from './components/TokenDataCard';

export const AgentsIdPage: FC = () => {
  const { id } = useParams();

  const {
    isLoading,
    data: currentItem,
    isError,
  } = useQuery({
    queryKey: ['agent', id],
    queryFn: () => axiosInstance.get(`/api/agents/${id}`).then((res) => res.data),
    refetchOnWindowFocus: false,
  });

  const agentTokenBalance = useBalanceOf(currentItem?.updated_token_address || '');
  const { unwrap, isConnected } = useUnwrapToken(currentItem?.updated_token_address);

  function handleCopy() {
    if (currentItem?.contract_address) {
      navigator.clipboard.writeText(currentItem.contract_address);
      toast.success('Address copied to clipboard!');
    }
  }

  if (isLoading) return <LoadingStub label="Fetching data" />;
  if (isError) return <UIAlert type="error" message="Error while fetching agents" />;
  if (!currentItem) return <UIAlert type="info" message="Agent not found" />;

  return (
    <div className="container mx-auto flex h-full flex-grow flex-col px-4 pb-6 md:px-8">
      <div className="overflow-x-scroll">
        <AgentsTable agents={[currentItem]} withSocials withControls={false} />
      </div>

      <div className="mt-6 grid flex-grow grid-cols-1 gap-3.5 md:mt-10 lg:grid-cols-3">
        <div className="col-span-1 flex flex-col gap-3.5 lg:col-span-2">
          <ItemWithFancyBorder className="rounded-xl">
            <div
              className="rounded-xl p-4"
              style={{
                background: 'linear-gradient(135deg, rgba(67,20,115,1) 0%, rgba(36,13,39,1) 20%)',
              }}
            >
              <div className="flex flex-col items-center justify-between gap-4 pb-4 sm:flex-row">
                <h2 className="font-space-grotesk text-xl font-bold sm:text-2xl">
                  Terminal of {currentItem.name}
                </h2>
                <div
                  className="group flex cursor-pointer items-center justify-between gap-2 rounded-2xl border border-[#3E1473] bg-[#1A0029]/50 p-2 transition-all hover:border-[#4F1C94] hover:bg-[#1A0029] sm:gap-3"
                  onClick={handleCopy}
                >
                  <span className="text-sm text-[#848484] group-hover:text-white sm:text-base">
                    CA:
                  </span>
                  <div className="flex items-center">
                    <span className="px-2 text-sm text-white sm:text-base">
                      {shortenHash(currentItem.contract_address || '')}
                    </span>
                    <div className="flex h-8 w-8 items-center justify-center rounded-xl bg-[#3E1473] transition-colors group-hover:bg-[#4F1C94]">
                      <Icon icon="fa6-solid:copy" className="text-white" />
                    </div>
                  </div>
                </div>
              </div>
              {currentItem.updated_token_address ? (
                <div className="relative">
                  <iframe
                    className="min-h-[300px] w-full rounded border-none bg-white sm:min-h-[500px]"
                    style={{ border: 'none' }}
                    src={`https://www.geckoterminal.com/eth/pools/${currentItem.uniswap_pair_address}?embed=1&info=0&swaps=0&grayscale=0&light_chart=1`}
                  />
                  <div className="absolute bottom-0 left-0 h-[36px] w-full rounded bg-white"></div>
                </div>
              ) : (
                <AgentTokenChart agent={currentItem} />
              )}
            </div>
          </ItemWithFancyBorder>
          {currentItem.twitter_agent_username && (
            <ItemWithFancyBorder className="rounded-xl">
              <div className="rounded-xl bg-black p-5 pb-10">
                <div className="mb-6 flex items-center">
                  <span className="text-lg font-bold">Influence Metrics</span>
                </div>
                <div className="grid grid-cols-2 gap-4 xl:grid-cols-4">
                  <div className="flex flex-col">
                    <span className="text-lg">Followers</span>
                    <span className="text-sm opacity-60">
                      {currentItem.twitter_stats.followers_count}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg">Influencers</span>
                    <span className="text-sm opacity-60">
                      {currentItem.twitter_stats.influencers_count}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg">Projects</span>
                    <span className="text-sm opacity-60">
                      {currentItem.twitter_stats.projects_count}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg">Score</span>
                    <span className="text-sm opacity-60">{currentItem.twitter_stats.score}</span>
                  </div>
                </div>
              </div>
            </ItemWithFancyBorder>
          )}
          <TabsBlock agent={currentItem} />
        </div>
        <div className="flex flex-col gap-5">
          <ItemWithFancyBorder className="relative rounded-xl">
            <div className="bg-[url('/images/masha-banner.jpg')] bg-cover bg-center bg-no-repeat pt-[50%]">
              <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center p-4 font-space-grotesk">
                <h5 className="mb-2 text-center">MASHA is streaming 24/7</h5>
                <p className="mb-4 text-center">Start the Livestream with MASHA NOW</p>
                <Link to="/stream">
                  <FancyButton>Talk to Masha</FancyButton>
                </Link>
              </div>
            </div>
          </ItemWithFancyBorder>
          {!currentItem.updated_token_address ? (
            <SwapBlock agent={currentItem} />
          ) : BN(agentTokenBalance.balance.raw).gt(0) ? (
            <ItemWithFancyBorder className="rounded-xl">
              <div className="h-full rounded-xl bg-black p-6">
                <div className="mb-6 flex items-center">
                  <span className="font-space-grotesk text-2xl font-bold">Unwrap</span>
                </div>
                <UIButton className="w-full" onClick={unwrap}>
                  {isConnected ? 'Unwrap' : 'Connect wallet'}
                </UIButton>
              </div>
            </ItemWithFancyBorder>
          ) : (
            <OkxWidget agent={currentItem} />
          )}
          {!currentItem.updated_token_address && <BondingCurve agent={currentItem} />}
          <TokenDataCard agent={currentItem} />
          <HolderDistributionCard agent={currentItem} />
        </div>
      </div>
    </div>
  );
};
