import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, ReactNode } from 'react';
import IconArrow from 'src/assets/images/btn-arrow-right.svg?react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';

enum Size {
  xl = 'xl',
  default = 'default',
}

const buttonSize: Record<keyof typeof Size, string> = {
  xl: 'py-1 pl-20 pr-2 text-xl lg:text-2xl',
  default: 'py-1 pl-10 pr-2 lg:text-xl',
};

const circleSize: Record<keyof typeof Size, string> = {
  xl: 'size-12 lg:size-14',
  default: 'size-8 lg:size-10',
} as const;

const arrowSize: Record<keyof typeof Size, string> = {
  xl: 'w-10',
  default: 'w-7',
} as const;

export const FancyButtonWithArrow = forwardRef<
  HTMLDivElement,
  Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'size'> & {
    children?: ReactNode;
    arrowBottom?: boolean;
    size?: keyof typeof Size;
  }
>(({ children, arrowBottom = false, size = 'default', ...props }, forwardedRef) => {
  return (
    <ItemWithFancyBorder
      hoverEffect={!props.disabled}
      className={`group rounded-[60px] ${props.disabled ? 'opacity-60' : ''}`}
      ref={forwardedRef}
    >
      <button
        className={`flex items-center gap-6 rounded-[60px] ${buttonSize[size]} font-space-grotesk font-light`}
        style={{
          background: 'linear-gradient(90deg, rgba(1,1,1,1) 0%, rgba(55,6,81,1) 100%)',
        }}
        {...props}
      >
        <span>{children}</span>
        <div
          className={`flex ${circleSize[size]} items-center justify-center rounded-full bg-black transition-all ${!props.disabled ? 'group-hover:bg-white' : ''}`}
        >
          <div className={`${arrowSize[size]}`}>
            <IconArrow
              className={`w-full transition-all group-hover:fill-black ${arrowBottom ? 'rotate-90' : !props.disabled ? 'group-hover:animate-move-arrow' : ''}`}
            />
          </div>
        </div>
      </button>
    </ItemWithFancyBorder>
  );
});
