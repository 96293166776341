import { useEffect } from 'react';
import { InViewComponent } from 'src/components/InViewComponent.tsx';
import { FancyButtonWithArrow } from 'src/components/ui/FancyButtonWithArrow.tsx';
import { useHookWithRefCallback } from 'src/hooks/useHookWithRefCallback.tsx';

export const DeployBlock = () => {
  const [ref1, setRef1] = useHookWithRefCallback<HTMLDivElement>();
  const [title1Ref, setTitle1Ref] = useHookWithRefCallback<HTMLHeadingElement>();
  const [btnRef, setBtnRef] = useHookWithRefCallback<HTMLDivElement>();

  useEffect(() => {
    if (!ref1) return;
    if (!title1Ref) return;
    if (!btnRef) return;

    title1Ref?.classList.add('opacity-0');
    btnRef?.classList.add('opacity-0');

    ref1.addEventListener('animationend', () => {
      // Add the classes to start the title animation after parent finishes
      title1Ref?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      btnRef?.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
    });
  }, [ref1, title1Ref, btnRef]);

  return (
    <section className="container mx-auto mb-20 lg:mb-0" ref={setRef1}>
      <InViewComponent
        inViewClasses="animate__animated animate__fadeIn"
        rootMargin={'0% 0% -300px 0%'}
      >
        <div
          className="relative mx-auto rounded-xl bg-[url('/images/deploy-bg-mob.png')] p-4 lg:w-[80%] lg:bg-[url('/images/bg-deploy.png')] lg:p-0 lg:pt-[50%]"
          style={{
            backgroundSize: 'cover',
          }}
        >
          <div className="left-0 top-0 flex h-full w-full flex-col items-start lg:absolute lg:pl-16 lg:pt-20">
            <h4
              className="mb-8 font-space-grotesk text-3xl font-bold leading-[1.5em] lg:text-6xl"
              ref={setTitle1Ref}
            >
              Deploy AI Agents.
              <br />
              Conquer Every
              <br />
              Platform
            </h4>
            <FancyButtonWithArrow size="xl" ref={setBtnRef}>
              Arriving Shortly
            </FancyButtonWithArrow>
          </div>
        </div>
      </InViewComponent>
    </section>
  );
};
