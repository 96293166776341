import { ChangeEvent, FC } from 'react';
import { UIRadio } from 'src/components/ui/UIRadio.tsx';

type RadioOption = {
  value: string;
  label: string;
  desc?: string;
};

type Props = {
  name: string;
  label?: string;
  description?: string;
  options: RadioOption[];
  value: string;
  errorMessage?: string;
  onChange: (propName: string, propValue: string) => void;
};

export const UIRadioGroup: FC<Props> = ({
  name,
  label,
  description,
  options,
  value,
  onChange,
  errorMessage,
}) => {
  function handleRadioChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    onChange(name, value);
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {(label || description) && (
        <div className="col-span-1 flex flex-col gap-0.5 sm:col-span-2 md:col-span-3">
          <span className="block text-lg">{label}</span>
          <span className="text-sm opacity-60">{description}</span>
        </div>
      )}
      {options.map((option) => (
        <UIRadio
          key={option.value}
          label={option.label}
          desc={option.desc}
          value={option.value}
          checked={option.value === value}
          onChange={handleRadioChange}
        />
      ))}
      {errorMessage && (
        <div className="col-span-1 text-red-700 sm:col-span-2 md:col-span-3">{errorMessage}</div>
      )}
    </div>
  );
};
