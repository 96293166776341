import { useFormikContext } from 'formik';
import { FC } from 'react';
import IconTG from 'src/assets/images/icons/i-tg-bot.svg?react';
import IconX from 'src/assets/images/icons/i-x-bot.svg?react';
import { UIAlert } from 'src/components/ui/UIAlert';
import { UICheckboxGroup } from 'src/components/ui/UICheckboxGroup';
import { UISelector } from 'src/components/ui/UISelector';
import { UISwitch } from 'src/components/ui/UISwitch';
import { UITextArea } from 'src/components/ui/UITextArea';
import { CONTENT_DIRECTIONS } from 'src/constants/agents';
import {
  CRISIS_COMMUNICATION,
  NewAgent,
  TELEGRAM_GROUP_ENGAGEMENT_STRATEGY,
} from 'src/types/agents';
import { AdvancedSettings } from './AdvancedSettings';
import { ContentExamples } from './ContentExamples';
import { ContentSpecificPreferences } from './ContentSpecificPreferences';
import { VideoSettings } from './VideoSettings';

export const ContentTab: FC = () => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<NewAgent>();

  if (!values.twitter_username && !values.telegram_token) {
    return (
      <UIAlert
        type="info"
        message="To access these settings, please return to the Integrations page and connect X (Twitter) / Telegram."
      />
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {values.twitter_username && (
        <>
          <div className="flex items-center gap-4 border-b border-white border-opacity-20 pb-6">
            <IconX className="w-6" />
            <span className="text-xl">X (Twitter)</span>
          </div>
          <VideoSettings />

          <div className="my-6 h-[1px] w-full bg-white/20" />

          <ContentExamples />
          <ContentSpecificPreferences />
          <UICheckboxGroup
            label="Define Your Content Direction"
            description="Define the type of content your AI agent will post on X (Twitter)."
            items={CONTENT_DIRECTIONS.map((el) => el)}
            values={values.twitter_content_directions}
            onChange={(e) => setFieldValue('twitter_content_directions', e)}
          />

          <div className="my-6 h-[1px] w-full bg-white/20" />

          <AdvancedSettings />
        </>
      )}

      {values.twitter_username && values.telegram_token && (
        <div className="my-6 h-[2px] w-full bg-gradient-to-r from-transparent via-white/50 to-transparent" />
      )}

      {values.telegram_token && (
        <>
          <div className="flex items-center gap-4 border-b border-white border-opacity-20 pb-6">
            <IconTG className="w-6" />
            <span className="text-xl">Telegram</span>
          </div>
          <UISwitch
            label="Auto Moderation"
            description="Filters spam, offensive content, and rule violations."
            labelPosition="left"
            disabled={!values.telegram_token}
            value={values.telegram_automoderation}
            onChange={(e) => setFieldValue('telegram_automoderation', e)}
          />

          <UISelector
            label="Group Engagement Strategy"
            description="Adjusts Agent's communication style to fit the group."
            disabled={!values.telegram_token}
            options={Object.values(TELEGRAM_GROUP_ENGAGEMENT_STRATEGY).map((el) => ({
              name: el,
              value: el,
            }))}
            value={{
              name: values.telegram_group_engagement_strategy,
              value: values.telegram_group_engagement_strategy,
            }}
            onSelect={(e) => setFieldValue('telegram_group_engagement_strategy', e.value)}
          />

          <UITextArea
            label="Welcome Message"
            description="Welcomes new members with a custom message."
            placeholder="Hi, {username}! 👋 Welcome to the group! We're excited to have you here. Feel free to introduce yourself and let us know how we can help. If you have any questions, just ask — I'm here to assist you!"
            disabled={!values.telegram_token}
            {...getFieldProps('telegram_welcome_message')}
          />
          <UISelector
            label="Crisis Communication Strategy"
            description="Responds to sensitive situations based on the chosen style."
            disabled={!values.telegram_token}
            options={Object.values(CRISIS_COMMUNICATION).map((el) => ({
              name: el,
              value: el,
            }))}
            value={{
              name: values.telegram_crisis_communication,
              value: values.telegram_crisis_communication,
            }}
            onSelect={(e) => setFieldValue('telegram_crisis_communication', e.value)}
          />
        </>
      )}
    </div>
  );
};
