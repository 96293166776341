import { useAppKitAccount } from '@reown/appkit/react';
import { useEffect, useRef, useState } from 'react';
import { useImmutableCallback } from 'src/hooks/useActualRef.ts';
import { useBalanceOf } from 'src/hooks/useBalanceOf.tsx';
import { useTimeDifference } from 'src/hooks/useTimeDifference.ts';
import { axiosInstance } from 'src/libs/axios.ts';
import { BN } from 'src/utils/bigNumber.ts';

const INITIAL_REMAINING_TIME = 2 * 60 * 1000 + 1000;

export const useServerRemainingTimer = (stream: boolean, endSession: () => void) => {
  const { address, isConnected } = useAppKitAccount();
  const { balance } = useBalanceOf('0x6f365eb3686ee95bdefbae71f1728d62c0af7ab1');

  const [usedTime, setUsedTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState<number>(INITIAL_REMAINING_TIME);

  const timeUntil = useTimeDifference(Date.now() + remainingTime, { withColon: true });

  const durationTime = useRef<number>(0);

  useEffect(() => {
    if (!isConnected) return;

    getStreamUsage();
  }, [isConnected]);

  useEffect(() => {
    const maxRemainingTime = calculateMaxRemainingTime();
    setRemainingTime(
      maxRemainingTime === Infinity ? maxRemainingTime : maxRemainingTime - usedTime,
    );
  }, [usedTime, balance]);

  useEffect(() => {
    if (!stream) return;
    if (!remainingTime || remainingTime === Infinity) return;

    const remindTimeInterval = setInterval(() => {
      setRemainingTime((prev) => {
        if (durationTime.current === 5) {
          durationTime.current = 0;
          axiosInstance.post(
            '/api/stream/usage',
            {},
            {
              params: {
                wallet: address,
                duration: 5,
              },
            },
          );
        } else {
          durationTime.current = durationTime.current + 1;
        }
        if (prev <= 1000) {
          clearInterval(remindTimeInterval);
          endSession();
          return 0;
        }

        return prev - 1000;
      });
    }, 1000);

    return () => {
      clearInterval(remindTimeInterval);
      axiosInstance.post(
        '/api/stream/usage',
        {},
        {
          params: {
            wallet: address,
            duration: durationTime.current,
          },
        },
      );
    };
  }, [stream]);

  async function getStreamUsage() {
    try {
      const resp = await axiosInstance.get<{ seconds: number }>('/api/stream/usage', {
        params: {
          wallet: address,
        },
      });

      setUsedTime(resp.data.seconds * 1000);
    } catch (e) {
      console.error(e);
    }
  }

  const calculateMaxRemainingTime = useImmutableCallback(() => {
    let maxTime = INITIAL_REMAINING_TIME;

    if (BN(balance.formatted).gt(20000)) {
      maxTime = BN(maxTime)
        .plus(30 * 60 * 1000)
        .toNumber();
    } else if (BN(balance.formatted).gt(15000)) {
      maxTime = BN(maxTime)
        .plus(25 * 60 * 1000)
        .toNumber();
    } else if (BN(balance.formatted).gt(10000)) {
      maxTime = BN(maxTime)
        .plus(20 * 60 * 1000)
        .toNumber();
    } else if (BN(balance.formatted).gt(5000)) {
      maxTime = BN(maxTime)
        .plus(10 * 60 * 1000)
        .toNumber();
    }

    return maxTime;
  });

  return timeUntil;
};
