import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder';
import { LoadingStub } from 'src/components/ui/LoadingStub';
import { UIDropdownMenu } from 'src/components/ui/UIDropdownMenu';
import { usePagination } from 'src/hooks/usePagination';
import { axiosInstance } from 'src/libs/axios';
import { NewAgent } from 'src/types/agents';
import { HeygenVoice } from 'src/types/heygen';

const languageEmojis: Record<string, string> = {
  English: '🇺🇸',
  Spanish: '🇪🇸',
  French: '🇫🇷',
  Portuguese: '🇵🇹',
  Indonesian: '🇮🇩',
  Turkish: '🇹🇷',
  German: '🇩🇪',
  Italian: '🇮🇹',
  Japanese: '🇯🇵',
  Korean: '🇰🇷',
  Chinese: '🇨🇳',
  Arabic: '🇸🇦',
  Hindi: '🇮🇳',
  Vietnamese: '🇻🇳',
  Thai: '🇹🇭',
  Dutch: '🇳🇱',
  Polish: '🇵🇱',
};

const getLanguageEmoji = (language: string) => {
  return languageEmojis[language] || '🌐';
};

type Filters = {
  language?: string;
  gender?: string;
};

export const VoiceAccordion: FC = () => {
  const { values, setValues } = useFormikContext<NewAgent>();
  const [playingVoiceId, setPlayingVoiceId] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filters>({});
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { data, isLoading } = useQuery({
    queryKey: ['heygen-voices'],
    queryFn: () =>
      axiosInstance
        .get<{ voices: HeygenVoice[] }>(`/api/heygen/voices`, {
          params: {
            support_interactive_avatar: true,
          },
        })
        .then((res) => res.data?.voices),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const handleFilterChange = (filterType: keyof Filters, value: string | undefined) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  const filteredVoices = useMemo(() => {
    return data?.filter((voice) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        if (key === 'language') return voice.language === value;
        if (key === 'gender') return voice.gender === value.toLowerCase();
        return true;
      });
    });
  }, [data, filters]);

  const { currentData, currentPage, maxPage, next, prev } = usePagination<HeygenVoice>(
    filteredVoices || [],
    10,
  );

  const handlePlayVoice = useCallback(
    (voice: HeygenVoice, event: React.MouseEvent) => {
      event.stopPropagation();

      if (audioRef.current) {
        audioRef.current.pause();
        if (playingVoiceId === voice.voice_id) {
          setPlayingVoiceId(null);
          return;
        }
      }

      const audio = new Audio(voice.preview_audio);
      audioRef.current = audio;

      audio.play();
      setPlayingVoiceId(voice.voice_id);

      audio.onended = () => {
        setPlayingVoiceId(null);
      };
    },
    [playingVoiceId],
  );

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  return (
    <div className={!values.twitter_agent_post_videos ? 'pointer-events-none opacity-60' : ''}>
      <ItemWithFancyBorder className="rounded-xl">
        <Disclosure>
          {({ open }) => (
            <div className="rounded-xl bg-black px-6 py-4">
              <DisclosureButton className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  {values.heygen.voice_id && (
                    <span className="text-md" title={values.heygen.voice_language}>
                      {getLanguageEmoji(values.heygen.voice_language)}
                    </span>
                  )}
                  <span className="text-md">{values.heygen.voice_name || 'Select Voice'}</span>
                </div>

                <div className="flex items-center gap-2">
                  {open && (
                    <div className="flex gap-4">
                      <UIDropdownMenu
                        items={[
                          { content: 'Male', onClick: () => handleFilterChange('gender', 'Male') },
                          {
                            content: 'Female',
                            onClick: () => handleFilterChange('gender', 'Female'),
                          },
                          {
                            content: 'Clear',
                            onClick: () => handleFilterChange('gender', undefined),
                          },
                        ]}
                        button={{
                          className:
                            'flex items-center justify-center gap-2 rounded-xl bg-[#370651] px-3 py-1.5 border border-[#848484]',
                          content: (
                            <>
                              <span className="font-regular text-sm">
                                {filters.gender || 'Gender'}
                              </span>
                              <Icon icon="fa6-solid:caret-down" />
                            </>
                          ),
                        }}
                      />
                      <UIDropdownMenu
                        items={[
                          ...Object.keys(languageEmojis).map((lang) => ({
                            content: (
                              <span>
                                {getLanguageEmoji(lang)} {lang}
                              </span>
                            ),
                            onClick: () => handleFilterChange('language', lang),
                          })),
                          {
                            content: 'Clear',
                            onClick: () => handleFilterChange('language', undefined),
                          },
                        ]}
                        button={{
                          className:
                            'flex items-center justify-center gap-2 rounded-xl bg-[#370651] px-3 py-1.5 border border-[#848484]',
                          content: (
                            <>
                              <span className="font-regular text-sm">
                                {filters.language ? (
                                  <>
                                    {getLanguageEmoji(filters.language)} {filters.language}
                                  </>
                                ) : (
                                  'Language'
                                )}
                              </span>
                              <Icon icon="fa6-solid:caret-down" />
                            </>
                          ),
                        }}
                      />
                    </div>
                  )}
                  <Icon
                    className={`size-4 transition-transform duration-200 ${open ? 'rotate-180' : ''}`}
                    icon="fa6-solid:caret-down"
                  />
                </div>
              </DisclosureButton>

              <DisclosurePanel className="mt-4">
                <div className="space-y-3">
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <LoadingStub label="Loading voices..." />
                    </div>
                  ) : (
                    currentData.map((voice) => (
                      <Fragment key={voice.voice_id}>
                        <div className="h-[1px] w-full bg-white opacity-60" />
                        <div
                          className="flex cursor-pointer items-center justify-between py-2 hover:bg-white/5"
                          onClick={() =>
                            setValues({
                              ...values,
                              heygen: {
                                ...values.heygen,
                                voice_id: voice.voice_id,
                                voice_name: voice.name,
                                voice_language: voice.language,
                              },
                            })
                          }
                        >
                          <div className="flex items-center gap-2">
                            <span className="text-base" title={voice.language}>
                              {getLanguageEmoji(voice.language)}
                            </span>
                            <span className="text-base">{voice.name}</span>
                          </div>
                          <div className="flex items-center gap-3">
                            {values.heygen.voice_id === voice.voice_id && (
                              <span className="text-sm text-purple-400">Selected</span>
                            )}
                            <button
                              onClick={(e) => handlePlayVoice(voice, e)}
                              className="flex items-center justify-center p-1 transition-colors hover:text-purple-400"
                              title="Preview voice"
                            >
                              {playingVoiceId === voice.voice_id ? (
                                <Icon
                                  className="size-6 animate-pulse text-purple-500"
                                  icon="fa6-solid:circle-pause"
                                />
                              ) : (
                                <Icon
                                  className="size-6 text-gray-400"
                                  icon="fa6-solid:circle-play"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    ))
                  )}
                </div>

                {/* Pagination Controls */}
                {maxPage > 1 && (
                  <div className="mt-4 flex items-center justify-center gap-3">
                    <button
                      className="text-gray-400 hover:text-white disabled:opacity-60"
                      onClick={prev}
                      disabled={currentPage === 1}
                    >
                      <Icon icon="fa6-solid:chevron-left" className="size-4" />
                    </button>
                    <span className="min-w-[4rem] text-center text-sm">
                      {currentPage} / {maxPage}
                    </span>
                    <button
                      className="text-gray-400 hover:text-white disabled:opacity-60"
                      onClick={next}
                      disabled={currentPage === maxPage}
                    >
                      <Icon icon="fa6-solid:chevron-right" className="size-4" />
                    </button>
                  </div>
                )}
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      </ItemWithFancyBorder>
    </div>
  );
};
