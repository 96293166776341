import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { toast } from 'sonner';
import { ItemWithFancyBorder } from 'src/components/ItemWithFancyBorder.tsx';
import { UIButton } from 'src/components/ui/UIButton';
import { UISwitch } from 'src/components/ui/UISwitch.tsx';
import { checkTwitterAuth, createAuthLink } from 'src/pages/agents/agents--create/api';
import { useSetModal } from 'src/providers/ModalsProvider';
import { CRISIS_COMMUNICATION, NewAgent, TwitterCommentStrategy } from 'src/types/agents.ts';

export const TwitterIntegration: FC = () => {
  const { values, setFieldValue, setValues } = useFormikContext<NewAgent>();
  const setModal = useSetModal();

  useQuery({
    queryKey: ['checkTwitterAuth'],
    queryFn: () =>
      checkTwitterAuth(values.agent_id).then((data) => {
        if (data) {
          setFieldValue('twitter_username', data.username);
          return data;
        } else {
          return {};
        }
      }),
    refetchOnWindowFocus: false,
    enabled: !values.twitter_username && !!values.agent_id,
  });

  const { mutate: mutateConnectTwitter } = useMutation({
    mutationKey: ['handleTwitterConnect'],
    mutationFn: ({ agent_id, redirect_url }: { agent_id: string; redirect_url: string }) =>
      createAuthLink(agent_id, redirect_url),
    onSuccess: (data) => {
      const link = document.createElement('a');
      link.href = data;
      document.body.appendChild(link);
      link.click();
    },
    onError: () => {
      toast.error('Failed to connect Twitter');
    },
  });

  const handleTwitterConnect = () => {
    const returnURL = new URL(document.location.origin);
    returnURL.pathname = document.location.pathname;
    const params = returnURL.searchParams;

    params.append('tab', 'integrations');
    params.append('agent_id', values.agent_id);

    mutateConnectTwitter({ agent_id: values.agent_id, redirect_url: returnURL.toString() });
  };

  const onTwitterDisconnect = () => {
    const resetState = {
      twitter_username: '',
      twitter_agent_set_likes: false,
      twitter_agent_regular_post: false,
      twitter_agent_answer_comments: false,
      twitter_agent_comment_tweets: false,
      twitter_agent_post_videos: false,
      twitter_content_examples: [''],
      twitter_content_specific_preferences: [''],
      twitter_content_directions: [],
      twitter_add_hashtags: false,
      twitter_comment_strategy: TwitterCommentStrategy.CONSTRUCTIVE,
      twitter_sentiment_control: false,
      twitter_crisis_communication: CRISIS_COMMUNICATION.APOLOGETIC,
    };

    setValues((prev) => ({ ...prev, ...resetState }));
  };

  const handleTwitterDisconnect = () => {
    setModal({
      modalKey: 'disconnect-agent-integration-modal',
      onDisconnect: onTwitterDisconnect,
      type: '(X) Twitter',
    });
  };

  return (
    <>
      <ItemWithFancyBorder className="rounded-xl">
        <div className="rounded-xl bg-black p-4">
          <div className="flex items-center gap-4 border-b border-white border-opacity-20 pb-5">
            <span className="text-lg">Connect (X) Twitter</span>
            {!values.twitter_username ? (
              <UIButton className="ml-auto" onClick={handleTwitterConnect}>
                Grant access
              </UIButton>
            ) : (
              <UIButton className="ml-auto" onClick={handleTwitterDisconnect}>
                Disconnect
              </UIButton>
            )}
          </div>
          <div className="relative flex flex-col gap-4 py-6">
            {!values.twitter_username && (
              <div
                onClick={handleTwitterConnect}
                className="absolute top-0 z-10 flex h-full w-full items-center justify-center backdrop-blur"
                style={{ width: 'calc(100% + 1em)', left: '-0.5em' }}
              >
                Login to X to use these features
              </div>
            )}
            <UISwitch
              label="Post Tweets"
              value={values.twitter_agent_regular_post}
              onChange={(e) => setFieldValue('twitter_agent_regular_post', e)}
            />
            <UISwitch
              label="Auto-Like Tweets"
              value={values.twitter_agent_set_likes}
              onChange={(e) => setFieldValue('twitter_agent_set_likes', e)}
            />
            <UISwitch
              label="Reply to Other Users’ Tweets"
              value={values.twitter_agent_answer_comments}
              onChange={(e) => setFieldValue('twitter_agent_answer_comments', e)}
            />
            <UISwitch
              label="Reply to Comments on Your Agent’s Tweets"
              value={values.twitter_agent_comment_tweets}
              onChange={(e) => setFieldValue('twitter_agent_comment_tweets', e)}
            />
          </div>
        </div>
      </ItemWithFancyBorder>
    </>
  );
};
