import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { LoadingStub } from 'src/components/ui/LoadingStub';
import { UIAlert } from 'src/components/ui/UIAlert';
import { useTimeDifference } from 'src/hooks/useTimeDifference.ts';
import { axiosInstance } from 'src/libs/axios.ts';
import { Agent } from 'src/types/agents.ts';
import { BN } from 'src/utils/bigNumber.ts';
import { shortenHash } from 'src/utils/ui.ts';

type Pagination = { limit: string; skip: string };
const initPagination: Pagination = {
  limit: '10',
  skip: '0',
};

type Trade = {
  creator: string;
  amount_0_in: number;
  amount_0_out: number;
  amount_1_in: number;
  amount_1_out: number;
  price: number;
  action: 'sell' | 'buy';
  timestamp: number;
  hash: string;
};

const TradeTableRow: FC<{ trade: Trade; agent: Agent }> = ({ trade, agent }) => {
  const formattedTime = useTimeDifference(trade.timestamp * 1000, { type: 'after', short: true });

  return (
    <tr
      style={{
        background: 'linear-gradient(180deg, rgba(43,13,61,1) 0%, rgba(36,13,39,1) 100%)',
      }}
    >
      <td
        className="min-w-32 rounded-bl-2xl rounded-tl-2xl py-4 pl-7"
        style={{
          borderLeft: '1px solid #45256C',
          borderTop: '1px solid #45256C',
          borderBottom: '1px solid #45256C',
        }}
      >
        <div className="flex items-center gap-2">
          {trade.action === 'buy' ? (
            <Icon className="text-xl text-green-500" icon="fa6-solid:circle-chevron-up" />
          ) : (
            <Icon className="text-xl text-red-500" icon="fa6-solid:circle-chevron-down" />
          )}
          <span className="text-sm">{trade.action === 'buy' ? 'Buy' : 'Sell'}</span>
        </div>
      </td>
      <td
        className="min-w-48 py-4"
        style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
      >
        <div className="flex flex-col gap-0.5">
          <span className="text-base font-bold">
            {BN(trade.action === 'buy' ? trade.amount_0_out : trade.amount_1_out).toFixed(2)}
          </span>
          <span className="text-sm text-white/70">
            ${trade.action === 'buy' ? agent.ticker : 'AITHER'}
          </span>
        </div>
      </td>
      <td
        className="min-w-48 py-4"
        style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
      >
        <div className="flex flex-col gap-0.5">
          <span className="text-base font-bold">
            {BN(trade.action === 'buy' ? trade.amount_1_in : trade.amount_0_in).toFixed(2)}
          </span>
          <span className="text-sm text-white/70">
            ${trade.action === 'buy' ? 'AITHER' : agent.ticker}
          </span>
        </div>
      </td>
      <td
        className="min-w-48 py-4"
        style={{ borderTop: '1px solid #45256C', borderBottom: '1px solid #45256C' }}
      >
        <a
          className="flex items-center gap-1 text-sm hover:underline"
          href={`https://basescan.org/tx/${trade.hash}`}
          target="_blank"
          rel="noreferrer"
        >
          {shortenHash(trade.hash)}
          <Icon icon="fa6-solid:up-right-from-square" className="text-white" />
        </a>
      </td>
      <td
        className="min-w-48 rounded-br-2xl rounded-tr-2xl py-4 pr-7"
        style={{
          borderRight: '1px solid #45256C',
          borderTop: '1px solid #45256C',
          borderBottom: '1px solid #45256C',
        }}
      >
        <div className="flex flex-col gap-0.5">
          <span className="text-base font-bold">{formattedTime}</span>
          <span className="text-sm text-white/70">ago</span>
        </div>
      </td>
    </tr>
  );
};

export const TradesTab: FC<{ agent: Agent }> = ({ agent }) => {
  const [pagination, setPagination] = useState(initPagination);
  const [trades, setTrades] = useState<Trade[]>([]);

  async function fetchTrades(p: Pagination) {
    const params: Record<string, string> = {
      ...p,
      agent_id: agent.agent_id,
    };
    const res = await axiosInstance.get<{ items_count: string; result: Trade[] }>(
      '/api/market/swaps',
      { params },
    );
    return res.data;
  }

  const { data, isPending, isError } = useQuery({
    queryKey: ['trades', agent.agent_id, pagination],
    queryFn: () => fetchTrades(pagination),
  });

  useEffect(() => {
    setTrades([]);
    setPagination(initPagination);
  }, [agent.agent_id]);

  useEffect(() => {
    if (data?.result) {
      setTrades((prev) => [...prev, ...data.result]);
    }
  }, [data]);

  const handleShowMore = () => {
    setPagination((prev) => {
      const newSkip = String(Number(prev.skip) + Number(prev.limit));
      return { ...prev, skip: newSkip };
    });
  };

  if (isPending && trades.length === 0) return <LoadingStub label="Loading trades" />;
  if (isError) return <UIAlert type="error" message="Error while fetching trades" />;

  return (
    <div style={{ minHeight: `${trades.length * 60}px` }} className="overflow-x-auto">
      <table
        style={{ borderCollapse: 'separate', borderSpacing: '0px 12px' }}
        className="mt-4 w-auto min-w-full max-w-none whitespace-nowrap text-left"
      >
        <thead>
          <tr>
            <th scope="col" className="min-w-32 pb-3">
              <span className="text-white/50">Action</span>
            </th>
            <th scope="col" className="min-w-48 pb-3">
              <span className="text-white/50">Out</span>
            </th>
            <th scope="col" className="min-w-48 pb-3">
              <span className="text-white/50">In</span>
            </th>
            <th scope="col" className="min-w-48 pb-3">
              <span className="text-white/50">Tx</span>
            </th>
            <th scope="col" className="min-w-48 pb-3">
              <span className="text-white/50">Date</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {trades?.map((trade) => <TradeTableRow key={trade.hash} trade={trade} agent={agent} />)}
        </tbody>
      </table>

      {parseInt(data?.items_count || '0') > trades?.length && (
        <div className="cursor-pointer py-2 text-center underline" onClick={handleShowMore}>
          {isPending ? 'Loading...' : 'Show more'}
        </div>
      )}
    </div>
  );
};
